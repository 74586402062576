import { FC, useMemo } from 'react';
import { Button } from '@epam/promo';
import { ReactComponent as MailIcon } from '@epam/assets/icons/common/communication-mail-24.svg';
import { isEmpty } from 'lodash';

import { dateFormatterForEmailTemplate } from '../../utils/dateFormatter';

import styles from './ContactSellerButton.module.scss';

interface ContactSellerButtonProps {
  contactName: string;
  contactEmail?: string;
  advertisementTitle: string;
  advertisementDate: string;
  buyerName: string;
  disabled?: boolean;
}

const SUBJECT_PREFIX = 'EPAM Marketplace Advertisement - ';
const NEW_LINE = '%0D%0A';
const DOUBLE_NEW_LINE = NEW_LINE + NEW_LINE;

const ContactSellerButton: FC<React.PropsWithChildren<ContactSellerButtonProps>> = ({
  contactName,
  contactEmail,
  advertisementTitle,
  advertisementDate,
  buyerName,
  disabled,
}) => {
  const body = useMemo(
    () =>
      `Hello ${contactName},${DOUBLE_NEW_LINE}` +
      `I'm ${buyerName}. Nice to meet you!${DOUBLE_NEW_LINE}` +
      `I contacted you because I'm interested in the advertisement '${advertisementTitle}' ` +
      `that you published on ${dateFormatterForEmailTemplate(
        advertisementDate
      )}.${DOUBLE_NEW_LINE}` +
      `This is the link to the advertisement I'm referring to:${NEW_LINE}` +
      `${window.location.href}${DOUBLE_NEW_LINE}` +
      `Is it still available?${DOUBLE_NEW_LINE}` +
      `Kind regards,${NEW_LINE}` +
      `${buyerName}`,
    [contactName, buyerName, advertisementTitle, advertisementDate]
  );

  const href = useMemo(
    () => `mailto:${contactEmail}?subject=${SUBJECT_PREFIX}${advertisementTitle}&body=${body}`,
    [advertisementTitle, contactEmail, body]
  );

  return (
    <Button
      cx={styles.contactButton}
      href={href}
      target="_blank"
      icon={MailIcon}
      isDisabled={disabled || isEmpty(contactEmail)}
    />
  );
};

export default ContactSellerButton;
