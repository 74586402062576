import { AdvertisementStatus } from '../../utils/businessConstants';

import { Status } from '../../types';

export const DEFAULT_CURRENCY = 'CHF';
export const PREVIEW_DEFAULT_TITLE = 'Title';
export const PREVIEW_DEFAULT_DESCRIPTION = 'Description';
export const PREVIEW_DEFAULT_PRICE = 0;
export const PREVIEW_DEFAULT_STATUS: Status = {
  id: AdvertisementStatus.DRAFT,
  description:
    'The item/service is under review because the display time expired or the advertisement is being edited by the owner',
};

export const countryAliases: Record<string, string> = {
  'United Kingdom': 'UK',
};
