import { useCallback } from 'react';
import { useEffectOnce, useSessionStorage } from 'react-use';
import { useUuiContext } from '@epam/uui-core';
import { noop } from 'lodash';

import TermsAndConditionsModal from './TermsAndConditionsModal';

import { SESSION_STORAGE_KEYS } from '../../utils/constants';
import { useAxios } from '../../apiHooks/useAxios';
import { UserDetailsServiceUrl } from '../../apiUtils/constants';
import useUserDetails from '../../auth/useUserDetails';

export const useTermsAndConditionsModal = () => {
  const { saveDetails } = useUserDetails();

  const [termsWereAccepted, setTermsWereAccepted] = useSessionStorage(
    SESSION_STORAGE_KEYS.TERMS_WERE_ACCEPTED,
    false
  );
  const { uuiModals } = useUuiContext();
  const { axios } = useAxios();

  const acceptTerms = useCallback(
    async (value: boolean) => {
      setTermsWereAccepted(value);

      const { data } = await axios.put(UserDetailsServiceUrl.updateTerms);
      // Keeping details up to date. However it will not change the user object!
      saveDetails(data);
    },
    [setTermsWereAccepted, axios, saveDetails]
  );

  useEffectOnce(() => {
    if (!termsWereAccepted) {
      axios
        .get(UserDetailsServiceUrl.userDetails)
        .then(({ data: { termsAndConditionsAccepted, ...rest } }) => {
          // Keeping details up to date. However it will not change the user object!
          saveDetails({ ...rest, termsAndConditionsAccepted });

          if (!termsAndConditionsAccepted) {
            uuiModals
              .show<boolean>((props) => <TermsAndConditionsModal {...props} />)
              .then(acceptTerms)
              .catch(noop);
          }
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            uuiModals
              .show<boolean>((props) => <TermsAndConditionsModal {...props} />)
              .then(acceptTerms)
              .catch(noop);
          }
        });
    } else {
      axios.get(UserDetailsServiceUrl.userDetails).then(({ data }) => {
        // Keeping details up to date. However it will not change the user object!
        saveDetails(data);
      });
    }
  });
};
