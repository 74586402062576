import { noop } from 'lodash';

import { SuccessNotification } from '@epam/promo';
import { Text } from '@epam/uui';
import { CommonContexts, INotification } from '@epam/uui-core';

import { SuccessMessage } from './constants';

const SuccessAlert = (message: SuccessMessage, svc: CommonContexts<unknown, unknown>) => {
  svc.uuiNotifications
    .show(
      (props: INotification) => (
        <SuccessNotification {...props}>
          <Text size="36" fontSize="14">
            {message}
          </Text>
        </SuccessNotification>
      ),
      { duration: 8 }
    )
    .then(noop)
    .catch(noop);
};

export default SuccessAlert;
