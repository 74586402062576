import { FC, useCallback, useState } from 'react';
import { Accordion, Button, RichTextView } from '@epam/promo';
import { isEmpty } from 'lodash';
import { produce } from 'immer';

import { CategoryCheckbox } from './components/CategoryCheckbox';

import { Category } from '../../types';

import css from './CategoriesFilter.module.scss';

type CategoriesFilterProps = {
  categories: Category[];
  categoriesFiltered: number[];
  onCategoriesSelectChange: (categories: number[]) => void;
};

const CategoriesFilter: FC<React.PropsWithChildren<CategoriesFilterProps>> = ({
  categories,
  categoriesFiltered,
  onCategoriesSelectChange,
}) => {
  const [accordionVisible, setAccordionVisible] = useState<boolean>(true);

  const handleCategoriesFilterChange = useCallback(
    (id: number) => {
      if (!categoriesFiltered.includes(id)) {
        onCategoriesSelectChange(
          produce(categoriesFiltered, (draft) => {
            draft.push(id);
          })
        );
      } else {
        onCategoriesSelectChange(categoriesFiltered.filter((categoryId) => categoryId !== id));
      }
    },
    [categoriesFiltered, onCategoriesSelectChange]
  );

  const handleClearFilter = useCallback(() => {
    if (!isEmpty(categoriesFiltered)) {
      onCategoriesSelectChange([]);
    }
  }, [categoriesFiltered, onCategoriesSelectChange]);

  return (
    <Accordion
      title="Categories"
      mode="block"
      value={accordionVisible}
      onValueChange={setAccordionVisible}
    >
      {categories.map((category) => (
        <CategoryCheckbox
          key={category.id}
          category={category}
          selected={categoriesFiltered.includes(category.id)}
          onValueChange={handleCategoriesFilterChange}
        />
      ))}
      <RichTextView cx={css.clearFilter}>
        <Button
          size="24"
          fill="white"
          color="blue"
          caption="Clear All"
          onClick={handleClearFilter}
        />
      </RichTextView>
    </Accordion>
  );
};

export default CategoriesFilter;
