export enum SESSION_STORAGE_KEYS {
  ID_TOKEN = '@@mp/id-token',
  ACCESS_TOKEN = '@@mp/access-token',
  REFRESH_TOKEN = '@@mp/refresh-token',
  ENTRY_URL = '@@mp/entry-path',
  TERMS_WERE_ACCEPTED = '@@mp/terms-were-accepted',
  USER_DETAILS = '@@mp/user-details',
}

export enum LOCAL_STORAGE_KEYS {
  EXPIRING_ADS_CHECKED_ON_DATES = '@@mp/expiring-ads-checked-on',
  MAIN_PAGE_SCROLL_POS = '@@mp/main-page/scroll-position',
  MAIN_PAGE_STORED_STATE = '@@mp/main-page/stored-state',
  MY_ADS_PAGE_SCROLL_POS = '@@mp/my-ads-page/scroll-position',
  MY_ADS_PAGE_STORED_STATE = '@@mp/my-ads-page/stored-state',
}
