import { Children, FC, ReactNode } from 'react';
import { Text } from '@epam/uui';
import css from './Breadcrumb.module.scss';

interface Props {
  children: ReactNode;
}

const Breadcrumb: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const childrenArr = Children.toArray(children);

  const renderChildrenWIthSeperator = Children.map(children, (child, i) => {
    const lastChild = i === childrenArr.length - 1;

    if (lastChild) {
      return child;
    }

    return (
      <>
        {child}
        <Text cx={css.seperator} fontSize="16">
          {'/'}
        </Text>
      </>
    );
  });

  return <span className={css.breadcrumb}>{renderChildrenWIthSeperator}</span>;
};

export default Breadcrumb;
