import { FC } from 'react';
import { StaticPage } from '../../templates/Page/Page';
import { FlexRow } from '@epam/loveship';
import { TermsAndConditions } from '../../components/TermsAndConditions';

import style from './TermsAndConditionsPage.module.scss';

const TermsAndConditionsPage: FC<React.PropsWithChildren<unknown>> = () => (
  <StaticPage>
    <div className={style.container}>
      <FlexRow>
        <TermsAndConditions />
      </FlexRow>
    </div>
  </StaticPage>
);

export default TermsAndConditionsPage;
