import { FC } from 'react';

import { FlexRow, FlexSpacer, Panel, Text } from '@epam/loveship';
import { Paginator } from '@epam/promo';
import SortMenu from './SortMenu';

import css from './ResultsHeader.module.scss';

type ResultsHeaderProps = {
  title?: string;
  resultsCount: number;
  sortType: string;
  paginationPageIndex: number;
  totalPages: number;
  onSortTypeChange: (sortType: string) => void;
  onPaginationPageChange: (page: number) => void;
};

const ResultsHeader: FC<React.PropsWithChildren<ResultsHeaderProps>> = ({
  title = 'Advertisements',
  resultsCount,
  sortType,
  onSortTypeChange,
  paginationPageIndex,
  onPaginationPageChange,
  totalPages,
}) => {
  return (
    <>
      <Panel cx={css.headerPanel}>
        <FlexRow>
          <Text
            cx={css.headerText}
            fontSize="18"
            lineHeight="30"
            color="night700"
            font="sans-semibold"
          >
            {title}
          </Text>
          <FlexSpacer />
          <Text cx={css.resultsCountText} fontSize="18">
            {`${resultsCount} ${resultsCount === 1 ? 'Item' : 'Items'}`}
          </Text>
          <FlexSpacer />
          <FlexSpacer />
          {totalPages > 1 && (
            <div className={css.paginator}>
              <Paginator
                size="24"
                totalPages={totalPages}
                value={paginationPageIndex}
                onValueChange={onPaginationPageChange}
              />
            </div>
          )}
          {resultsCount > 0 && (
            <SortMenu sortType={sortType} onSortTypeChange={onSortTypeChange} isDisabled={false} />
          )}
        </FlexRow>
      </Panel>
    </>
  );
};

export default ResultsHeader;
