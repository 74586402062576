import { FC, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { DropdownMenuBody, DropdownMenuButton } from '@epam/promo';
import { ReactComponent as DeleteIcon } from '@epam/assets/icons/common/action-deleteforever-18.svg';
import { ReactComponent as StatusChangeIcon } from '@epam/assets/icons/common/table-swap-18.svg';

import style from './AdvertisementTile.module.scss';

const MOUSE_DOWN = 'mousedown';

type ActionMenuProps = {
  statusChangeCaption: string;
  onDelete: () => void;
  onChangeStatus: () => void;
  onCloseDropdown: () => void;
  onClickOutside: () => void;
};

const ActionMenu: FC<React.PropsWithChildren<ActionMenuProps>> = ({
  statusChangeCaption,
  onDelete,
  onChangeStatus,
  onCloseDropdown,
  onClickOutside,
}) => {
  const selfReference = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    const handleClikOutside = (e: MouseEvent) => {
      if (selfReference.current && !selfReference.current.contains(e.target as Node)) {
        onClickOutside();
      }
    };

    document.addEventListener(MOUSE_DOWN, handleClikOutside);

    return () => {
      document.removeEventListener(MOUSE_DOWN, handleClikOutside);
    };
  });

  return (
    <div className={style.dropdown} ref={selfReference}>
      <DropdownMenuBody onClose={onCloseDropdown} style={{ maxWidth: '250px' }}>
        <DropdownMenuButton caption="Delete" icon={DeleteIcon} onClick={onDelete} />
        <DropdownMenuButton
          caption={statusChangeCaption}
          icon={StatusChangeIcon}
          onClick={onChangeStatus}
        />
      </DropdownMenuBody>
    </div>
  );
};

export default ActionMenu;
