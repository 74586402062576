import { FC, useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { IconContainer } from '@epam/promo';
import { Tooltip } from '@epam/uui';
import { ReactComponent as InfoIcon } from '@epam/assets/icons/common/notification-info-fill-18.svg';

import {
  FIRST_WARNING_THRESHOLD_DAYS,
  SECOND_WARNING_THRESHOLD_DAYS,
} from '../../pages/MyAdvertisementsPage/useExpiringAdWarnings';

import styles from './expirationIndicator.module.scss';

const MAX_EXPIRATION_DAYS = 30;
const HANDLE_WIDTH_PX = 12;

export type ExpirationIndicatorProps = {
  statusLastUpdatedOn: string;
};

const ExpirationIndicator: FC<React.PropsWithChildren<ExpirationIndicatorProps>> = ({
  statusLastUpdatedOn,
}) => {
  const [sliderRef, setSliderRef] = useState<HTMLDivElement | null>(null);
  const lastUpdatedDays = useMemo(
    () => dayjs().diff(statusLastUpdatedOn, 'day'),
    [statusLastUpdatedOn]
  );

  const expirationLabel = useMemo(() => {
    if (lastUpdatedDays > MAX_EXPIRATION_DAYS) {
      return 'Expired';
    }

    const daysToExpire = MAX_EXPIRATION_DAYS - lastUpdatedDays;

    if (daysToExpire === 0) {
      return 'Expires today';
    }

    return `Expires in ${daysToExpire} day${daysToExpire > 1 ? 's' : ''}`;
  }, [lastUpdatedDays]);

  const fillerWidth = useMemo(() => {
    const componentWidth = sliderRef?.clientWidth ?? 0;
    const elapsedWidth = (componentWidth * lastUpdatedDays) / MAX_EXPIRATION_DAYS;

    return Math.min(elapsedWidth, componentWidth);
  }, [lastUpdatedDays, sliderRef]);

  const handlePositionX = useMemo(() => {
    if (fillerWidth < HANDLE_WIDTH_PX) {
      return 0;
    }

    return fillerWidth - HANDLE_WIDTH_PX;
  }, [fillerWidth]);

  const ragStatusClassName = useMemo(() => {
    if (lastUpdatedDays < FIRST_WARNING_THRESHOLD_DAYS) {
      return styles.green;
    }

    if (lastUpdatedDays < SECOND_WARNING_THRESHOLD_DAYS) {
      return styles.amber;
    }

    return styles.red;
  }, [lastUpdatedDays]);

  const renderTooltipContent = useCallback(() => {
    return (
      <>
        <div>
          {'Published advertisement automatically becomes draft after 30 days of publishing.'}
        </div>
        <div>
          {
            'Draft advertisement is automatically deleted after 30 days without any further changes.'
          }
        </div>
      </>
    );
  }, []);

  return (
    <>
      <div className={styles.tooltipContainer}>
        <Tooltip content={renderTooltipContent()} placement="top">
          <IconContainer icon={InfoIcon} color="gray60" cx={styles.icon} />
        </Tooltip>
        <span className={styles.label}>{expirationLabel}</span>
      </div>
      <div className={styles.sliderContainer}>
        <div className={classNames(styles.slider, ragStatusClassName)} ref={setSliderRef} />
        <div className={styles.filler} style={{ width: `${fillerWidth}px` }} />
        <div
          className={classNames(styles.handle, ragStatusClassName)}
          style={{ transform: `translateX(${handlePositionX}px` }}
        />
      </div>
      <div className={classNames(styles.rangeLabelContainer, styles.label)}>
        <span>{'0'}</span>
        <span>{'30'}</span>
      </div>
    </>
  );
};

export default ExpirationIndicator;
