import { FlexRow, Text } from '@epam/uui';
import { Category } from '../../../../types';

import css from './CategoryCheckbox.module.scss';

interface CategoryCheckboxProps {
  category: Category;
  selected: boolean;
  onValueChange: (id: number) => void;
}

function CategoryCheckbox({ category, selected, onValueChange }: CategoryCheckboxProps) {
  const { id, name } = category;

  return (
    <FlexRow
      cx={selected ? `${css.wrapper} ${css.selectedCategory}` : css.wrapper}
      key={id}
      onClick={() => onValueChange(category.id)}
    >
      <Text cx={selected ? css.selectedCategoryText : css.text} size="48">
        {name}
      </Text>
      <input
        className={css.checkbox}
        type="checkbox"
        id={`custom-checkbox-${id}`}
        name={name}
        value={name}
        checked={selected}
        onChange={() => onValueChange(category.id)}
      />
    </FlexRow>
  );
}

export default CategoryCheckbox;
