import { SetStateAction, useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { SESSION_STORAGE_KEYS } from '../utils/constants';

const useUserDetails = () => {
  const [userDetails, setUserDetails] = useLocalStorage(SESSION_STORAGE_KEYS.USER_DETAILS, {
    roles: [''],
  });

  const saveDetails = useCallback(
    (details: SetStateAction<{ roles: string[] } | undefined>) => setUserDetails(details),
    [setUserDetails]
  );

  return { saveDetails, userDetails };
};

export default useUserDetails;
