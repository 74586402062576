import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { produce } from 'immer';
import { isEmpty, noop } from 'lodash';

import Photo, { PhotoStatus } from './Photo';
import { useAxios } from '../../../../apiHooks/useAxios';

import { AdvertisementServiceUrl } from '../../../../apiUtils/constants';

const uploadConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const useFileUploadManager = (initialHighlight?: string) => {
  const [images, setImages] = useState<Photo[]>([]);
  const [highlight, setHighlight] = useState<string | undefined>();
  const [uploadDeleteInProgress, setUploadDeleteInProgress] = useState(false);
  const { axios } = useAxios();

  useEffect(() => setHighlight(initialHighlight), [initialHighlight]);

  const trackUploadProgress = useCallback(
    (id: string) => (progressEvent: AxiosProgressEvent) => {
      setImages((state) =>
        produce(state, (draft) => {
          const updatedImage = draft.find((image) => image.id === id);

          if (updatedImage) {
            const { loaded, total } = progressEvent;
            updatedImage.progress = Math.round((loaded / total!) * 100);
          }
        })
      );
    },
    []
  );

  const setGeneralError = useCallback((id: string, error: Record<string, unknown>) => {
    setImages((state) =>
      produce(state, (draft) => {
        const updatedImage = draft.find((image) => image.id === id);

        if (updatedImage) {
          updatedImage.error = error;
        }
      })
    );
  }, []);

  const handlePendingUploadDelete = useCallback(
    (advertisementId: string) => {
      const remainingPhotos: Photo[] = [];
      const processPhotoPromises: Promise<void>[] = [];
      let newHighlight = highlight;
      let hasErrors = false;

      const deletePhotos: (photos: Photo[]) => Promise<void> = (photos) =>
        axios
          .post<void>(AdvertisementServiceUrl.deleteAttachments, {
            advertisementId,
            ids: photos.map((photo) => photo.id),
          })
          .then(noop)
          .catch((error) => {
            hasErrors = true;
            photos.forEach((image) => {
              remainingPhotos.push(image);
              setGeneralError(image.id, error);
            });
          });

      const uploadPhotos: (photos: Photo[]) => Promise<void>[] = (photos) => {
        const eventualHighlight = highlight ?? photos[0]?.id;
        return photos.map((photo) => {
          const photoIsHighlighted = photo.id === eventualHighlight;
          const formData = new FormData();
          formData.append('photo', photo.file);
          formData.append('advertisementId', advertisementId);

          const config = produce(uploadConfig, (draft) => {
            draft.onUploadProgress = trackUploadProgress(photo.id);
          });

          return axios
            .post<string>(AdvertisementServiceUrl.uploadAttachment, formData, config)
            .then(({ data: attachmentId }) => {
              remainingPhotos.push(
                produce(photo, (draft) => {
                  draft.id = attachmentId;
                  draft.progress = 100;
                  draft.status = PhotoStatus.UPLOADED;
                })
              );

              if (photoIsHighlighted) {
                newHighlight = attachmentId;
              }
            })
            .catch((error) => {
              hasErrors = true;
              setGeneralError(photo.id, error);
            });
        });
      };

      const photosToDelete = images.filter((image) => image.status === PhotoStatus.PENDING_REMOVAL);
      if (!isEmpty(photosToDelete)) {
        processPhotoPromises.push(deletePhotos(photosToDelete));
      }

      const photosToUpload = images.filter((image) => image.status === PhotoStatus.PENDING_UPLOAD);
      processPhotoPromises.push(...uploadPhotos(photosToUpload));

      const alreadyUploadedPhotos = images.filter((image) => image.status === PhotoStatus.UPLOADED);
      remainingPhotos.push(...alreadyUploadedPhotos);

      if (!isEmpty(processPhotoPromises)) {
        setUploadDeleteInProgress(true);
      }

      return new Promise<{ remainingPhotos: Photo[]; newHighlight: string | undefined }>(
        (resolve, reject) => {
          Promise.allSettled(processPhotoPromises).finally(() => {
            ReactDOM.unstable_batchedUpdates(() => {
              setUploadDeleteInProgress(false);
              setImages(remainingPhotos);
            });

            if (hasErrors) {
              reject();
            } else {
              resolve({ remainingPhotos, newHighlight });
            }
          });
        }
      );
    },
    [axios, highlight, images, setGeneralError, trackUploadProgress]
  );

  const handleAddPhotos = useCallback((photos: Photo[]) => {
    setImages((state) => produce(state, (draft) => draft.concat(...photos)));
  }, []);

  const handleHighlightChange = useCallback(
    (newHighlight: string | undefined) => {
      const eligibleImages = images.filter((img) => img.isPreviewEligible());
      if (!isEmpty(eligibleImages)) {
        if (newHighlight) {
          setHighlight(newHighlight);
        } else {
          const [firstImage] = eligibleImages;
          setHighlight(firstImage.id);
        }
      } else {
        setHighlight(undefined);
      }
    },
    [images]
  );

  const handleRemovePhoto = useCallback(
    (photo: Photo) => {
      const foundIndex = images.findIndex((image) => image.id === photo.id);

      if (foundIndex > -1) {
        const image = images[foundIndex];

        setImages((state) =>
          produce(state, (draft) => {
            if (image.status === PhotoStatus.UPLOADED) {
              draft[foundIndex].status = PhotoStatus.PENDING_REMOVAL;
            } else if (image.status === PhotoStatus.PENDING_UPLOAD) {
              draft.splice(foundIndex, 1);
            }
          })
        );
      }
    },
    [images]
  );

  return {
    images,
    highlight,
    uploadDeleteInProgress,
    handlePendingUploadDelete,
    handleAddPhotos,
    handleRemovePhoto,
    handleHighlightChange,
  };
};

export default useFileUploadManager;
