import { Spinner } from '@epam/uui';
import { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useAxios } from '../../apiHooks/useAxios';
import { AdvertisementServiceUrl } from '../../apiUtils/constants';
import { HttpStatusCode } from 'axios';

export const TestId = {
  initial: 'AsyncImage-initial',
  final: 'AsyncImage-final',
};

const PLACEHOLDER_URL = '/resources/image_placeholder.png';
const FORBID_URL = '/resources/forbidden.png';
const DEFAULT_TIMEOUT = 2000;
const MAX_ATTEMPTS = 8;

type AnyncImageProps = {
  attachmentId: string;
  alt: string;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
  timeout?: number;
};

const AsyncImage: FC<React.PropsWithChildren<AnyncImageProps>> = ({
  attachmentId,
  alt,
  className,
  onClick,
  timeout = DEFAULT_TIMEOUT,
}) => {
  const [testId, setTestId] = useState(TestId.initial);
  const [src, setSrc] = useState(PLACEHOLDER_URL);
  const [pending, setPending] = useState(false);
  const [currentAlt, setCurrentAlt] = useState('initialization');

  const { axios } = useAxios();

  const attachmentUrl = AdvertisementServiceUrl.attachmentDetail(attachmentId);

  const retry = useRef<NodeJS.Timeout | null>();

  const fetchImage = useCallback(
    (attemptNo = 0) => {
      axios
        .get<Blob>(attachmentUrl, { responseType: 'blob' })
        .then(({ data }) => {
          const attachmentFile = new File([data], attachmentId);
          setSrc(URL.createObjectURL(attachmentFile));
          setPending(false);
        })
        .catch(({ response }) => {
          if (response) {
            const { status } = response;
            if (status === HttpStatusCode.TooEarly) {
              if (attemptNo < MAX_ATTEMPTS) {
                retry.current = setTimeout(() => fetchImage(attemptNo + 1), timeout);
                setPending(true);
              } else {
                setSrc(FORBID_URL);
                setPending(false);
              }
            } else {
              setSrc(PLACEHOLDER_URL);
              setPending(false);
            }
          }
        })
        .finally(() => {
          setTestId(TestId.final);
          setCurrentAlt(alt);
        });

      return retry;
    },
    [alt, attachmentId, attachmentUrl, axios, retry, timeout]
  );

  useEffect(() => {
    fetchImage();

    return () => {
      if (retry.current) {
        clearTimeout(retry.current);
      }
    };
  }, [fetchImage]);

  return (
    <>
      {pending ? (
        <Spinner />
      ) : (
        <img
          className={className}
          alt={currentAlt}
          src={src}
          onClick={onClick}
          data-testid={testId}
        />
      )}
    </>
  );
};

export default AsyncImage;
