import ReactDOM from 'react-dom';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import axios from 'axios';
import { sortBy, uniq } from 'lodash';
import { produce } from 'immer';

import { City, Country, Currency, Location } from '../types';

const mapLocationsToCurrencies = (locations: Location[]): Currency[] =>
  sortBy(uniq(locations.map(({ currency }) => currency))).map((currency) => ({
    id: currency,
    name: currency,
  }));

const mapLocationsToCountriesAndCities = (locations: Location[]): Country[] => {
  const countryNamesAndCountriesWithCities = locations.reduce((acc, location) => {
    const country = acc.get(location.country) ?? {
      name: location.country,
      currency: location.currency,
      cities: [],
    };

    const city: City = {
      id: location.id,
      name: location.city,
    };

    if (!country.cities.includes(city)) {
      country.cities.push(city);
    }

    acc.set(country.name, country);
    return acc;
  }, new Map<string, Country>());

  const countriesWithCities = Array.from(
    countryNamesAndCountriesWithCities,
    ([, country]) => country
  );

  return sortBy(
    countriesWithCities.map((country) =>
      produce(country, (draft) => {
        draft.cities = sortBy(draft.cities, 'name');
      })
    ),
    'name'
  );
};

export const useLocations = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [locations, setLocations] = useState<Location[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  useEffectOnce(() => {
    axios
      .get<Location[]>('/resources/locations.json')
      .then(({ data }) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setLocations(data);
          setCurrencies(mapLocationsToCurrencies(data));
          setCountries(mapLocationsToCountriesAndCities(data));
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return { locations, currencies, countries, isLoading };
};
