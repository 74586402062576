import { useMemo, useState } from 'react';
import { useEffectOnce, useLocalStorage } from 'react-use';
import { isEmpty, times } from 'lodash';
import { produce } from 'immer';
import dayjs from 'dayjs';

import mapUserToOwner from '../CreateAndEditAdvertisementPage/utils/mapUserToOwner';
import { WarningAlert } from '../../components';
import { useAuth } from '../../auth/AuthProvider';
import { useAxios } from '../../apiHooks/useAxios';
import { useUuiContext } from '@epam/uui-core';

import { AdvertisementServiceUrl } from '../../apiUtils/constants';
import { AdvertisementStatus } from '../../utils/businessConstants';
import {
  getExpiringAdFirstWarningToDeleteMessage,
  getExpiringAdFirstWarningToDraftTransitionMessage,
  getExpiringAdSecondWarningToDeleteMessage,
  getExpiringAdSecondWarningToDraftTransitionMessage,
} from '../../components/Notifications/constants';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';

import {
  AdvertisementApiSearchRequest,
  AdvertisementApiSearchResponse,
  DEFAULT_SEARCH_FILTERS,
} from '../../apiHooks/useSearch';
import { Owner } from '../../types';

const DATES_SEPARATOR = '|';
const DATE_FORMAT = 'YYYY-MM-DD';
export const FIRST_WARNING_THRESHOLD_DAYS = 23;
export const SECOND_WARNING_THRESHOLD_DAYS = 29;
const LOCAL_STORE_KEPT_DAYS = 7;
export const EXPIRING_ADS_SCAN_PAGE_SIZE = DEFAULT_SEARCH_FILTERS.pageSize * 5;

const getSearchRequest = (
  owner: Owner,
  pageIndex: number
): Partial<AdvertisementApiSearchRequest> => ({
  pageIndex,
  pageSize: EXPIRING_ADS_SCAN_PAGE_SIZE,
  orderBy: 'creationDate',
  direction: 'desc',
  owner,
});

export const useExpiringAdWarnings = (): Set<AdvertisementStatus> => {
  const [adsCheckedOnDates, setAdsCheckedOnDates] = useLocalStorage<Date[]>(
    LOCAL_STORAGE_KEYS.EXPIRING_ADS_CHECKED_ON_DATES,
    [],
    {
      raw: false,
      serializer: (values: Date[]) =>
        values.map((value) => dayjs(value).format(DATE_FORMAT)).join(DATES_SEPARATOR),
      deserializer: (value: string) =>
        value
          .split(DATES_SEPARATOR)
          .filter((dateString) => !isEmpty(dateString))
          .map((dateString) => new Date(dateString)),
    }
  );

  const { user } = useAuth();
  const owner = useMemo(() => mapUserToOwner(user), [user]);
  const svc = useUuiContext();
  const { axios } = useAxios();

  const isExecutedToday = useMemo(() => {
    const today = dayjs();
    return adsCheckedOnDates!.some((checkedOnDate) => today.diff(checkedOnDate, 'day') === 0);
  }, [adsCheckedOnDates]);

  useEffectOnce(() => {
    const today = dayjs();
    const lastSevenDayDates = adsCheckedOnDates!.filter(
      (checkedOnDate) => today.diff(checkedOnDate, 'day') < LOCAL_STORE_KEPT_DAYS
    );

    if (lastSevenDayDates.length !== adsCheckedOnDates!.length) {
      setAdsCheckedOnDates(lastSevenDayDates);
    }
  });

  const [statusesOfExpiringItems, setStatusesOfExpiringItems] = useState(
    new Set<AdvertisementStatus>()
  );

  useEffectOnce(() => {
    const fetchMyAds = async (pageIndex = 0) => {
      const payload = getSearchRequest(owner, pageIndex);
      return await axios.post<AdvertisementApiSearchResponse>(
        AdvertisementServiceUrl.searchAdvertisements,
        payload
      );
    };

    fetchMyAds()
      .then(async ({ data }) => {
        const myAds = data.items;

        if (isEmpty(myAds)) {
          return;
        }

        if (data.totalPages > 1) {
          const promises = times(data.totalPages - 1).map((pageIndex) => fetchMyAds(1 + pageIndex));
          const restMyAds = await Promise.all(promises);
          restMyAds.forEach((page) => myAds.push(...page.data.items));
        }

        const today = dayjs();
        const { sevenDayDraftTitles, sevenDayDeleteTitles, oneDayDraftTitles, oneDayDeleteTitles } =
          myAds.reduce(
            (acc, ad) => {
              const lastChangedRelativeDays = today.diff(ad.lastStatusChangedOn, 'day');
              const adStatus = ad.status.id;
              const adTitle = ad.title;

              if (lastChangedRelativeDays === FIRST_WARNING_THRESHOLD_DAYS) {
                if (adStatus === AdvertisementStatus.PUBLISH) {
                  acc.sevenDayDraftTitles.push(adTitle);
                } else if (adStatus === AdvertisementStatus.DRAFT) {
                  acc.sevenDayDeleteTitles.push(adTitle);
                }
              } else if (lastChangedRelativeDays === SECOND_WARNING_THRESHOLD_DAYS) {
                if (adStatus === AdvertisementStatus.PUBLISH) {
                  acc.oneDayDraftTitles.push(adTitle);
                } else if (adStatus === AdvertisementStatus.DRAFT) {
                  acc.oneDayDeleteTitles.push(adTitle);
                }
              }

              if (lastChangedRelativeDays >= FIRST_WARNING_THRESHOLD_DAYS) {
                setStatusesOfExpiringItems((statuses) => statuses.add(adStatus));
              }

              return acc;
            },
            {
              sevenDayDraftTitles: [] as string[],
              sevenDayDeleteTitles: [] as string[],
              oneDayDraftTitles: [] as string[],
              oneDayDeleteTitles: [] as string[],
            }
          );

        if (!isExecutedToday) {
          if (!isEmpty(sevenDayDraftTitles)) {
            WarningAlert(
              getExpiringAdFirstWarningToDraftTransitionMessage(sevenDayDraftTitles),
              svc
            );
          }

          if (!isEmpty(sevenDayDeleteTitles)) {
            WarningAlert(getExpiringAdFirstWarningToDeleteMessage(sevenDayDeleteTitles), svc);
          }

          if (!isEmpty(oneDayDraftTitles)) {
            WarningAlert(
              getExpiringAdSecondWarningToDraftTransitionMessage(oneDayDraftTitles),
              svc
            );
          }

          if (!isEmpty(oneDayDeleteTitles)) {
            WarningAlert(getExpiringAdSecondWarningToDeleteMessage(oneDayDeleteTitles), svc);
          }
        }
      })
      .finally(() => {
        setAdsCheckedOnDates((state) =>
          produce(state, (draft) => {
            draft!.push(new Date());
          })
        );
      });
  });

  return statusesOfExpiringItems;
};
