import { useCallback, useEffect, useState } from 'react';
import { produce } from 'immer';
import { omitBy, isNil } from 'lodash';

import { useAxios } from './useAxios';

import { AdvertisementServiceUrl } from '../apiUtils/constants';
import { AdvertisementStatus } from '../utils/businessConstants';

import { Advertisement, Owner, PriceRangePickerValue } from '../types';
import { ResultSortingTypes } from '../components/ResultsHeader/contants';
import { RangeDatePickerValue } from '@epam/uui-core';

const DEFAULT_PAGE_SIZE = 21;
const DEFAULT_PAGE_INDEX = 1;

type DateRangeFilterValue = {
  from: string;
  to: string;
};

export type SortDirection = 'asc' | 'desc';

export type SearchFilter = {
  pageIndex: number;
  pageSize: number;
  owner?: Owner;
  keyword?: string;
  sortType: string;
  categories: number[];
  currency?: string | null;
  country: string | null;
  price: PriceRangePickerValue;
  date: DateRangeFilterValue;
  status?: AdvertisementStatus | null;
};

export type AdvertisementApiSearchRequest = {
  pageIndex: number;
  pageSize: number;
  orderBy: string;
  direction: SortDirection;
  dateFrom?: string | null;
  dateTo?: string | null;
  priceFrom?: number | null;
  priceTo?: number | null;
  categories?: number[];
  currency?: string | null;
  country?: string | null;
  keyword?: string;
  owner?: Owner;
  status?: AdvertisementStatus | null;
};

export type AdvertisementApiSearchResponse = {
  items: Advertisement[];
  pageIndex: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
};

export const DEFAULT_SEARCH_FILTERS: SearchFilter = {
  categories: [],
  pageIndex: DEFAULT_PAGE_INDEX,
  pageSize: DEFAULT_PAGE_SIZE,
  sortType: ResultSortingTypes.Newest,
  price: {
    from: null,
    to: null,
  },
  date: {
    from: '',
    to: '',
  },
  country: null,
};

const useSearch = (initialSearchFilter = DEFAULT_SEARCH_FILTERS) => {
  const [searchFilter, setSearchFilter] = useState(initialSearchFilter);
  const [data, setData] = useState<AdvertisementApiSearchResponse>();
  const { axios } = useAxios();

  const getOrderingProperties = useCallback((): Pick<
    AdvertisementApiSearchRequest,
    'orderBy' | 'direction'
  > => {
    let orderBy;
    let direction: SortDirection;
    switch (searchFilter.sortType) {
      case ResultSortingTypes.Oldest:
        orderBy = 'creationDate';
        direction = 'asc';
        break;
      case ResultSortingTypes.HighestPrice:
        orderBy = 'price';
        direction = 'desc';
        break;
      case ResultSortingTypes.LowestPrice:
        orderBy = 'price';
        direction = 'asc';
        break;
      case ResultSortingTypes.Newest:
      default:
        orderBy = 'creationDate';
        direction = 'desc';
        break;
    }
    return { orderBy, direction };
  }, [searchFilter.sortType]);

  const refreshData = useCallback(() => {
    const requestBody: AdvertisementApiSearchRequest = {
      pageIndex: searchFilter.pageIndex - 1,
      pageSize: searchFilter.pageSize,
      keyword: searchFilter.keyword,
      owner: searchFilter.owner,
      currency: searchFilter.currency,
      country: searchFilter.country,
      categories: searchFilter.categories,
      priceFrom: searchFilter.price.from,
      priceTo: searchFilter.price.to,
      dateFrom: searchFilter.date.from || null,
      dateTo: searchFilter.date.to || null,
      status: searchFilter.status,
      ...getOrderingProperties(),
    };

    const payload = omitBy(requestBody, isNil);

    axios
      .post<AdvertisementApiSearchResponse>(AdvertisementServiceUrl.searchAdvertisements, payload)
      .then(({ data }) => {
        setData(data);
      });
  }, [searchFilter, getOrderingProperties, axios]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const handlePageIndexChange = useCallback((index: number) => {
    setSearchFilter((state) =>
      produce(state, (draft) => {
        draft.pageIndex = index;
      })
    );
  }, []);

  const handleKeywordChange = useCallback((keyword: string) => {
    setSearchFilter((state) =>
      produce(state, (draft) => {
        draft.keyword = keyword;
        draft.pageIndex = DEFAULT_PAGE_INDEX;
      })
    );
  }, []);

  const handleSortTypeChange = useCallback((sortType: string) => {
    setSearchFilter((state) =>
      produce(state, (draft) => {
        draft.sortType = ResultSortingTypes[sortType as keyof typeof ResultSortingTypes];
        draft.pageIndex = DEFAULT_PAGE_INDEX;
      })
    );
  }, []);

  const handleCurrencyChange = useCallback((currency: string | undefined) => {
    setSearchFilter((state) =>
      produce(state, (draft) => {
        draft.currency = currency;
        draft.pageIndex = DEFAULT_PAGE_INDEX;
      })
    );
  }, []);

  const handleCategoriesChange = useCallback((categoryIds: number[]) => {
    setSearchFilter((state) =>
      produce(state, (draft) => {
        draft.categories = categoryIds;
        draft.pageIndex = DEFAULT_PAGE_INDEX;
      })
    );
  }, []);

  const handlePriceRangeChange = useCallback((priceRange: PriceRangePickerValue) => {
    setSearchFilter((state) =>
      produce(state, (draft) => {
        draft.price = priceRange;
        draft.pageIndex = DEFAULT_PAGE_INDEX;
      })
    );
  }, []);

  const handleDateRangeChange = useCallback((dateRange: RangeDatePickerValue) => {
    setSearchFilter((state) =>
      produce(state, (draft) => {
        draft.date = {
          from: dateRange.from ?? '',
          to: dateRange.to ?? '',
        };
        draft.pageIndex = DEFAULT_PAGE_INDEX;
      })
    );
  }, []);

  const handleCountryChange = useCallback(
    (countryName: string | null, currency: string | undefined | null) => {
      setSearchFilter((state) =>
        produce(state, (draft) => {
          draft.country = countryName;
          draft.currency = currency;
          draft.pageIndex = DEFAULT_PAGE_INDEX;
        })
      );
    },
    []
  );

  const handleResetFilters = useCallback(() => {
    setSearchFilter(initialSearchFilter);
  }, [initialSearchFilter]);

  return {
    searchFilter,
    data,
    handlePageIndexChange,
    handleKeywordChange,
    handleSortTypeChange,
    handleCurrencyChange,
    handleCategoriesChange,
    handlePriceRangeChange,
    handleDateRangeChange,
    handleCountryChange,
    handleResetFilters,
    handleRefreshData: refreshData,
  };
};

export default useSearch;
