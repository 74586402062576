import { immerable } from 'immer';

export enum PhotoStatus {
  PENDING_UPLOAD = 'PENDING_UPLOAD',
  PENDING_REMOVAL = 'PENDING_REMOVAL',
  UPLOADED = 'UPLOADED',
}

class Photo {
  readonly [immerable] = true;

  readonly id: string;
  readonly file: File;
  readonly status: PhotoStatus;
  readonly url: string;
  progress: number;
  error: Record<string, unknown> | null;

  static ofFile(id: string, file: File, status: PhotoStatus = PhotoStatus.PENDING_UPLOAD) {
    return new Photo(id, file, status);
  }

  private constructor(id: string, file: File, status: PhotoStatus) {
    this.id = id;
    this.file = file;
    this.status = status;
    this.url = URL.createObjectURL(file);
    this.progress = 0;
    this.error = null;
  }

  isPreviewEligible() {
    return [PhotoStatus.PENDING_UPLOAD, PhotoStatus.UPLOADED].includes(this.status);
  }
}

export default Photo;
