import { ContactType } from '../../../utils/businessConstants';

import { User } from '../../../auth/useTokens';
import { Owner } from '../../../types';

const mapUserToOwner = (user: User): Owner => ({
  id: user.pmcId,
  name: user.name,
  contacts: [
    {
      contactType: ContactType.EPAM_EMAIL,
      value: user.email,
    },
  ],
});

export default mapUserToOwner;
