import { FC, SyntheticEvent } from 'react';
import Photo, { PhotoStatus } from '../utils/Photo';
import AsyncImage from '../../../../components/AsyncImage';

type AsyncPhotoProps = {
  photo: Photo;
  alt: string;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
  timeout?: number;
};

const AsyncPhoto: FC<React.PropsWithChildren<AsyncPhotoProps>> = ({
  photo,
  alt,
  className,
  onClick,
  timeout,
}) => (
  <>
    {photo.status === PhotoStatus.PENDING_UPLOAD ? (
      <img src={photo.url} alt={alt} className={className} onClick={onClick} />
    ) : (
      <AsyncImage
        attachmentId={photo.id}
        alt={alt}
        className={className}
        onClick={onClick}
        timeout={timeout}
      />
    )}
  </>
);

export default AsyncPhoto;
