import { FC } from 'react';

type PageRedirectProps = {
  to: string;
};

const PageRedirect: FC<React.PropsWithChildren<PageRedirectProps>> = ({ to }) => {
  window.location.href = to;
  return null;
};

export default PageRedirect;
