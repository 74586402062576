import { FC } from 'react';
import { IModal, IHasChildren } from '@epam/uui-core';
import { ModalBlocker, ModalWindowProps } from '@epam/promo';
import { ModalWindow } from '@epam/uui';

type BlockingModalProps = {
  modalWindowWidth?: ModalWindowProps['width'];
} & IModal<unknown> &
  IHasChildren;

const BlockingModal: FC<React.PropsWithChildren<BlockingModalProps>> = ({
  modalWindowWidth = '600',
  children,
  ...props
}) => (
  <ModalBlocker disallowClickOutside {...props}>
    <ModalWindow width={modalWindowWidth}>{children}</ModalWindow>
  </ModalBlocker>
);

export default BlockingModal;
