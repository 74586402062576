import { FC } from 'react';
import { useEffectOnce, useSessionStorage } from 'react-use';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { isEmpty } from 'lodash';

import PageRedirect from './PageRedirect';
import { Spinner } from '@epam/promo';

import useQueryParams from './useQueryParams';
import useTokens from './useTokens';

import { SESSION_STORAGE_KEYS } from '../utils/constants';
import { TOKEN_REQUEST_PATH } from './constants';
import { UserDetailsServiceUrl } from '../apiUtils/constants';
import useUserDetails from './useUserDetails';

type TokenResponse = {
  access_token: string;
  id_token: string;
  refresh_token: string;
};

const AuthToken: FC<React.PropsWithChildren<unknown>> = () => {
  const [entryUrl] = useSessionStorage(SESSION_STORAGE_KEYS.ENTRY_URL, '', true);

  const { saveTokens, accessToken } = useTokens();
  const { saveDetails } = useUserDetails();

  const query = useQueryParams();
  const code = query.get('code');

  if (!code) {
    throw new Error('Authorization code is missing.');
  }

  useEffectOnce(() => {
    const fetchTokens = async () => {
      const client = axios.create();
      axiosRetry(client, { retries: 5, retryDelay: (retryCount) => retryCount * 1000 });

      const { data: tokens } = await client
        .post<TokenResponse>(TOKEN_REQUEST_PATH, null, {
          params: {
            authorizationCode: code,
          },
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(`Error fetching authorization token`, err);
          throw err;
        });

      const { access_token, id_token, refresh_token } = tokens;

      try {
        // We also get the user's details to see if they are an Admin
        const { data: userDetails } = await client.get(UserDetailsServiceUrl.userDetails, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        saveDetails(userDetails);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching user details`, error);
      }

      saveTokens(id_token, access_token, refresh_token);
    };

    fetchTokens();
  });

  if (!isEmpty(accessToken)) {
    return <PageRedirect to={entryUrl || '/'} />;
  }

  return <Spinner rawProps={{ role: 'progressbar' }} />;
};

export default AuthToken;
