import { FC, useCallback, useState } from 'react';
import {
  Button,
  Panel,
  ScrollBars,
  ModalHeader,
  FlexSpacer,
  ModalFooter,
  HintAlert,
} from '@epam/promo';
import { Text, FlexRow } from '@epam/uui';
import { IModal } from '@epam/uui-core';

import BlockingModal from './BlockingModal';

import {
  getDraftToPublishMessage,
  getPublishToDraftMessage,
  getPublishToDraftHeader,
  getDraftToPublishHeader,
} from '../Notifications/constants';

import { Advertisement } from '../../types';

type ChangeAdStatusProps = {
  onChangeStatus: () => Promise<void>;
  modalProps: IModal<void>;
  isPublished: boolean;
  advertisement: Advertisement | undefined;
};

import styles from './ChangeAdStatus.module.scss';

const ChangeAdStatus: FC<React.PropsWithChildren<ChangeAdStatusProps>> = ({
  isPublished,
  modalProps,
  onChangeStatus,
  advertisement,
}) => {
  const [isLoading, setLoading] = useState(false);

  const statusChangeHeader = isPublished
    ? getPublishToDraftHeader(advertisement!.title)
    : getDraftToPublishHeader(advertisement!.title);

  const statusChangeMessage = isPublished ? getPublishToDraftMessage() : getDraftToPublishMessage();

  const handleConfirm = useCallback(() => {
    setLoading(true);
    onChangeStatus().then(modalProps.success);
  }, [onChangeStatus, modalProps, setLoading]);

  return (
    <BlockingModal {...modalProps}>
      <Panel background="white">
        <ModalHeader cx={styles.modalHeader} title={statusChangeHeader} />
        <ScrollBars hasTopShadow hasBottomShadow>
          <FlexRow padding="24" vPadding="12">
            <HintAlert>
              <Text size="24" fontSize="14">
                {statusChangeMessage}
              </Text>
            </HintAlert>
          </FlexRow>
        </ScrollBars>
        <ModalFooter>
          <FlexSpacer />
          <Button
            color="gray50"
            fill="white"
            caption="Cancel"
            isDisabled={isLoading}
            onClick={modalProps.abort}
          />
          <Button
            isDisabled={isLoading}
            color="blue"
            caption={isPublished ? 'Confirm reversion' : 'Confirm publication'}
            onClick={handleConfirm}
          />
        </ModalFooter>
      </Panel>
    </BlockingModal>
  );
};

export default ChangeAdStatus;
