import { useCallback, useState } from 'react';

import Photo from '../../../pages/CreateAndEditAdvertisementPage/components/utils/Photo';

export interface PhotoViewerState {
  isPhotoViewerVisible: boolean;
  currentImageIndex: number;
  setCurrentImageIndex: (imageIndex: number) => void;
  onPhotoViewerToggle: () => void;
  onPhotoViewerNext: () => void;
  onPhotoViewerPrev: () => void;
}

const usePhotoViewer = (attachments: Photo[]): PhotoViewerState => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPhotoViewerVisible, setIsPhotoViewerVisible] = useState(false);

  const onPhotoViewerToggle = useCallback(() => {
    setIsPhotoViewerVisible((state) => !state);
  }, []);

  const onPhotoViewerNext = useCallback(() => {
    if (attachments.length === currentImageIndex + 1) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  }, [attachments.length, currentImageIndex]);

  const onPhotoViewerPrev = useCallback(() => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(attachments.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  }, [attachments.length, currentImageIndex]);

  return {
    isPhotoViewerVisible,
    currentImageIndex,
    setCurrentImageIndex,
    onPhotoViewerToggle,
    onPhotoViewerNext,
    onPhotoViewerPrev,
  };
};

export default usePhotoViewer;
