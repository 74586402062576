import { FC, useCallback, useContext, useMemo, useRef, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { produce } from 'immer';
import { isEmpty } from 'lodash';

import DeferredFileUploader from '../components/DeferredFileUploader';
import { ErrorAlert, SuccessAlert, WarningAlert } from '../../../components';
import Layout from '../components/Layout';
import { ReferenceDataContext } from '../../../components/ReferenceDataProvider';

import { getAdvertisementFormMetadata } from '../utils/validationSchema';
import descriptionFormatter from '../../../utils/descriptionFormatter';
import { getCurrencyByCountryId } from '../utils/getCurrencyById';
import mapUserToOwner from '../utils/mapUserToOwner';
import { useAuth } from '../../../auth/AuthProvider';
import { useAxios } from '../../../apiHooks/useAxios';
import {
  useCategoriesDataSource,
  useCountriesDataSoruce,
  useCurrencyDataSource,
} from '../hooks/dataSources';
import useFileUploadManager from '../components/utils/useFileUploadManager';
import { useForm } from '@epam/uui';
import { useUuiContext } from '@epam/uui-core';

import { AdvertisementServiceUrl } from '../../../apiUtils/constants';
import { countryAliases, PREVIEW_DEFAULT_STATUS } from '../constants';
import {
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from '../../../components/Notifications/constants';

import { Advertisement, Location, Owner } from '../../../types';
import { AdvertisementFormState } from '../types';
import { User } from '../../../auth/useTokens';

const resolveDefaultUserLocation = (user: User, locations: Location[]) => {
  const { country: userCountry, city: userCity } = user;
  const mappedUserCountry = countryAliases[userCountry] ?? userCountry;

  const userLocations = locations.filter(({ country }) => country === mappedUserCountry);

  if (isEmpty(userLocations)) {
    return {
      country: undefined,
      city: undefined,
      currency: undefined,
    };
  }

  return {
    country: mappedUserCountry,
    city: userLocations.find(({ city }) => city === userCity)?.city,
    currency: userLocations[0].currency,
  };
};

const buildEmptyAdvertisement = (
  currentUser: User,
  owner: Owner,
  locations: Location[]
): AdvertisementFormState => ({
  id: '',
  title: '',
  description: '',
  ...resolveDefaultUserLocation(currentUser, locations),
  status: PREVIEW_DEFAULT_STATUS,
  attachments: [],
  owner,
});

const CreateAdvertisementPage: FC<React.PropsWithChildren<RouteComponentProps>> = () => {
  const svc = useUuiContext();
  const { categories, locations, currencies, countries } = useContext(ReferenceDataContext);
  const { user } = useAuth();
  const { axios } = useAxios();
  const [adId, setAdId] = useState<string>();
  const {
    images,
    highlight,
    uploadDeleteInProgress,
    handlePendingUploadDelete,
    handleAddPhotos,
    handleRemovePhoto,
    handleHighlightChange,
  } = useFileUploadManager();

  const owner = useMemo(() => mapUserToOwner(user), [user]);
  const defaultFormData = useMemo(
    () => buildEmptyAdvertisement(user, owner, locations),
    [locations, owner, user]
  );

  const categoriesDataSource = useCategoriesDataSource(categories);
  const currencyDataSource = useCurrencyDataSource(currencies);
  const countryDataSource = useCountriesDataSoruce(countries);

  const displayValidationNotification = useRef<boolean>(false);

  const {
    lens,
    save,
    value: advertisementFormState,
    onValueChange,
    isInProgress,
    isInvalid,
  } = useForm<AdvertisementFormState>({
    value: defaultFormData,
    beforeLeave: null,
    onSave: (ad: Advertisement) => {
      const payload = produce(ad, (draft) => {
        draft.description = descriptionFormatter(draft.description);
        draft.highlight = undefined;
      });

      return new Promise((resolve, reject) => {
        axios
          .post<Advertisement>(AdvertisementServiceUrl.advertisementList, payload)
          .then(async ({ data: createdAdvertisement }) => {
            const id = createdAdvertisement.id;
            setAdId(id);

            await handlePendingUploadDelete(id)
              .then(({ remainingPhotos, newHighlight }) => {
                const payload = produce(createdAdvertisement, (draft) => {
                  draft.description = descriptionFormatter(draft.description);
                  draft.attachments = remainingPhotos.map((a) => a.id);
                  draft.highlight = newHighlight;
                  draft.owner = owner;
                });

                return axios
                  .put<Advertisement>(AdvertisementServiceUrl.advertisementDetails(id), payload)
                  .then(({ data }) => resolve({ form: data }))
                  .then(() => SuccessAlert(SuccessMessage.AdvertisementCreate, svc));
              })
              .catch(() => {
                ErrorAlert(ErrorMessage.AttachmentSaveOnCreate, svc);

                // If there has been an error saving the images, we delete the advertisement silently
                axios.delete(AdvertisementServiceUrl.advertisementDetails(id));
              });
          })
          .catch(reject);
      });
    },
    onSuccess: () => {
      // There is currently a bug when redirecting to the advertisement page after saving,
      // the form thinks we aren't done with the changes and triggers the beforeLeave event
      // Issue opened here: https://github.com/epam/UUI/issues/2191
      svc.uuiRouter.redirect({ pathname: `/advertisement/${adId}` });
    },
    onError: () => {
      ErrorAlert(ErrorMessage.AdvertisementSave, svc);
    },
    getMetadata: getAdvertisementFormMetadata,
  });

  useEffect(() => {
    if (displayValidationNotification.current) {
      if (isInvalid) {
        WarningAlert(WarningMessage.AdvertisementValidationError, svc);
      }

      displayValidationNotification.current = false;
    }
  });

  const handleSave = useCallback(
    (targetStatus: string) => () => {
      displayValidationNotification.current = true;
      const currentStatus = advertisementFormState.status.id;

      if (currentStatus !== targetStatus) {
        onValueChange(
          produce(advertisementFormState, (draft) => {
            draft.status.id = targetStatus;
          })
        );
      }

      save();
    },
    [onValueChange, save, advertisementFormState]
  );

  const handleCountryChange = useCallback(
    (newCountryValue: string) => {
      onValueChange(
        produce(advertisementFormState, (draft) => {
          draft.country = newCountryValue;
          draft.city = undefined;
          draft.currency = getCurrencyByCountryId(countryDataSource, newCountryValue);
        })
      );
    },
    [onValueChange, advertisementFormState, countryDataSource]
  );

  useEffect(() => {
    onValueChange(
      produce(advertisementFormState, (draft) => {
        draft.highlight = highlight;
      })
    );
  }, [advertisementFormState, highlight, onValueChange]);

  const renderFileUploader = useCallback(
    () => (
      <DeferredFileUploader
        photos={images}
        highlight={highlight}
        onAddPhotos={handleAddPhotos}
        onDeletePhoto={handleRemovePhoto}
        onHighlightChange={handleHighlightChange}
        actionInProgress={uploadDeleteInProgress}
      />
    ),
    [
      images,
      highlight,
      handleAddPhotos,
      handleRemovePhoto,
      handleHighlightChange,
      uploadDeleteInProgress,
    ]
  );

  return (
    <Layout
      formState={advertisementFormState}
      lens={lens}
      currencies={currencyDataSource}
      categories={categoriesDataSource}
      countries={countryDataSource}
      attachments={images}
      attachmentUploadInProgress={uploadDeleteInProgress}
      disabled={uploadDeleteInProgress || isInProgress}
      onCountryChange={handleCountryChange}
      onSave={handleSave}
      renderFileUploader={renderFileUploader}
    />
  );
};

export default withRouter(CreateAdvertisementPage);
