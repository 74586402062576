import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';

import Photo, { PhotoStatus } from '../pages/CreateAndEditAdvertisementPage/components/utils/Photo';
import { useAxios } from './useAxios';

import { AdvertisementServiceUrl } from '../apiUtils/constants';
import placeholderImageDataUrl from '../icons/image_placeholder.png';

export const useAttachmentFiles = (
  onPhotosFetched: (photos: Photo[]) => void,
  attachmentIds: string[] = []
) => {
  const { axios } = useAxios();

  useEffect(() => {
    if (!isEmpty(attachmentIds)) {
      const fetchPhotos = async () => {
        const placeholderImageBlob = await (await fetch(placeholderImageDataUrl)).blob();
        const placeholderImageFile = new File([placeholderImageBlob], 'image_placeholder.png', {
          type: 'image/png',
        });

        const mapFileData = (photoRes: AxiosResponse<Blob, unknown>, index: number) => {
          const attachmentId = attachmentIds[index];
          const file = new File(
            [photoRes.data],
            AdvertisementServiceUrl.attachmentDetail(attachmentId)
          );
          return Photo.ofFile(attachmentId, file, PhotoStatus.UPLOADED);
        };

        const fetchPhoto = (id: string, index: number) =>
          axios
            .get<Blob>(AdvertisementServiceUrl.attachmentDetail(id), { responseType: 'blob' })
            .then((response) => mapFileData(response, index))
            .catch(() =>
              Promise.resolve(Photo.ofFile(id, placeholderImageFile, PhotoStatus.UPLOADED))
            );

        const photos = await Promise.all(attachmentIds.map(fetchPhoto));
        onPhotosFetched(photos);
      };

      fetchPhotos();
    }
  }, [attachmentIds, axios, onPhotosFetched]);
};
