import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { UuiContext, useUuiServices, HistoryAdaptedRouter } from '@epam/uui-core';
import { Snackbar, Modals } from '@epam/uui-components';
import { ErrorHandler } from '@epam/loveship';
import '@epam/uui-components/styles.css';
import '@epam/uui/styles.css';
import '@epam/promo/styles.css';
import '@epam/loveship/styles.css';
import { App } from './App';

const history = createBrowserHistory();
const router = new HistoryAdaptedRouter(history);
const container = document.getElementById('root');

const UuiEnhancedApp = () => {
  const { services } = useUuiServices({ router });
  return (
    <UuiContext.Provider value={services}>
      <ErrorHandler>
        <Router history={history}>
          <App />
        </Router>
        <Snackbar />
        <Modals />
      </ErrorHandler>
    </UuiContext.Provider>
  );
};

const root = createRoot(container!);

root.render(<UuiEnhancedApp />);

// Dev environment
// eslint-disable-next-line no-console
console.info(`Application is built for '${process.env.REACT_APP_ENV}' environment`);
