import { ReactElement } from 'react';
import { noop } from 'lodash';

import { CommonContexts, INotification } from '@epam/uui-core';
import { WarningNotification } from '@epam/promo';
import { Text } from '@epam/uui';

import { WarningMessage } from './constants';

const WarningAlert = (
  message: WarningMessage | string | ReactElement,
  svc: CommonContexts<unknown, unknown>
) => {
  svc.uuiNotifications
    .show(
      (props: INotification) => (
        <WarningNotification {...props}>
          <Text size="36" fontSize="14">
            {message}
          </Text>
        </WarningNotification>
      ),
      { duration: 8 }
    )
    .then(noop)
    .catch(noop);
};

export default WarningAlert;
