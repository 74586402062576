import { createContext, useContext, useMemo, ReactNode, FC } from 'react';
import { useLocation } from 'react-router-dom';

import AuthToken from './AuthToken';
import PageRedirect from './PageRedirect';

import useTokens, { User } from './useTokens';

import {
  LOGIN_REQUEST_PATH,
  LOGIN_RETURN_PATH,
  LOGOUT_PAHT,
  TERMS_AND_CONDITIONS_PATH,
} from './constants';

const pathsOutsideAuthentication = [LOGOUT_PAHT, TERMS_AND_CONDITIONS_PATH];

type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextState = {
  user?: User;
};

export const AuthContext = createContext<AuthContextState>({});

const AuthProvider: FC<React.PropsWithChildren<AuthProviderProps>> = ({ children }) => {
  const { isAuthenticated, user } = useTokens();
  const context = useMemo(() => ({ user }), [user]);

  const location = useLocation();
  const pathName = location.pathname;

  const isTokenRequestPath = useMemo(() => pathName === LOGIN_RETURN_PATH, [pathName]);

  const authenticationIsRequired = useMemo(
    () => !pathsOutsideAuthentication.includes(pathName),
    [pathName]
  );

  if (!isAuthenticated && authenticationIsRequired) {
    if (isTokenRequestPath) {
      return <AuthToken />;
    }

    return <PageRedirect to={LOGIN_REQUEST_PATH} />;
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const { user } = useContext(AuthContext);

  return useMemo(
    () => ({
      user: user!,
    }),
    [user]
  );
};

export default AuthProvider;
