import { FC } from 'react';
import classNames from 'classnames';

import { FlexRow } from '@epam/promo';

import { IconButton, RichTextView, Text } from '@epam/loveship';
import { ReactComponent as CloseIcon } from '@epam/assets/icons/common/navigation-close-24.svg';
import { ReactComponent as LeftIcon } from '@epam/assets/icons/common/navigation-chevron-left-24.svg';
import { ReactComponent as RightIcon } from '@epam/assets/icons/common/navigation-chevron-right-24.svg';

import Photo from '../../../../pages/CreateAndEditAdvertisementPage/components/utils/Photo';

import { PhotoViewerState } from '../usePhotoViewer';

import css from './PhotoViewer.module.scss';
import AsyncPhoto from '../../../../pages/CreateAndEditAdvertisementPage/components/AsyncPhoto/AsyncPhoto';

interface PhotoViewerProps {
  photosToView: Photo[];
  photoViewerState: PhotoViewerState;
  alt: string;
}

const PhotoViewer: FC<React.PropsWithChildren<PhotoViewerProps>> = ({
  photosToView: attachments,
  photoViewerState,
  alt,
}) => {
  const {
    isPhotoViewerVisible,
    currentImageIndex,
    setCurrentImageIndex,
    onPhotoViewerNext,
    onPhotoViewerPrev,
    onPhotoViewerToggle,
  } = photoViewerState;

  if (!isPhotoViewerVisible) {
    return null;
  }

  const renderImagesList = () => {
    const imageClass = (index: number) =>
      classNames(css.thumbnailImage, {
        [classNames(css.currentThumbnail)]: index === currentImageIndex,
      });

    return (
      <FlexRow>
        {attachments.length >= 2 && (
          <ul className={css.thumbnailsList}>
            {attachments.map((thumbnail, index) => {
              return (
                <li key={thumbnail.id} className={css.thumbnail}>
                  <AsyncPhoto
                    onClick={() => setCurrentImageIndex(index)}
                    className={imageClass(index)}
                    alt={alt}
                    photo={thumbnail}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </FlexRow>
    );
  };

  return (
    <div className={css.container}>
      <FlexRow cx={css.titleContainer}>
        <RichTextView>
          <Text cx={css.title}>{alt}</Text>
        </RichTextView>
        <IconButton
          color="night600"
          cx={css.closeButton}
          icon={CloseIcon}
          onClick={onPhotoViewerToggle}
        />
      </FlexRow>
      <div className={css.imageContainer}>
        <AsyncPhoto className={css.image} alt={alt} photo={attachments[currentImageIndex]} />
        {renderImagesList()}
      </div>

      {attachments.length >= 2 && (
        <>
          <IconButton
            color="night600"
            cx={css.prevButton}
            icon={LeftIcon}
            onClick={onPhotoViewerPrev}
          />
          <IconButton
            color="night600"
            cx={css.nextButton}
            icon={RightIcon}
            onClick={onPhotoViewerNext}
          />
        </>
      )}
    </div>
  );
};

export default PhotoViewer;
