import { useArrayDataSource } from '@epam/uui-core';

import { Category, City, Country, Currency } from '../../../types';

export const useCategoriesDataSource = (categories: Category[] = []) =>
  useArrayDataSource<Category, string, unknown>(
    {
      items: categories,
    },
    [categories]
  );

export const useCitiesDataSource = (cities: City[] = []) =>
  useArrayDataSource<City, string, unknown>({ items: cities, getId: (item) => item.name }, [
    cities,
  ]);

export const useCountriesDataSoruce = (countries: Country[] = []) =>
  useArrayDataSource<Country, string, unknown>(
    {
      items: countries,
      getId: (item) => item.name,
    },
    [countries]
  );

export const useCurrencyDataSource = (currencies: Currency[] = []) =>
  useArrayDataSource<Currency, string, unknown>(
    {
      items: currencies,
    },
    [currencies]
  );
