import { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Badge, Button, Text } from '@epam/promo';
import UploadStatus from '../UploadStatus';
import formatBytes from '../utils/formatBytes';
import Photo, { PhotoStatus } from '../utils/Photo';
import { ReactComponent as pinIcon } from '@epam/assets/icons/common/action-pin_on-18.svg';
import styles from './thumbnail.module.scss';

export type ThumbnailProps = {
  photo: Photo;
  highlighted: boolean;
  onDelete: (photo: Photo) => void;
  onSetHighlighted: (highlight: string) => void;
  actionInProgress?: boolean;
};

const Thumbnail: FC<React.PropsWithChildren<ThumbnailProps>> = ({
  photo,
  highlighted,
  onDelete,
  onSetHighlighted,
  actionInProgress = false,
}) => {
  const [hover, setHover] = useState(false);
  const handleRemove = useCallback(() => {
    onDelete(photo);
  }, [onDelete, photo]);

  const thumbnailClass = useMemo(
    () =>
      classNames(styles.thumbnail, {
        [styles.amber]:
          photo.progress < 100 &&
          [PhotoStatus.PENDING_REMOVAL, PhotoStatus.PENDING_UPLOAD].includes(photo.status),
        [styles.red]: !!photo.error,
      }),
    [photo]
  );

  return (
    <div className={thumbnailClass}>
      <div
        className={styles.imageContainer}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {highlighted && (
          <Badge
            cx={styles.highlightControl}
            icon={pinIcon}
            color="cyan"
            fill="semitransparent"
            caption="Highlighted"
          />
        )}
        {hover && !highlighted && (
          <Button
            cx={styles.highlightControl}
            fill="white"
            color="blue"
            caption="Set as highlighted"
            onClick={() => onSetHighlighted(photo.id)}
          />
        )}
        <img className={styles.image} src={photo.url} alt="thumbnail" />
      </div>
      <div className={styles.controls}>
        <Text cx={styles.text} color="gray80" font="sans-semibold" fontSize="14" lineHeight="18">
          {photo.file.name}
        </Text>
        <Text
          cx={classNames(styles.text, styles.imageSize)}
          font="sans-semibold"
          fontSize="12"
          lineHeight="12"
        >
          {formatBytes(photo.file.size)}
        </Text>
        <Button
          color="gray50"
          caption="Remove"
          onClick={handleRemove}
          isDisabled={actionInProgress || photo.status === PhotoStatus.PENDING_REMOVAL}
        />
        <UploadStatus error={photo.error} progress={photo.progress} status={photo.status} />
      </div>
    </div>
  );
};

export default Thumbnail;
