import { useCallback, useContext, useMemo, FC, useState, SetStateAction } from 'react';
import { produce } from 'immer';
import { isEmpty } from 'lodash';

import EmptySearchList from '../MainPage/components/EmptySearchList';
import { FlexCell, FlexRow, Paginator } from '@epam/promo';
import { FlexSpacer, Panel, RichTextView, TabButton } from '@epam/loveship';
import { AdvertisementTile, PageSpinner, ResultsFilter, CategoriesFilter } from '../../components';
import { Page } from '../../templates';
import { ReferenceDataContext } from '../../components/ReferenceDataProvider';

import mapUserToOwner from '../CreateAndEditAdvertisementPage/utils/mapUserToOwner';
import { useAuth } from '../../auth/AuthProvider';
import { useExpiringAdWarnings } from './useExpiringAdWarnings';
import { useSearch } from '../../apiHooks';

import { AdvertisementApiSearchResponse, DEFAULT_SEARCH_FILTERS } from '../../apiHooks/useSearch';
import { ResultSortingTypes } from '../../components/ResultsHeader/contants';

import { Advertisement, Owner } from '../../types';

import cssMainPage from '../MainPage/MainPage.module.scss';
import { AdvertisementStatus } from '../../utils/businessConstants';
import SortMenu from '../../components/ResultsHeader/SortMenu';
import style from './MyAdvertisementsPage.module.scss';
import useStoredScrollPosition from '../../apiHooks/useStoredScrollPosition';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import useStoredState from '../../apiHooks/useStoredState';

const getInitialFilters = (owner: Owner, status: AdvertisementStatus) =>
  produce(DEFAULT_SEARCH_FILTERS, (draft) => {
    draft.owner = owner;
    if (status) {
      draft.status = status;
    }
  });

const MyAdvertisementsPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { categories } = useContext(ReferenceDataContext);
  const { user } = useAuth();
  const owner = useMemo(() => mapUserToOwner(user), [user]);

  const publishedAds = useSearch(getInitialFilters(owner, AdvertisementStatus.PUBLISH));
  const draftAds = useSearch(getInitialFilters(owner, AdvertisementStatus.DRAFT));

  const displayedAds = useCallback(
    (view: AdvertisementStatus) => {
      switch (view) {
        case AdvertisementStatus.PUBLISH:
          return publishedAds;
        case AdvertisementStatus.DRAFT:
          return draftAds;
      }
    },
    [draftAds, publishedAds]
  );

  const handleRefreshData = useCallback(() => {
    publishedAds.handleRefreshData();
    draftAds.handleRefreshData();
  }, [draftAds, publishedAds]);

  const [view, setView] = useState(AdvertisementStatus.PUBLISH);

  const storeState = useCallback(
    () => ({
      view,
      publishedAdsPageIndex: publishedAds.searchFilter.pageIndex,
      draftAdsPageIndex: draftAds.searchFilter.pageIndex,
    }),
    [view, draftAds.searchFilter.pageIndex, publishedAds.searchFilter.pageIndex]
  );

  const restoreState = useCallback(
    ({
      view,
      publishedAdsPageIndex,
      draftAdsPageIndex,
    }: {
      view: SetStateAction<AdvertisementStatus>;
      publishedAdsPageIndex: number;
      draftAdsPageIndex: number;
    }) => {
      setView(view);
      publishedAds.handlePageIndexChange(publishedAdsPageIndex);
      draftAds.handlePageIndexChange(draftAdsPageIndex);
    },
    [draftAds, publishedAds]
  );

  useStoredState(LOCAL_STORAGE_KEYS.MY_ADS_PAGE_STORED_STATE, storeState, restoreState);

  const isReadyToScroll = useCallback(() => !!publishedAds && !!draftAds, [publishedAds, draftAds]);

  useStoredScrollPosition(LOCAL_STORAGE_KEYS.MY_ADS_PAGE_SCROLL_POS, isReadyToScroll);

  const statusesOfExpiringAds = useExpiringAdWarnings();
  const expiringPublishedAdExists = statusesOfExpiringAds.has(AdvertisementStatus.PUBLISH);
  const expiringDraftAdExists = statusesOfExpiringAds.has(AdvertisementStatus.DRAFT);

  const handlePaginationChange = useCallback(
    (page: number) => {
      displayedAds(view).handlePageIndexChange(page);
      window.scrollTo(0, 100);
    },
    [displayedAds, view]
  );

  const renderAdvertisementTiles = useCallback(
    (items: Advertisement[], handleRefreshData: () => void) => (
      <div className={cssMainPage.grid}>
        {items.map((advertisement: Advertisement) => (
          <AdvertisementTile
            key={advertisement.id}
            advertisement={advertisement}
            onRefreshData={handleRefreshData}
            withContextMenu
            withExpirationDetails
          />
        ))}
      </div>
    ),
    []
  );

  const handlePublishedTabClick = useCallback(() => {
    setView(AdvertisementStatus.PUBLISH);
  }, []);

  const handleDraftsTabClick = useCallback(() => {
    setView(AdvertisementStatus.DRAFT);
  }, []);

  const total = useCallback((ads: { data: AdvertisementApiSearchResponse | undefined }) => {
    return ads.data ? ads.data.totalElements : '...';
  }, []);

  const advertisements = displayedAds(view);

  if (!advertisements.data) {
    return (
      <div aria-label="loading">
        <PageSpinner aria-label="Loading" />
      </div>
    );
  } else {
    const {
      searchFilter,
      data,
      handleSortTypeChange,
      handleCurrencyChange,
      handleCategoriesChange,
      handlePriceRangeChange,
      handleDateRangeChange,
      handleCountryChange,
      handleResetFilters,
    } = advertisements;

    const { items, totalPages, totalElements } = data;

    const paginator = (
      <Paginator
        size="24"
        totalPages={totalPages}
        value={searchFilter.pageIndex}
        onValueChange={handlePaginationChange}
      />
    );

    const content = (
      <>
        <Panel cx={style.headerPanel}>
          <FlexRow>
            <TabButton
              caption={`Published (${total(publishedAds)})`}
              captionCX={style.statusCaption}
              isLinkActive={view === AdvertisementStatus.PUBLISH}
              onClick={handlePublishedTabClick}
              withNotify={expiringPublishedAdExists}
              size="36"
            />
            <TabButton
              caption={`Drafts (${total(draftAds)})`}
              captionCX={style.statusCaption}
              isLinkActive={view === AdvertisementStatus.DRAFT}
              onClick={handleDraftsTabClick}
              withNotify={expiringDraftAdExists}
              size="36"
            />
            <FlexSpacer />
            {totalPages > 1 && <div>{paginator}</div>}
            {totalElements > 0 && (
              <SortMenu
                sortType={searchFilter.sortType ?? ResultSortingTypes.Newest}
                onSortTypeChange={handleSortTypeChange}
                isDisabled={false}
              />
            )}
          </FlexRow>
        </Panel>
        {!isEmpty(items) && renderAdvertisementTiles(items, handleRefreshData)}
        {totalPages > 1 && <FlexRow cx={cssMainPage.paginator}>{paginator}</FlexRow>}
        {isEmpty(items) && <EmptySearchList />}
      </>
    );

    return (
      <Page>
        <div className={cssMainPage.container}>
          <FlexRow padding="12" alignItems="top">
            <RichTextView>
              <h2>{'My Ads'}</h2>
            </RichTextView>
          </FlexRow>
          <FlexRow padding="12" alignItems="top">
            <FlexCell cx={cssMainPage.sidebar} width="100%">
              <CategoriesFilter
                categories={categories}
                categoriesFiltered={searchFilter.categories}
                onCategoriesSelectChange={handleCategoriesChange}
              />
              <ResultsFilter
                filters={searchFilter}
                onDateRangeChange={handleDateRangeChange}
                onPriceRangeChange={handlePriceRangeChange}
                onCurrencyChange={handleCurrencyChange}
                onCountryChange={handleCountryChange}
                onClearSearchProperties={handleResetFilters}
              />
            </FlexCell>
            <FlexCell cx={cssMainPage.advertisements} width="100%">
              {content}
            </FlexCell>
          </FlexRow>
        </div>
      </Page>
    );
  }
};

export default MyAdvertisementsPage;
