import axios from 'axios';
import { useMemo } from 'react';
import { noop } from 'lodash';

import { ErrorNotification } from '@epam/promo';
import { Text } from '@epam/uui';
import { INotification, useUuiContext } from '@epam/uui-core';

import useTokens from '../auth/useTokens';
import { ErrorMessage } from '../components/Notifications/constants';
import { ErrorAlert } from '../components';

export const useAxios = () => {
  const { accessToken, resetTokens } = useTokens();
  const svc = useUuiContext();

  const client = useMemo(() => {
    const client = axios.create();

    client.interceptors.request.use(
      (config) => {
        config.timeout = 60000; // Wait for 60 seconds before timing out
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error.response?.status) {
          case 401:
            resetTokens();
            window.location.reload();
            break;
          case 429:
            ErrorAlert(ErrorMessage.TooManyRequests, svc);
            break;
        }
        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
          svc.uuiNotifications
            .show(
              (props: INotification) => (
                <ErrorNotification
                  {...props}
                  actions={[
                    {
                      name: 'Ok',
                      action: () => {
                        location.reload();
                      },
                    },
                  ]}
                  onClose={() => {
                    location.reload();
                  }}
                >
                  <Text size="36" fontSize="14">
                    {ErrorMessage.Network}
                  </Text>
                </ErrorNotification>
              ),
              { duration: 'forever' }
            )
            .then(noop)
            .catch(noop);
        }
        return Promise.reject(error);
      }
    );

    return client;
  }, [accessToken, svc, resetTokens]);

  return { axios: client };
};
