import { FC } from 'react';
import { ErrorPage } from '@epam/loveship';
import { RichTextView } from '@epam/promo';
import css from './PermissionsDeniedErrorPage.module.scss';
import { IHasCX, ErrorPageInfo } from '@epam/uui-core';

// TODO ask uui team to corectly expose the component interface
type ErrorPageProps = IHasCX &
  ErrorPageInfo & {
    theme?: 'light' | 'dark';
  };

const permissionDeniedImageUrl = '/resources/error_403.svg';

const PermissionDeniedErrorPage: FC<React.PropsWithChildren<ErrorPageProps>> = (props) => (
  <>
    <ErrorPage
      cx={css.permissionDeniedErrorPage}
      imageUrl={permissionDeniedImageUrl}
      title={
        <RichTextView>
          <h1>YOU HAVE NO PERMISSIONS!</h1>
        </RichTextView>
      }
      {...props}
    />
  </>
);

export default PermissionDeniedErrorPage;
