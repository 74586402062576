import { DEFAULT_CURRENCY } from '../constants';

import { ArrayDataSource } from '@epam/uui-core';
import { Country } from '../../../types';

export const getCurrencyByCountryId = (
  countryDataSource: ArrayDataSource<Country, string, unknown>,
  countryName: string,
  defaultCurrency = DEFAULT_CURRENCY
) => countryDataSource.getById(countryName)?.currency ?? defaultCurrency;
