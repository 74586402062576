import md5 from 'md5';
import { User } from '../../../auth/useTokens';
import { Owner } from '../../../types';

const CDN_HOST = 'https://static.cdn.epam.com/avatar/';
export const AVATAR_PLACEHOLDER = '/resources/avatar_placeholder.jpg';

const getAvatarUrl = (owner: Owner, user: User) => {
  if (!owner.id && !user.pmcId) {
    return AVATAR_PLACEHOLDER;
  }

  const avatarHash = md5(`large_${owner.id || user.pmcId}`);
  return `${CDN_HOST + avatarHash}.jpg`;
};

export default getAvatarUrl;
