import { useMemo } from 'react';
import { CommonContexts, INotification, useUuiContext } from '@epam/uui-core';

import ActionAlert, { NotificationAction } from '../../../../components/Notifications/ActionAlert';
import { ValidationFileResult } from './validateFiles';

import { WarningMessage } from '../../../../components/Notifications/constants';

import styles from '../DeferredFileUploader/deferredFileUploader.module.scss';

type AlertFileUploaderType = {
  AdvertisementMaxAttachments: () => void;
  AdvertisementUnsupportedExtension: () => void;
  AdvertisementMaxFileSize: () => void;
  AdvertisementInvalidExtentionsAndSize: () => void;
  AdvertisementPartiallySupportedSizeAndExtension: (action: () => void) => void;
  AdvertisementPartiallySupportedSize: (action: () => void) => void;
  AdvertisementPartiallySupportedExtension: (action: () => void) => void;
  ValidationFailed: (singl: boolean, validationResult: ValidationFileResult) => void;
  PartiallyValidFailed: (validationResult: ValidationFileResult, action: () => void) => void;
};

const buildGenericActions =
  (text: string, action: () => void) =>
  (props: INotification): NotificationAction[] =>
    [
      {
        name: text,
        action: () => {
          action();
          props.onSuccess && props.onSuccess();
        },
      },
      {
        name: 'Cancel all file uploads',
        action: () => {
          props.onClose && props.onClose();
        },
      },
    ];

const confirmActionAlert = (
  warningMessage: WarningMessage,
  svc: CommonContexts<unknown, unknown>
) => {
  ActionAlert(
    warningMessage,
    (props: INotification) => [{ name: 'Ok', action: () => props.onSuccess && props.onSuccess() }],
    svc,
    styles.actionAlertBody
  );
};

const useAlertFileUploader = () => {
  const svc = useUuiContext();

  const alert = useMemo(() => {
    const alerts: AlertFileUploaderType = {
      AdvertisementMaxAttachments: () => {
        confirmActionAlert(WarningMessage.AdvertisementMaxAttachments, svc);
      },
      AdvertisementInvalidExtentionsAndSize: () => {
        confirmActionAlert(WarningMessage.AdvertisementInvalidExtentionsAndSize, svc);
      },
      AdvertisementUnsupportedExtension: () => {
        confirmActionAlert(WarningMessage.AdvertisementUnsupportedExtension, svc);
      },
      AdvertisementMaxFileSize: () => {
        confirmActionAlert(WarningMessage.AdvertisementMaxFileSize, svc);
      },
      AdvertisementPartiallySupportedSizeAndExtension: (action) => {
        ActionAlert(
          WarningMessage.AdvertisementPartiallySupportedSizeAndExtension,
          buildGenericActions('Upload allowed files', action),
          svc,
          styles.actionAlertBody
        );
      },
      AdvertisementPartiallySupportedSize: (action) => {
        ActionAlert(
          WarningMessage.AdvertisementPartiallySupportedSize,
          buildGenericActions('Upload only supported files', action),
          svc,
          styles.actionAlertBody
        );
      },
      AdvertisementPartiallySupportedExtension: (action) => {
        ActionAlert(
          WarningMessage.AdvertisementPartiallySupportedExtension,
          buildGenericActions('Upload only supported files', action),
          svc,
          styles.actionAlertBody
        );
      },
      ValidationFailed: (singl, validationResult) => {
        if (!singl && !validationResult.validExtension && !validationResult.validSize) {
          alerts.AdvertisementInvalidExtentionsAndSize();
          return;
        }

        if (!validationResult.validExtension) {
          alerts.AdvertisementUnsupportedExtension();
        } else {
          alerts.AdvertisementMaxFileSize();
        }
      },
      PartiallyValidFailed: (validationResult, action) => {
        const invalidExtension = !validationResult.validExtension;

        if (validationResult.partiallyValidExtention && validationResult.partiallyValidSize) {
          alerts.AdvertisementPartiallySupportedSizeAndExtension(action);
          return;
        }

        if (invalidExtension) {
          alerts.AdvertisementPartiallySupportedExtension(action);
        } else {
          alerts.AdvertisementPartiallySupportedSize(action);
        }
      },
    };

    return alerts;
  }, [svc]);

  return alert;
};

export default useAlertFileUploader;
