import { FC, useCallback } from 'react';
import { nanoid } from 'nanoid';
import { DropSpot } from '@epam/promo';
import Thumbnail from '../Thumbnail';
import Photo, { PhotoStatus } from '../utils/Photo';
import validateFiles, { MAX_PHOTO_COUNT } from '../utils/validateFiles';
import useAlertFileUploader from '../utils/useAlertFileUploader';

import styles from './deferredFileUploader.module.scss';

const buildPhoto = (file: File) => Photo.ofFile(`${nanoid()}_${new Date().getTime()}`, file);

export type DeferredFileUploaderProps = {
  photos: Photo[];
  highlight?: string | undefined;
  onHighlightChange: (newValue: string) => void;
  onAddPhotos: (photos: Photo[]) => void;
  onDeletePhoto: (photo: Photo) => void;
  actionInProgress?: boolean;
};

const DeferredFileUploader: FC<React.PropsWithChildren<DeferredFileUploaderProps>> = ({
  photos,
  highlight,
  onHighlightChange,
  onAddPhotos,
  onDeletePhoto,
  actionInProgress = false,
}) => {
  const alert = useAlertFileUploader();

  const handleUploadFiles = useCallback(
    (files: File[]) => {
      const numberOfExistingPhotos = photos.filter(
        (photo) => photo.status !== PhotoStatus.PENDING_REMOVAL
      ).length;
      const numberOfPhotosToUpload = files.length;

      if (numberOfPhotosToUpload + numberOfExistingPhotos > MAX_PHOTO_COUNT) {
        alert.AdvertisementMaxAttachments();
        return;
      }

      const isSingleFile = numberOfPhotosToUpload === 1;
      const validationResult = validateFiles(files);

      if (validationResult.validationFailed) {
        alert.ValidationFailed(isSingleFile, validationResult);
        return;
      }

      if (validationResult.validationPass) {
        onAddPhotos(files.map(buildPhoto));
        return;
      }

      const partialUpload = () => {
        onAddPhotos(validationResult.validFiles.map(buildPhoto));
      };

      alert.PartiallyValidFailed(validationResult, partialUpload);
    },
    [photos, alert, onAddPhotos]
  );

  return (
    <>
      <DropSpot
        cx={styles.fixInfoTextOverflow}
        onUploadFiles={handleUploadFiles}
        accept="image/*"
        infoText="You can upload 10 photos with the max size of 5 MB each"
      />
      <div className={styles.thumbnails}>
        {photos.map((photo) => (
          <Thumbnail
            key={photo.id}
            photo={photo}
            highlighted={photo.id === highlight}
            onDelete={onDeletePhoto}
            onSetHighlighted={onHighlightChange}
            actionInProgress={actionInProgress}
          />
        ))}
      </div>
    </>
  );
};

export default DeferredFileUploader;
