import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { produce } from 'immer';

import { useAxios } from './useAxios';

import { AdvertisementServiceUrl } from '../apiUtils/constants';

import { Category } from '../types';

export const useCategories = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const { axios } = useAxios();

  useEffectOnce(() => {
    axios
      .get<Category[]>(AdvertisementServiceUrl.categories)
      .then(({ data }) => {
        setCategories(
          data.map((category) =>
            produce(category, (draft) => {
              draft.name = draft.name.replace(/_/g, ' ');
            })
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return { categories, isLoading };
};
