import { noop } from 'lodash';

import { ErrorNotification } from '@epam/promo';
import { Text } from '@epam/uui';
import { CommonContexts, INotification } from '@epam/uui-core';

import { ErrorMessage } from './constants';

const ErrorAlert = (message: ErrorMessage, svc: CommonContexts<unknown, unknown>) => {
  svc.uuiNotifications
    .show(
      (props: INotification) => (
        <ErrorNotification {...props}>
          <Text size="36" fontSize="14">
            {message}
          </Text>
        </ErrorNotification>
      ),
      { duration: 8 }
    )
    .then(noop)
    .catch(noop);
};

export default ErrorAlert;
