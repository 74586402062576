const k = 1024;
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes <= 0) {
    return '0 Byte';
  }

  if (bytes === 1) {
    return '1 Byte';
  }

  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default formatBytes;
