import styles from './notifications.module.scss';

export enum ErrorMessage {
  Network = 'EPAM Marketplace services are currently not available. Please return and try again later.',
  Frontend = 'EPAM Marketplace is currently not available. Please return and try again later',
  AdvertisementSave = "We couldn't save your advertisement. Please try again later.",
  AdvertisementStatusChange = 'Unfortunately, your advertisement status has not been updated. Try it again later.',
  AttachmentSaveOnCreate = 'Not all of the attached images could be saved. Please check your advertisement details page and add any missing pictures.',
  AttachmentSaveOnEdit = 'Not all of the attached images could be saved. Please refresh your advertisement edit page and add any missing pictures.',
  TooManyRequests = 'You sent too many requests, please wait a moment.',
}

export enum SuccessMessage {
  AdvertisementCreate = 'Thank you! Your advertisement has been added.',
  AdvertisementEdited = 'Thank you! Your advertisement has been edited.',
  AdvertisementDelete = 'Your advertisement has been deleted successfully!',
  AdvertisementStatusChange = 'Your advertisement status has been updated.',
}

export enum WarningMessage {
  AdvertisementDeleteNonExisting = 'You have tried to delete a non-existing advertisement. It may have already been deleted or otherwise removed from EPAM Marketplace.',
  AdvertisementAccessNonExisting = 'You have tried to display a non-existing advertisement. It may have already been deleted or otherwise removed from EPAM Marketplace.',
  AdvertisementStatusChangeNonExisting = 'You have tried to update the status of a non-existing advertisement. It may have already been deleted or otherwise removed from EPAM Marketplace',
  AdvertisementMaxAttachments = 'The number of attached files is limited to ten. Please decrease so that it would not exceed maximum and advertisement could be created.',
  AdvertisementMaxFileSize = 'The size of the attached files is limited to 5Mb per each. Please decrease it so that it would not exceed the maximum file size.',
  AdvertisementUnsupportedExtension = 'You are trying to upload one or more non-supported file(s). Please upload at least one supported file and an advertisement could be created.',
  AdvertisementPartiallySupportedExtension = 'You are trying to upload at least one non-supported file. What would you want to do?',
  AdvertisementPartiallySupportedSize = 'You are trying to upload at least one file that exceeds 5 Megabytes. What would you want to do?',
  AdvertisementPartiallySupportedSizeAndExtension = 'You are trying to upload at least one file that exceeds 5 Megabytes and its extension is not supported. What would you want to do?',
  AdvertisementInvalidExtentionsAndSize = 'You are trying to upload files that exceeds 5 Megabytes and its extension is not supported',
  AdvertisementStatusChangeDraft = 'Your advertisement is saved as draft.',
  AdvertisementValidationError = 'Your advertisement is missing important information. Please correct the highlighted fields.',
}

export const getDraftToPublishMessage = () => (
  <div>
    Please be informed that without any further status change, your advertisement will be reverted
    to draft in 30 days.
  </div>
);

export const getDraftToPublishHeader = (adTitle: string) => (
  <div
    className={styles.spacer}
  >{`Your advertisement "${adTitle}" will be published. Do you confirm the publication?`}</div>
);

export const getPublishToDraftHeader = (adTitle: string) => (
  <div
    className={styles.spacer}
  >{`Your advertisement "${adTitle}" will be reverted to draft. Do you confirm the reversion?`}</div>
);

export const getPublishToDraftMessage = () => (
  <div>
    Please be informed that without publishing, your advertisement will be deleted in 30 days.
  </div>
);

export const getDeleteConfirmationMessage = (adTitle: string) =>
  `This step is irreversible. Your advertisement "${adTitle}" is to be permanently removed. Do you confirm the deletion?`;

export const getDeleteConfirmationMessageAdmin = (adTitle: string) =>
  `This is an ADMIN action. The advertisement "${adTitle}" is to be permanently removed. Do you confirm the deletion?`;

const getExpiringAdMessage = (summary: string, titles: string[]) => (
  <>
    {summary}
    <ul>
      {titles.map((title, index) => (
        <li key={index}>{title}</li>
      ))}
    </ul>
  </>
);

export const getExpiringAdFirstWarningToDraftTransitionMessage = (titles: string[]) =>
  getExpiringAdMessage(
    'Please be informed that the following advertisement(s) are going to be set to DRAFT in one week:',
    titles
  );

export const getExpiringAdSecondWarningToDraftTransitionMessage = (titles: string[]) =>
  getExpiringAdMessage(
    'Please be informed that the following advertisement(s) are going to be set to DRAFT in one day:',
    titles
  );

export const getExpiringAdFirstWarningToDeleteMessage = (titles: string[]) =>
  getExpiringAdMessage(
    'Please be informed that the following advertisement(s) are going to be deleted in one week:',
    titles
  );

export const getExpiringAdSecondWarningToDeleteMessage = (titles: string[]) =>
  getExpiringAdMessage(
    'Please be informed that the following advertisement(s) are going to be deleted in one day:',
    titles
  );
