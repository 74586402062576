import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { noop } from 'lodash';
import { WarningNotification } from '@epam/promo';
import { useUuiContext, INotification } from '@epam/uui-core';
import { Text } from '@epam/uui';

import { useAxios } from './useAxios';

import requestedResourceIsNotFound from '../utils/responseErrorHelper';
import { AdvertisementServiceUrl } from '../apiUtils/constants';
import { WarningMessage } from '../components/Notifications/constants';

import { Advertisement } from '../types';

const useAdvertisement = (id: string) => {
  const [advertisement, setAdvertisement] = useState<Advertisement>();

  const svc = useUuiContext();
  const history = useHistory();
  const { axios } = useAxios();

  useEffect(() => {
    axios
      .get<Advertisement>(AdvertisementServiceUrl.advertisementDetails(id))
      .then(({ data }) => {
        setAdvertisement(data);
      })
      .catch((error) => {
        if (requestedResourceIsNotFound(error)) {
          svc.uuiNotifications
            .show(
              (props: INotification) => (
                <WarningNotification
                  {...props}
                  actions={[
                    {
                      name: 'Ok',
                      action: () => {
                        history.push('/my-advertisements');
                        props.onSuccess && props.onSuccess();
                      },
                    },
                  ]}
                  onClose={() => {
                    history.goBack();
                    props.onClose && props.onClose();
                  }}
                >
                  <Text size="36" fontSize="14">
                    {WarningMessage.AdvertisementAccessNonExisting}
                  </Text>
                </WarningNotification>
              ),
              { duration: 'forever' }
            )
            .then(noop)
            .catch(noop);
        }
      });
  }, [axios, history, id, svc]);

  return { advertisement, setAdvertisement };
};

export default useAdvertisement;
