/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

export default function dateFormatter(date: string): string {
  const dateObj = new Date(date);

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isYesterday = (date: Date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toDateString() === date.toDateString();
  };

  if (isToday(dateObj)) {
    return 'Today';
  } else if (isYesterday(dateObj)) {
    return 'Yesterday';
  } else {
    return dateObj.toLocaleString('ch-CH', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    });
  }
}

export function dateFormatterForEmailTemplate(date: string): string {
  return new Date(date).toLocaleString('en-UK', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
