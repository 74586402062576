export const MAX_FILE_SIZE = 5 * 1024 * 1024;
export const MAX_PHOTO_COUNT = 10;

const validateFileSize = (file: File): boolean => file.size <= MAX_FILE_SIZE;
const validateFileExtention = (file: File): boolean => file.type.startsWith('image/');
const validateFileInfo = (file: File): boolean =>
  validateFileSize(file) && validateFileExtention(file);

export interface ValidationFileResult {
  validExtension: boolean;
  validSize: boolean;
  validationFailed: boolean;
  validationPass: boolean;
  partiallyValidSize: boolean;
  partiallyValidExtention: boolean;
  validFiles: File[];
}

const validateFiles = (files: File[]): ValidationFileResult => {
  const validFiles = files.filter(validateFileInfo);
  const validExtension = files.every(validateFileExtention);
  const validSize = files.every(validateFileSize);

  return {
    validExtension,
    validSize,
    partiallyValidSize: !validSize && files.some(validateFileSize),
    partiallyValidExtention: !validExtension && files.some(validateFileExtention),
    validationFailed: validFiles.length === 0,
    validationPass: validFiles.length === files.length,
    validFiles,
  };
};

export default validateFiles;
