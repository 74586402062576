import { FC, useMemo } from 'react';

import { Panel } from '@epam/promo';

import getAvatarUrl, {
  AVATAR_PLACEHOLDER,
} from '../../pages/CreateAndEditAdvertisementPage/utils/avatarUtil';

import { ContactType } from '../../utils/businessConstants';

import { Advertisement } from '../../types';
import { User } from '../../auth/useTokens';

import styles from './ContactDetails.module.scss';

type IncludeProps = 'title' | 'country' | 'city' | 'owner';
export type ContactDetailsData = Pick<Advertisement, IncludeProps>;

type ContactDetailsProps = {
  advertisement: ContactDetailsData;
  user: User;
};

const ContactDetails: FC<React.PropsWithChildren<ContactDetailsProps>> = ({
  advertisement,
  user,
}) => {
  const { city, country, owner } = advertisement;
  const emailContact = useMemo(() => {
    const ownerEmail = owner.contacts.find(
      ({ contactType }) => contactType === ContactType.EPAM_EMAIL
    )?.value;
    return ownerEmail ?? user.email;
  }, [owner, user]);

  return (
    <Panel shadow cx={styles.container} background="white">
      <div className={styles.content}>
        <img
          alt="seller-avatar"
          src={getAvatarUrl(advertisement.owner, user)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = AVATAR_PLACEHOLDER;
          }}
          height={140}
          className={styles.image}
        />
        <div className={styles.infoGroup}>
          <div className={styles.infoBlock}>
            <h2>{'Seller'}</h2>
            <div>
              <span className={styles.bold}>{'Name:'}</span>
              <span>{owner.name || user.name}</span>
            </div>
            <div>
              <span className={styles.bold}>{'Email:'}</span>
              <span>{emailContact}</span>
            </div>
          </div>
          <div className={styles.infoBlock}>
            <h2>{'Location'}</h2>
            <div>
              <span className={styles.bold}>{'City:'}</span>
              <span>{city}</span>
            </div>
            <div>
              <span className={styles.bold}>{'Country:'}</span>
              <span>{country}</span>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default ContactDetails;
