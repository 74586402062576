import { RichTextView } from '@epam/loveship';
import { FC } from 'react';
import css from './TermsAndConditions.module.scss';

const Heading: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <span className={css.inlineHeading}>{children}</span>
);
const TermsAndConditions: FC<React.PropsWithChildren<unknown>> = () => (
  <RichTextView>
    <h2>Terms and Conditions</h2>
    <p>
      Please read this Member Agreement (the “Agreement”) carefully as it applies to your use of
      online services and digital products of Marketplace, powered by EPAM Systems, Inc.
      (“Marketplace” or “we” or “us”), including but not limited to the Marketplace websites located
      at [<a href="/">http:/marketplace.epam.com</a>] and all subdomains (the “Platform”), and any
      and all mobile application offered by Marketplace and all its features, content and products
      and services (collectively, the “Services”). Your use of the Platform and Services is governed
      by this Agreement regardless of how you access the Platform or Services.
    </p>
    <p>
      PLEASE READ THE TERMS AND CONDITIONS OF THIS AGREEMENT CAREFULLY, AS THEY MAY AFFECT YOUR
      LEGAL RIGHTS, INCLUDING HOW YOU MAY RESOLVE DISPUTES. PLEASE NOTE THAT THIS AGREEMENT MAY
      REQUIRE YOU TO SUBMIT DISPUTES TO ARBITRATION. YOU HAVE A RIGHT TO OPT OUT OF THE BINDING
      ARBITRATION AND CLASS ACTION WAIVER PROVISIONS AS DESCRIBED IN SECTION 23 (GOVERNING LAW AND
      ARBITRATION).
    </p>
    <p>
      <Heading>1. ACCEPTANCE OF TERMS.</Heading> The Platform and Services are made available by
      Marketplace subject to this Agreement. Marketplace reserves the right to update or make
      changes to this Agreement from time to time and may provide you with notice of such changes by
      any reasonable means, including by posting the revised version of this Agreement on the
      Platform. You can determine when this Agreement was last revised by referring to the “LAST
      UPDATED” notice at the end of these terms. Your access to or use of the Platform or Services
      following changes to this Agreement will constitute your acceptance of those changes. Any
      changes to this Agreement shall not apply to any dispute between you and Marketplace arising
      prior to the date on which Marketplace posted the revised version of this Agreement
      incorporating such changes or otherwise notified you of such changes. When using any Services,
      you agree that you are subject to any additional terms and conditions posted by us that are
      applicable to such Services, which are incorporated by reference into this Agreement.
    </p>
    <p>
      BY “CLICK ACCEPTING” THESE TERMS AND CONDITIONS, OR BY ACCESSING OR USING THE PLATFORM OR
      SERVICES (INCLUDING BY COMPLETING A TRANSACTION), YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO
      ENTER INTO THIS AGREEMENT AND THAT YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
      AGREEMENT.
    </p>
    <p>
      <Heading>2. JOINING MARKETPLACE.</Heading>
    </p>
    <p>
      <Heading>a. Eligibility.</Heading> You may use our Services only if you can legally form a
      binding contract with Marketplace, and only in compliance with these terms and all applicable
      laws. You cannot use the Services if (1) you are a child for whom consent is required to fully
      use the Services, (2) we previously disabled your Account for violations of these terms, (3)
      our Services are not directed to your geographic territory, or (4) you are otherwise
      prohibited by applicable laws from accessing or receiving our Services. We reserve the right
      to refuse registration for, access to, or use of our Services by any person or household at
      any time and for any reason.
    </p>
    <p>
      <Heading>b. Becoming a Member.</Heading> When you register to become a Member, you must
      provide us with accurate and complete information. We reserve the right to reject and
      terminate any registrations submitted with false or inaccurate registration information, or
      otherwise submitted in violation of these terms.
    </p>
    <p>
      <Heading>c. Account Types and Access.</Heading> Members may create, operate, maintain, or
      otherwise use an account on the Services (“Account(s)”) for personal use. Each individual is
      limited to one Account. You are responsible for maintaining the security of your Account login
      information and you must keep such login information confidential. You are responsible for all
      activity that takes place under your Account. You should notify us immediately if you suspect
      or become aware of any unauthorized use of or access to your Account. Your username and
      password are for your personal use only and should be kept confidential; you (and not
      Marketplace) are responsible for any use or misuse of your username or password. We are not
      liable for any loss or damage arising from your failure to comply with any of the foregoing
      obligations.
    </p>
    <p>
      <Heading>d. Permitted Activities.</Heading> You may use, access, search, interact with, or
      otherwise make use of our Services only for the purposes for which they are provided and by
      the means we make available (such as our website, apps), and in each case subject to any
      supplemental terms we provide governing their permitted uses.
    </p>
    <p>
      <Heading>3. LICENSES.</Heading> Some of our Services may allow you to download client
      software. Subject to compliance with these terms, Marketplace grants you a worldwide,
      non-exclusive, personal, and non-assignable license to download, install, and run that
      software, solely to access our services. You may not copy, modify, create derivative works
      based upon, distribute, sell, lease, or sublicense any of our software or services. You also
      may not reverse engineer or decompile our software or services, attempt to do so, or assist
      anyone in doing so, unless you have our written consent or applicable law permits it. Although
      Marketplace grants you this license, we retain any intellectual property rights we have in our
      software and services.
    </p>
    <p>
      <Heading>4. OPEN SOURCE.</Heading> Some of Marketplace&apos;s services include software
      subject to separate open source license terms, and your use of those services are subject to
      your compliance with those license terms, when applicable. We encourage you to review them, as
      some licenses may explicitly override these terms.
    </p>
    <p>
      <Heading>5. MODIFICATION OF THE PLATFORM OR SERVICES.</Heading> Marketplace reserves the
      right, at any time and from time to time, temporarily or permanently, in whole or in part, to
      modify or discontinue the Platform or Services, with or without notice; charge fees in
      connection with the use of the Platform or Services; modify or waive any fees charged in
      connection with the Platform or Services; or offer opportunities to some or all users of the
      Platform or Services. If any part of the Platform or the Services is suspended, modified or
      discontinued, or if a technical error occurs, information, data, or content created or
      provided by you in connection therewith may be deleted or become inaccessible, and you should
      not exclusively rely on the Platform or Services to store or preserve such information, data
      or content. You agree that we shall not be liable to you or to any third party for any
      modification, suspension or discontinuance of the Platform or of any Service, content, feature
      or product, in whole or in part. Your continued access to or use of the Platform or Services
      after such changes will indicate your acceptance of such changes.
    </p>
    <p>
      <Heading>6. PLATFORM AND SERVICES INFORMATION.</Heading> We provide Members of the Platform
      and/or Services with access to certain content and services related to education, which may
      include, without limitation (a) seminar registration, (b) live streaming media (e.g., webcasts
      and groupcasts) and on-demand streaming media, (c) downloadable audio and video courses, (d)
      simulated testing software, (e) downloadable presentation materials, (f) downloadable
      publications, (g) blogs, and (h) transcripts. The quality (e.g., the resolution) of streaming
      content, as well as the download speed of downloadable content, may be affected by a variety
      of factors such as your location, the content being streamed or downloaded and the speed of
      your Internet connection. Marketplace makes no representation or warranty regarding access to
      content available through the Platform or in connection with Services, including the quality
      of streaming content and the download speed of downloadable content.
    </p>
    <p>
      <Heading>7. DISCLAIMER.</Heading> Any content provided through the Platform and/or or in
      connection with the Services is designed to provide practical and useful information on the
      subject matter covered. WHILE SUCH CONTENT MAY CONCERN ISSUES IN THE TECHNOLOGY INDUSTRY OR
      OTHER ISSUES RELATED TO IT SERVICES, SUCH CONTENT SHOULD NOT BE RELIED UPON WITHOUT SEEKING
      ADVICE OF A COMPETENT PROFESSIONAL IN THE APPLICABLE SUBJECT MATTER. MARKETPLACE EXPRESSLY
      DISCLAIMS ALL LIABILITY IN RESPECT OF ACTIONS TAKEN OR NOT TAKEN BASED ON ANY CONTENT OF THIS
      PLATFORM OR IN CONNECTION WITH THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE CONTENT IS NOT
      PROVIDED FOR THE PURPOSE OF RENDERING ANY PROFESSIONAL SERVICES OR ADVICE.
    </p>
    <p>
      <Heading>8. JURISDICTIONAL ISSUES.</Heading> The Platform and the Services are controlled and
      operated by Marketplace from the United States, and are not intended to subject Marketplace to
      the laws or jurisdiction of any state, country or territory other than that of the United
      States. Marketplace neither represents nor warrants that the Platform or the Services or any
      part thereof is appropriate or available for use in any particular jurisdiction other than the
      United States. In choosing to access the Platform or Services, you do so on your own
      initiative and at your own risk, and you are responsible for complying with all local laws,
      rules and regulations. You are also subject to United States export controls and are
      responsible for any violations of such controls, including any United States embargoes or
      other federal rules and regulations restricting exports.
    </p>
    <p>
      <Heading>9. INFORMATION SUBMITTED THROUGH THE PLATFORM AND/OR SERVICES.</Heading> Your
      submission of information through the Platform or in connection with the Services is governed
      by Marketplace&apos;s Privacy Policy, which is located at [
      <a href="/">https://marketplace.epam.com</a>] (the “Privacy Policy”). You agree that all
      information that you provide to us is true, accurate and complete, and that you will maintain
      and update such information regularly.
    </p>
    <p>
      <Heading>10. RULES OF CONDUCT.</Heading> You must comply with all applicable laws, rules and
      regulations while accessing or using the Platform and/or Services. In addition, we expect
      users to respect the rights and dignity of others. Your use of the Platform and/or Services is
      conditioned upon your compliance with the rules located at [
      <a href="/">https://marketplace.epam.com</a>].
    </p>
    <p>
      <Heading>11. FORUMS.</Heading> Marketplace and our service providers may make available
      through the Platform or Services certain services (for example, message boards, forums, blogs,
      chat functionality, messaging functionality and comment functionality, among other services)
      to which you are able to post information and materials (each, a “Forum”). Information
      contained in Forums may be provided by employees of Marketplace as well as by third party
      visitors to the Platform or Services. Please note that visitors to the Platform or Services
      may post messages or make statements in the Forums that are inaccurate, misleading or
      deceptive. Marketplace and its directors, officers, employees, representatives, affiliates,
      licensors and service providers (collectively, with Marketplace, the “Marketplace Entities”)
      neither endorse nor are responsible for any opinion, advice, information or statements made in
      the Forums by third parties. Without limitation, the Marketplace Entities are not responsible
      for any information or materials made available through the Forums (including errors or
      omissions in Forum postings or links or images embedded in Forum postings) or results obtained
      by using any such information or materials. Under no circumstances will the Marketplace
      Entities be liable for any loss or damage caused by your reliance on such information or
      materials. The opinions expressed in the Forums reflect solely the opinions of the individuals
      who submitted such opinions, and may not reflect the opinions of Marketplace.
    </p>
    <p>
      In addition, the Marketplace Entities have no control over, and shall have no liability for,
      any damages resulting from the use (including republication) or misuse by any third party
      information voluntarily made public through a Forum or any other part of the Platform or
      Services. IF YOU CHOOSE TO MAKE ANY OF YOUR PERSONALLY IDENTIFIABLE OR OTHER INFORMATION
      PUBLICLY AVAILABLE IN A FORUM OR OTHERWISE ON THE PLATFORM OR SERVICES, YOU DO SO AT YOUR OWN
      RISK.
    </p>
    <p>
      <Heading>12. SUBMISSIONS.</Heading> The Platform or Services may make available certain
      functionality (including Forums and email addresses) through which you are able to post, send
      or make available information and materials (each, a “Submission”). For purposes of clarity,
      you retain any ownership rights that you may have in any of the Submissions that you post,
      subject to the terms and conditions of this Agreement. For each Submission that you make
      available through or in connection with the Platform or Services, you grant to us a
      world-wide, royalty-free, fully paid-up, non-exclusive, perpetual, irrevocable, transferable
      and fully sublicensable (through multiple tiers) license, without additional consideration to
      you or any third party, to reproduce, distribute, perform and display (publicly or otherwise),
      adapt, modify and otherwise use and exploit such Submission, in any format or media now known
      or here after developed, and for any purpose (including promotional purposes, such as
      displaying a user comment on the front page of the Platform or Services as a testimonial), and
      you represent and warrant that you have all necessary rights to grant the foregoing license.
      We may use Submissions for any purpose whatsoever without compensation to you or any other
      person. You are and remain responsible and liable for the content of any Submission.
    </p>
    <p>
      If you participate in a Marketplace program, either as a speaker or a faculty member, you may
      be required to sign a “consent form” agreement with Marketplace regarding your presentation(s)
      and material(s) prior to speaking or presenting at any program.
    </p>
    <p>
      <Heading>13. FEEDBACK.</Heading> Unless we expressly agree otherwise in writing, if you
      provide us with any ideas, proposals, suggestions or materials (including training materials)
      (“Input”), whether related to the Platform, the Services or otherwise, you acknowledge and
      agree that (a) your provision of any Input is gratuitous, unsolicited and without restriction
      and does not place Marketplace under any fiduciary or other obligation, and (b) any Input is
      not confidential and Marketplace has no confidentiality obligations with respect to such
      Input. Without limiting the foregoing, all Input shall be deemed a Submission and licensed to
      us pursuant to Section 12 (Submissions).
    </p>
    <p>
      <Heading>14. MONITORING.</Heading> We may and expressly reserve the right (but have no
      obligation) to monitor, review, store, evaluate, alter or remove any information (including
      any Submissions or any messages, information, content or other materials sent to you, or
      received by you, in connection with the Platform or the Services, or their features and
      functionalities), at any time before or after it appears on the Platform or in connection with
      the Services, and to monitor, review or analyze your access to or use of the Platform or
      Services, in each case by manual, automated or other means, and in each case for any purpose,
      including such purposes as may be described in our Privacy Policy. We may disclose any such
      information (including any Submissions) and the circumstances surrounding its transmission to
      anyone for any reason or purpose.
    </p>
    <p>
      <Heading>15. MARKETPLACE&apos;S PROPRIETARY RIGHTS.</Heading> We and/or our licensors and
      suppliers own the information and materials made available through the Platform and/or
      Services. Such information and materials are protected by copyright, trademark, patent and/or
      other proprietary rights and laws. Except as expressly authorized in advance by us, you agree
      not to reproduce, modify, rent, lease, loan, sell, distribute or create derivative works based
      on, all or any part of the Platform or Services or any information or materials made available
      through the Platform or Services.
    </p>
    <p>
      Trade names, trademarks and service marks on the Platform or Services, are owned or licensed
      by Marketplace, Marketplace&apos;s licensors or other respective owners. Such trade names,
      trademarks and service marks, whether registered or unregistered, may not be used in
      connection with any product or service that is not ours, or in any manner that is likely to
      cause confusion. Nothing contained on the Platform or Services should be construed as
      granting, by implication, estoppel or otherwise, any license or right to use any such trade
      names, trademarks or service marks without the express prior written consent of the owner.
    </p>
    <p>
      <Heading>16. PURCHASES AND RETURNS.</Heading> We may make available products and services for
      purchase through the Platform or Services, and we you may use third-party suppliers and
      service providers to enable payment via e-commerce functionality on our Platform or Services.
      If you wish to purchase any product or service made available by us through the Platform or
      Services (each such purchase, a “Transaction”), you may be asked to supply certain information
      relevant to your Transaction, including without limitation your credit card number, the
      expiration date of your credit card, your billing address, and your shipping information. YOU
      REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) UTILIZED IN
      CONNECTION WITH ANY TRANSACTION. By submitting such information, you grant Marketplace the
      right to provide such information to third parties for purposes of facilitating the completion
      of Transactions initiated by you or on your behalf. Verification of information may be
      required prior to the acknowledgment or completion of any Transaction.
    </p>
    <p>
      Marketplace reserves the right to delete any content at any time in its own
      discretionMarketplace reserves the right and, with or without prior notice, to limit the
      available quantity of or discontinue any product or service made available; to not honor, or
      impose conditions on the honoring of, any coupon, coupon code, promotional code or other
      similar promotions; to bar any user from making any or all Transaction(s); and/or to refuse to
      provide any user with any product or service. Marketplace reserves the right to change its
      pricing for any and all of its products and services offered via the Platform and Services, at
      any time in its sole and absolute discretion without notice. YouAs a buyer in a Transaction
      you agree to pay all charges that may be incurred by you or on your behalf through the
      Platform or Services, at the price(s) in effect when such charges are incurred, including
      without limitation all shipping and handling chargesagreed with the seller. In addition,
      Marketplace you remain will not be responsible for any taxes that may be applicable to your
      Transactions.
    </p>
    <p>
      Marketplace provides the Platform for exchanging information between buyers and sellers of
      products and service. Marketplace does not represent either the buyer or the seller in any
      transactions. Marketplace does not control and is not liable to or responsible for the
      quality, safety, lawfulness or availability of the products or services offered on this
      channel or the ability of the sellers to complete a sale or the ability of buyers to complete
      a purchase. Marketplace makes the Platform available only for connecting sellers and buyers
      and is not involved in the purchase and payment transactions and hereby disclaims all
      liability related thereto.
    </p>
    <p>
      <Heading>17. ACCURACY OF INFORMATION; PRODUCTS, CONTENT AND SPECIFICATIONS.</Heading>{' '}
      Marketplace attempts to ensure that information provided on or in connection with the Platform
      or Services is complete, accurate and current. Despite our efforts, such information may
      occasionally be inaccurate, incomplete or out of date. Accordingly, we make no representation
      as to the completeness, accuracy or currency of such information, including all descriptions,
      images, references, features, content, specifications, products and prices of products and
      services described or depicted on the Platform or Services. Such information is also subject
      to change at any time without notice. Descriptions and images of, and references to,
      third-party products or services available in connection with the Platform or Services do not
      imply Marketplace&apos;s endorsement of such third-party products or services. Certain
      weights, measures and similar descriptions are approximate and are provided for convenience
      purposes only. The inclusion of any products or services on the Platform or Services at a
      particular time does not imply or warrant that these products or services will be available at
      any time. It is your responsibility to ascertain and obey all applicable local, state, federal
      and international laws (including minimum age requirements) in regard to the possession, use
      and sale of any item purchased from the Platform or in connection with the Services. By
      placing an order, you represent that the products ordered will be used only in a lawful
      manner.
    </p>
    <p>
      <Heading>18. THIRD PARTY CONTENT; LINKS.</Heading> The Platform or Services may incorporate
      certain functionality that allows the routing and transmission of, and online access to,
      certain digital communications and content made available by third parties, including social
      media providers (such communications and content, “Third Party Content”). By using such
      functionality, you acknowledge and agree that you are directing us to access and transmit to
      you Third Party Content associated with such functionality. The Platform or Services may
      provide links to other web sites and online resources that include Third Party Content.
      Because we do not control Third Party Content, you agree that the Marketplace Entities are
      neither responsible nor liable for any Third Party Content, including the accuracy, integrity,
      quality, legality, usefulness, or safety of, or intellectual property rights relating to,
      Third Party Content. We have no obligation to monitor Third Party Content, and we may block or
      disable access to any Third Party Content (in whole or part) via the Platform or Services at
      any time. Your access to or receipt of Third Party Content via the Platform or Services does
      not imply our endorsement of, or our affiliation with any provider of, such Third Party
      Content. Further, your use of Third Party Content may be governed by additional terms and
      conditions that are not set forth in this Agreement or our Privacy Policy (for example, terms
      and conditions that are made available by the providers of such Third Party Content). This
      Agreement does not create any legal relationship between you and the providers of such Third
      Party Content with respect to such Third Party Content, and nothing in this Agreement shall be
      deemed to be a representation or warranty by any of the Marketplace Entities with respect to
      any Third Party Content. YOU AGREE THAT YOUR USE OF THIRD PARTY CONTENT IS AT YOUR OWN RISK
      AND IS SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH THIRD PARTY CONTENT.
    </p>
    <p>
      Marketplace shall have the right, at any time, to block links to the Platform or Services
      through technological or other means without prior notice.
    </p>
    <p>
      <Heading>19. DISCLAIMER OF WARRANTIES.</Heading> THE PLATFORM, SERVICES AND ANY GOODS MADE
      AVAILABLE THROUGH OR IN CONNECTION WITH THE PLATFORM OR SERVICES ARE PROVIDED TO YOU “AS IS”
      WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY.
      YOU AGREE THAT YOU MUST EVALUATE, AND THAT YOU BEAR ALL RISKS ASSOCIATED WITH, ACCESS TO AND
      USE OF THE PLATFORM OR SERVICES, INCLUDING WITHOUT LIMITATION, ANY RELIANCE ON THE ACCURACY,
      COMPLETENESS OR USEFULNESS OF ANY MATERIALS AVAILABLE THROUGH THE PLATFORM OR SERVICES. THE
      MARKETPLACE ENTITIES DISCLAIM ALL WARRANTIES WITH RESPECT TO THE PLATFORM. WE ARE NOT LIABLE
      FOR ANY LOSS OR DAMAGE ARISING FROM YOUR FAILURE TO COMPLY WITH ANY OF THESE TERMS AND
      SERVICES AND ANY GOODS OBTAINED OR MADE AVAILABLE VIA WITH THE PLATFORM OR SERVICES (INCLUDING
      WITHOUT LIMITATION, THIRD PARTY PRODUCTS AND SERVICES) TO THE FULLEST EXTENT PERMISSIBLE UNDER
      APPLICABLE LAW, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
      NONINFRINGEMENT AND TITLE.
    </p>
    <p>
      <Heading>20. LIMITATION OF LIABILITY.</Heading> THE MARKETPLACE ENTITIES WILL NOT BE LIABLE
      FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY
      KIND, UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY, INCLUDING WITHOUT
      LIMITATION, DAMAGES FOR LOSS OF PROFITS, USE, DATA, LOSS OF OTHER INTANGIBLES, LOSS OF
      SECURITY OF INFORMATION YOU HAVE PROVIDED IN CONNECTION WITH YOUR USE OF THE PLATFORM OR
      SERVICES, OR UNAUTHORIZED INTERCEPTION OF ANY SUCH INFORMATION BY THIRD PARTIES, EVEN IF
      ADVISED IN ADVANCE OF SUCH DAMAGES OR LOSSES. IN PARTICULAR, AND WITHOUT LIMITATION, THE
      MARKETPLACE ENTITIES WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM YOUR ACCESS TO
      OR USE OF, OR INABILITY TO ACCESS OR USE, THE PLATFORM OR SERVICES, OR FROM ANY CONTENT POSTED
      ON THE PLATFORM OR IN CONNECTION WITH THE SERVICES BY MARKETPLACE OR ANY THIRD PARTY. YOUR
      SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE PLATFORM OR SERVICES IS TO STOP USING
      THE PLATFORM OR SERVICES. THE MAXIMUM LIABILITY OF MARKETPLACE FOR ALL DAMAGES, LOSSES AND
      CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE) OR
      OTHERWISE, SHALL BE THE GREATER OF (I) THE TOTAL AMOUNT, IF ANY, PAID BY YOU TO MARKETPLACE TO
      ACCESS AND USE THE PLATFORM OR SERVICES IN THE TWELVE (12) MONTHS PRECEDING THE FIRST INCIDENT
      RESULTING IN SUCH DAMAGE, LOSS OR CAUSE OF ACTION, AND (II) ONE HUNDRED US DOLLARS ($100.00).
    </p>
    <p>
      While we try to maintain the integrity and security of the Platform and Services and the
      servers from which the Platform and Services are operated, we do not guarantee that the
      Platform or Services will be or remain secure, complete or correct, or that access to the
      Platform or Services will be uninterrupted. The Platform or Services may include inaccuracies,
      errors and materials that violate or conflict with this Agreement. Additionally, third parties
      may make unauthorized alterations to the Platform or Services. If you become aware of any
      unauthorized third party alteration to the Platform or Services, contact us at
      privacy@aw.clubmarketplace.epam.com with a description of the material(s) at issue and the URL
      or location on the Platform or Services where such material(s) appear.
    </p>
    <p>
      <Heading>21. INDEMNITY.</Heading> Except to the extent prohibited under applicable law, you
      agree to defend, indemnify and hold harmless each of the Marketplace Entities from and against
      all claims, losses, costs and expenses (including attorneys&apos; fees) arising out of (a)
      your use of, or activities in connection with, the Platform or Services, (b) any violation or
      alleged violation of this Agreement by you, or (c) any claim that any of your Submissions or
      any use or exploitation thereof caused damage to or infringed upon or violated the rights of a
      third party, including past, present or future infringement, misappropriation, libel,
      defamation, invasion of privacy or right of publicity, or violation of any right related to
      the foregoing.
    </p>
    <p>
      <Heading>22. TERMINATION.</Heading> This Agreement is effective until terminated. Marketplace
      may terminate your access to or use of the Platform or Services, at any time and for any
      reason, including if Marketplace believes that you have violated or acted inconsistently with
      the letter or spirit of this Agreement. Upon any such termination, your right to access or use
      the Platform or Services will immediately cease. You agree that any termination of your access
      to or use of the Platform or Services may occur without prior notice, and that Marketplace may
      immediately deactivate or delete your password and username, and all related information and
      files associated with it, and/or bar any further access to such information or files. You
      agree that Marketplace shall not be liable to you or any third party for any termination of
      your access to the Platform or Services or to any such information or files, and shall not be
      required to make such information or files available to you after any such termination.
    </p>
    <p>
      <Heading>23. GOVERNING LAW AND ARBITRATION.</Heading> This Agreement is governed by the laws
      of the State of New York, USA, without regard to its conflicts of law provisions, and
      regardless of your location. ALL DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY
      ASPECT OF THE RELATIONSHIP BETWEEN YOU AND MARKETPLACE (INCLUDING, WITHOUT LIMITATION,
      MARKETPLACE&apos;S COLLECTION, STORAGE, USE AND DISCLOSURE OF INFORMATION PURSUANT TO
      MARKETPLACE&apos;S PRIVACY POLICY), WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD,
      MISREPRESENTATION OR ANY OTHER LEGAL THEORY, SHALL BE RESOLVED THROUGH FINAL AND BINDING
      ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY AND, BY YOUR
      ACCESS TO OR USE OF THIS PLATFORM OR SERVICES, YOU AGREE THAT YOU AND MARKETPLACE ARE EACH
      WAIVING OUR RIGHT TO TRIAL BY A JURY. BY YOUR ACCESS TO OR USE OF THE PLATFORM OR SERVICES,
      YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS;
      CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND, BY YOUR ACCESS TO OR USE OF THE
      PLATFORM OR SERVICES, YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS
      ACTION. The arbitration will be administered by the American Arbitration Association under its
      Commercial Arbitration Rules, as amended by this Agreement. Any in-person hearings or
      appearances shall be held in New York County, State of New York, USA. Arbitration proceedings
      shall be conducted in a manner that preserves confidentiality. The arbitrator&apos;s decision
      shall follow this Agreement and shall be final and binding. The arbitrator shall have
      authority to award temporary, interim or permanent injunctive relief or relief providing for
      specific performance of this Agreement, but only to the extent necessary to provide relief
      warranted by the individual claim before the arbitrator. The award rendered by the arbitrator
      may be confirmed and enforced in any court having jurisdiction thereof.
    </p>
    <p>
      <Heading>24. INFORMATION OR COMPLAINTS.</Heading> If you have a question or complaint
      regarding the Platform or Services, please contact us by writing to Marketplace Director, 41
      University Drive, Suite 202, Newtown, PA 18940, or by emailing us at
      support@aw.clubmarketplace.epam.com. Please note that email communications will not
      necessarily be secure; accordingly, you should not include payment information (such as credit
      card information) or other sensitive information in your email correspondence with us.
      California residents may reach the Complaint Assistance Unit of the Division of Consumer
      Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd.,
      Sacramento, CA 95834, or by telephone at 916.445.1254 or 800.952.5210.
    </p>
    <p>
      <Heading>25. EXPORT CONTROL.</Heading> You agree to comply with all applicable import, export,
      and re-export control laws and restrictions, including but not limited to those of the
      European Union and its member states, the U.S. Department of Commerce Export Administration
      Regulations (“EAR”) and economic sanctions maintained by the U.S. Office of Foreign Assets
      Control (“OFAC”), and the International Traffic in Arms Regulations (“ITAR”), and will not use
      the services to cause a violation of such laws or regulations. Further, you represent and
      warrant that you are not located in North Korea, on any government list of prohibited or
      restricted parties, or otherwise subject to equivalent restrictions, as specified in the laws
      and regulations listed above or in your country&apos;s laws. You may not download or use our
      services if you are located in a country or region subject to U.S. or E.U. government embargo
      (including Cuba, Iran, Syria, and the Crimea region) unless that use is authorized by the
      United States and other relevant authorities.
    </p>
    <p>
      <Heading>26. MISCELLANEOUS.</Heading> This Agreement does not, and shall not be construed to,
      create any partnership, joint venture, employer-employee, agency or franchisor-franchisee
      relationship between you and Marketplace. If any provision of this Agreement is found to be
      unlawful, void or for any reason unenforceable, that provision will be deemed severable from
      this Agreement and will not affect the validity and enforceability of any remaining provision.
      You may not assign, transfer or sublicense any or all of your rights or obligations under this
      Agreement without our express prior written consent, and any prohibited assignment, transfer
      or sublicense is void. We may assign, transfer or sublicense any or all of our rights or
      obligations under this Agreement without restriction. No waiver by either party of any breach
      or default hereunder will be deemed to be a waiver of any preceding or subsequent breach or
      default. Any heading, caption or section title contained herein is inserted only as a matter
      of convenience, and in no way defines or explains any section or provision hereof. This,
      together with all policies referred to herein, is the entire Agreement between you and
      Marketplace relating to the subject matter herein and supersedes any prior or contemporaneous
      written or oral agreements or understandings between you and Marketplace relating to such
      subject matter. Notices to you may be made via posting to the Platform or through the
      Services, by email, or by regular mail, in Marketplace&apos;s discretion. The Platform or
      Services may also provide notices of changes to this Agreement or other matters by displaying
      such notices or by providing links to such notices. Marketplace will not be responsible for
      failures to fulfill any obligations due to causes beyond its control.
    </p>
    <h2>MARKETPLACE EVENT TERMS</h2>
    <p>
      Events may be organized by Marketplace and you acknowledge and agree that participation in the
      event is at your own free will and risk. Marketplace excludes any liability that may arise in
      respect of the Event to the fullest extent permissible under law. Events may be recorded by
      audio, video or photography and you consent to such recordings by participating in the event.
    </p>
    <h2>MARKETPLACE COPYRIGHT POLICY</h2>
    <p>
      The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright
      owners who believe that material appearing on the Internet infringes their rights under U.S.
      copyright law. If you believe in good faith that materials available on the Platform or in
      connection with the Services infringe your copyright, you (or your agent) may send Marketplace
      a written notice by mail, email or fax, requesting that Marketplace remove the material or
      block access to it. If you believe in good faith that someone has wrongly filed a notice of
      copyright infringement against you, the DMCA permits you to send Marketplace a counter-notice.
      Notices and counter-notices must meet the then-current statutory requirements imposed by the
      DMCA. See http://www.copyright.gov/ for details. Notices and counter-notices must be sent to
      Marketplace&apos;s DMCA Agent as follows: By mail to Marketplace Director, 41 University
      Drive, Suite 202, Newtown, PA 18940; or by email to support@aw.clubmarketplace.epam.com.
      Marketplace suggests that you consult your legal advisor before submitting a notice or
      counter-notice.
    </p>
    <h2>MARKETPLACE VOLUNTEER TERMS</h2>
    <p>
      As a Member of Marketplace you may have the opportunity to participate in volunteer projects
      and programs subject to these terms and any additional terms that may apply to particular
      projects.
    </p>
    <p>
      Details related to volunteer projects are confidential and may contain Marketplace
      confidential information. Marketplace confidential information includes any materials,
      communications, and information that are marked confidential or that would normally be
      considered confidential under the circumstances. If you receive any such information, then you
      will not disclose it to any third party without Marketplace&apos;s prior written consent
      unless required by law with reasonable prior notice to us (unless a court orders that we not
      receive notice). You will not make any statement regarding your participation in a volunteer
      project without Marketplace&apos;s prior written approval.
    </p>
    <p>
      As a Member of Marketplace you also have the opportunity to request assistance from other
      volunteer Members to work on non-profit related projects by submitting an application here.
      Marketplace shall work with you to find suitable volunteers. If required, you may request that
      any volunteers who participate agree to some additional terms.
    </p>
    <h2>MARKETPLACE GUIDELINES</h2>
    <p>
      Marketplace is a platform for industry collaboration, learning and sharing ideas. Marketplace
      encourages users to publish their thoughts, express and promote ideas and obtain feedback.
      Education is at the heart of what Marketplace does and Marketplace is excited to create a
      forum to share and discuss innovative, disruptive or alternative ideas.
    </p>
    <p>
      Marketplace encourages responsible and appropriate use of the Platform and users agree to
      comply with the terms and conditions of these guidelines (“Club GuidelinesMarketplace
      Guidelines”). In order to use the Platform you must read and agree to be bound by the terms of
      this Club GuidelinesMarketplace Guidelines.
    </p>
    <p>
      You must comply with all existing laws, including not only those laws and regulations that are
      specific to computers and networks, but also those that may apply generally to personal
      conduct. Marketplace may, without prior notice or liability to you, remove, suspend, modify,
      block, filter, or restrict by any means the transmission of any material or use of the
      Platform if Marketplace determines that such transmission or use may be unlawful, harmful or
      in violation of this Club GuidelinesMarketplace Guidelines.
    </p>
    <p>The following acts constitute violations of this Club GuidelinesMarketplace Guidelines.</p>
    <p>
      <Heading>• Unlawful use.</Heading> Using the Platform to transmit any material (by email,
      uploading, posting or otherwise) whose transmission violates any law, statute, regulation,
      rule or ordinance including but not limited to those dealing with libel, slander, defamation
      of character, obscenity, and intellectual property. You may not remove, modify or obscure any
      proprietary rights notices that are placed on the Platform.
    </p>
    <p>
      <Heading>• Harm to minors.</Heading> Using the Platform to harm, or attempt to harm, minors in
      any way.
    </p>
    <p>
      <Heading>• Threats.</Heading> Using the Platform to transmit any material (by email,
      uploading, posting or otherwise) that threatens or encourages bodily harm or destruction of
      property.
    </p>
    <p>
      <Heading>• Fraudulent activity.</Heading> Using the Platform to make fraudulent offers to sell
      or buy products, items, or services or to advance any type of financial scam such as “pyramid
      schemes,” “Ponzi schemes,” unregistered sales of securities, or securities fraud.
    </p>
    <p>
      <Heading>• Forgery or impersonation.</Heading> Adding, removing or modifying identifying
      network, message, or article header information in an effort to deceive or mislead; attempting
      to impersonate any person by using forged headers or other identifying information.
    </p>
    <p>
      <Heading>• Unsolicited commercial email/Unsolicited bulk email.</Heading> Using the Platform
      to transmit any unsolicited commercial email or unsolicited bulk email, engaging in any other
      activities that have the effect of facilitating unsolicited commercial email or unsolicited
      bulk email, whether or not that email is commercial in nature, or using deliberately
      misleading headers in emails sent to multiple parties.
    </p>
    <p>
      <Heading>• Unauthorized access.</Heading> Using the Platform to access, or to attempt to
      access without authority, the accounts of others, or to penetrate, or attempt to penetrate,
      security measures of Marketplace or another entity&apos;s computer software or hardware,
      electronic communications system, or telecommunications system, whether the intrusion results
      in disruption of service or the corruption or loss of data.
    </p>
    <p>
      <Heading>• Collection of personal data.</Heading> Using the Platform to collect, or attempt to
      collect, personal information about third parties without their knowledge or consent.
      Spidering, screen scraping, database scraping, harvesting or otherwise attempting automatic or
      manual means of accessing, logging-in to, obtaining data or information from the Platform is
      forbidden.
    </p>
    <p>
      <Heading>• Reselling the Platform.</Heading> Reselling the Platform without Marketplace&apos;s
      written authorization whether on a service bureau, service provider/reseller or time-sharing
      basis or otherwise.
    </p>
    <p>
      <Heading>• Network disruptions and unfriendly activity.</Heading> Using the Platform for any
      activity that adversely affects the ability of other people or systems to use either the
      Platform or other parties&apos; internet-based resources, including without limitation
      excessive consumption of network or system resources (such excessive consumption to be
      determined in Marketplace&apos;s sole discretion), “denial of service” attacks against another
      network host or individual user, or any other interference with or disruption of other network
      users, network Platform or network equipment.
    </p>
    <p>
      You are responsible for all information and data, including any verbal, visual, graphical,
      sound, textual or other content (collectively, “Content”) disclosed, published or used on the
      Platform. You will not publish any Content on the Platform unless by virtue of ownership of
      the Content or possession of a license to use that Content. You grant Marketplace and its
      affiliates and service providers a fully paid up, transferable, sublicensable, right and
      license to store, modify, reformulate and otherwise use all such Content. You agree that you
      shall defend, indemnify and hold harmless Marketplace from and against any claims, damages,
      losses or expenses (including attorney fees and costs) incurred in connection with all claims,
      suits, judgments and settlements for damages arising from your breach of any provision of this
      Club GuidelinesMarketplace Guidelines.
    </p>
    <p>
      You agree that Marketplace may, in its discretion, monitor the usage, bandwidth, transmissions
      and content of the Platform periodically to (a) comply with any necessary laws, regulations or
      other governmental requests, or (b) operate the Platform properly or to protect itself, its
      network and its users. Marketplace reserves the right to update this Club
      GuidelinesMarketplace Guidelines at any time. Your continued use of the Platform after an
      update will be deemed acceptance of the updated terms.
    </p>
    <p>
      If you have any questions about this Club GuidelinesMarketplace Guidelines or wish to report
      any postings or content, please contact{' '}
      <a href="mailto:support@aw.clubmarketplace.epam.com">support@aw.clubmarketplace.epam.com</a>.
    </p>
    <p>LAST UPDATED: FEBRUARY 2023</p>
  </RichTextView>
);

export default TermsAndConditions;
