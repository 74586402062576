import { isNumber } from 'lodash';
import { Metadata } from '@epam/uui-core';
import { MAX_PHOTO_COUNT } from '../components/utils/validateFiles';

import { AdvertisementFormState } from '../types';

export const getAdvertisementFormMetadata = (): Metadata<AdvertisementFormState> => ({
  props: {
    title: {
      isRequired: true,
      validators: [
        (value: string) => [value.length <= 2 && 'Please enter at least three characters'],
        (value: string) => [
          value.trim().length !== value.length &&
            'Title must not have any whitespaces at the beginning or at the end',
        ],
      ],
    },
    description: {
      isRequired: true,
      validators: [
        (description: string) => [
          description.length <= 2 && 'Please enter at least three characters',
        ],
      ],
    },
    country: { isRequired: true },
    city: { isRequired: true },
    category: { isRequired: true },
    price: {
      isRequired: true,
      validators: [
        (value) => [!isNumber(value) && 'Invalid number format'],
        (value) => {
          const fractions = Number(String(value).split('.')[1] || 0);
          return [fractions >= 100 && 'Please add only two decimals for the price'];
        },
      ],
    },
    currency: {
      isRequired: true,
      validators: [
        (currency: string | undefined) => [currency?.length !== 3 && 'The field is mandatory'],
      ],
    },
    attachments: {
      isRequired: false,
      validators: [
        (attachments: string[] | undefined) => [
          attachments
            ? attachments.length > MAX_PHOTO_COUNT &&
              'Number of attached photos is limited to ten, please reduce your images'
            : true,
        ],
      ],
    },
  },
});
