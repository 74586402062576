import { FC } from 'react';

import { Panel, SearchInput } from '@epam/loveship';

import styles from './MainHeader.module.scss';

type MainHeaderProps = {
  value?: string;
  onValueChange: (input: string) => void;
};

const MainHeader: FC<React.PropsWithChildren<MainHeaderProps>> = ({ value, onValueChange }) => (
  <>
    <img src="/resources/hero_image.jpg" className={styles.heroImage} alt="hero image" />
    <Panel cx={styles.searchPanel} background="white">
      <SearchInput
        mode="cell"
        size="48"
        value={value}
        onValueChange={onValueChange}
        placeholder="Search here..."
        debounceDelay={1000}
      />
    </Panel>
  </>
);

export default MainHeader;
