import { useState, useCallback, useContext, FC } from 'react';
import { produce } from 'immer';

import {
  Accordion,
  FlexRow,
  NumericInput,
  PickerInput,
  RangeDatePicker,
  Text,
} from '@epam/loveship';
import { Button, RichTextView } from '@epam/promo';
import { FlexCell } from '@epam/uui-components';
import { RangeDatePickerValue } from '@epam/uui-core';
import { ReferenceDataContext } from '../ReferenceDataProvider';

import datePickerPresets from './datePickerPresets';
import {
  useCountriesDataSoruce,
  useCurrencyDataSource,
} from '../../pages/CreateAndEditAdvertisementPage/hooks/dataSources';

import { PriceRangePickerValue } from '../../types';
import { SearchFilter } from '../../apiHooks/useSearch';

import css from './ResultsFilter.module.scss';

type ResultFilterProps = {
  filters: SearchFilter;
  onDateRangeChange: (date: RangeDatePickerValue) => void;
  onPriceRangeChange: (price: PriceRangePickerValue) => void;
  onCurrencyChange: (currency: string | undefined) => void;
  onCountryChange: (country: string | null, currency: string | undefined | null) => void;
  onClearSearchProperties: () => void;
};

const maxPrice = 9999999;
const font = 'sans-semibold';

const ResultsFilter: FC<React.PropsWithChildren<ResultFilterProps>> = ({
  filters,
  onDateRangeChange,
  onPriceRangeChange,
  onCurrencyChange,
  onCountryChange,
  onClearSearchProperties,
}) => {
  const { countries, currencies } = useContext(ReferenceDataContext);
  const [accordionVisible, setAccordionVisible] = useState<boolean>(true);

  const currencyDataSource = useCurrencyDataSource(currencies);
  const countryDataSource = useCountriesDataSoruce(countries);

  const handlePriceRangeFromChange = useCallback(
    (from: number | null) => {
      onPriceRangeChange(
        produce(filters.price, (draft) => {
          draft.from = from;
        })
      );
    },
    [onPriceRangeChange, filters.price]
  );

  const handlePriceRangeToChange = useCallback(
    (to: number | null) => {
      onPriceRangeChange(
        produce(filters.price, (draft) => {
          draft.to = to;
        })
      );
    },
    [onPriceRangeChange, filters.price]
  );

  const handleCountryChange = useCallback(
    (countryName: string | null) => {
      let currency: string | undefined;
      if (countryName) {
        currency = countries.find(({ name }) => name === countryName)?.currency;
      }

      onCountryChange(countryName, currency);
    },
    [countries, onCountryChange]
  );

  return (
    <>
      <Accordion
        title="Filter"
        mode="block"
        cx={css.filterPanel}
        value={accordionVisible}
        onValueChange={setAccordionVisible}
      >
        <FlexRow>
          <Text font={font}>{'Advertisement Date'}</Text>
        </FlexRow>
        <FlexRow>
          <RangeDatePicker
            value={filters.date}
            onValueChange={onDateRangeChange}
            format="MMM D, YYYY"
            presets={datePickerPresets}
          />
        </FlexRow>
        <FlexRow>
          <Text font={font}>{'Price Range'}</Text>
        </FlexRow>
        <FlexCell width="auto" grow={1}>
          <FlexRow>
            <FlexCell width="100%">
              <NumericInput
                value={filters.price.from}
                onValueChange={handlePriceRangeFromChange}
                min={0}
                max={maxPrice}
              />
            </FlexCell>
            <FlexCell width="auto" minWidth={20} shrink={0}>
              <Text font={font}>{'To'}</Text>
            </FlexCell>
            <FlexCell width="100%">
              <NumericInput
                value={filters.price.to}
                onValueChange={handlePriceRangeToChange}
                min={0}
                max={maxPrice}
              />
            </FlexCell>
          </FlexRow>
        </FlexCell>
        <FlexRow>
          <Text font={font}>{'Country'}</Text>
        </FlexRow>
        <FlexRow>
          <FlexCell width="100%">
            <PickerInput
              dataSource={countryDataSource}
              selectionMode="single"
              valueType="id"
              sorting={{ field: `name`, direction: 'asc' }}
              value={filters.country}
              placeholder="Country"
              onValueChange={handleCountryChange}
            />
          </FlexCell>
        </FlexRow>
        <FlexRow>
          <Text font={font}>{'Currency'}</Text>
        </FlexRow>
        <FlexRow>
          <FlexCell width={120}>
            <PickerInput
              dataSource={currencyDataSource}
              selectionMode="single"
              valueType="id"
              value={filters.currency}
              placeholder="Currency"
              onValueChange={onCurrencyChange}
            />
          </FlexCell>
        </FlexRow>
        <RichTextView cx={css.clearFilter}>
          <Button
            size="24"
            fill="white"
            color="blue"
            caption="Clear Filter"
            onClick={onClearSearchProperties}
          />
        </RichTextView>
      </Accordion>
    </>
  );
};

export default ResultsFilter;
