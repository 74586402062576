import { ReactElement, FC, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { AdvertisementDetails, ContactDetails } from '../../../../components';
import { ArrayDataSource, ILens } from '@epam/uui-core';
import { Text as PromoText } from '@epam/promo';
import {
  FlexCell,
  FlexRow,
  FlexSpacer,
  LabeledInput,
  Text,
  TextInput,
  TextArea,
  NumericInput,
  Panel,
  PickerInput,
} from '@epam/loveship';
import { Page } from '../../../../templates';

import descriptionFormatter from '../../../../utils/descriptionFormatter';
import mapUserToOwner from '../../utils/mapUserToOwner';
import { useAuth } from '../../../../auth/AuthProvider';
import { useCitiesDataSource } from '../../hooks/dataSources';

import { AdvertisementStatus } from '../../../../utils/businessConstants';
import {
  PREVIEW_DEFAULT_DESCRIPTION,
  PREVIEW_DEFAULT_PRICE,
  PREVIEW_DEFAULT_STATUS,
  PREVIEW_DEFAULT_TITLE,
} from '../../constants';

import { AdvertisementDetailsData } from '../../../../components/AdvertisementDetails/types';
import { AdvertisementFormState } from '../../types';
import { Category, Country, Currency } from '../../../../types';
import { ContactDetailsData } from '../../../../components/ContactDetails/ContactDetails';
import Photo from '../utils/Photo';
import { User } from '../../../../auth/useTokens';

import styles from './layout.module.scss';
import Header from './Header';

type BuildAdvertisementPreview = {
  formState: AdvertisementFormState;
  user: User;
};

const buildPreviewAdvertisement = ({
  formState,
  user,
}: BuildAdvertisementPreview): AdvertisementDetailsData & ContactDetailsData => ({
  price: formState.price || PREVIEW_DEFAULT_PRICE,
  currency: formState.currency ?? '',
  description: descriptionFormatter(formState.description) || PREVIEW_DEFAULT_DESCRIPTION,
  title: formState.title || PREVIEW_DEFAULT_TITLE,
  owner: formState.owner || mapUserToOwner(user),
  status: formState.status ?? PREVIEW_DEFAULT_STATUS,
  highlight: formState.highlight,
  country: formState.country ?? '',
  city: formState.city ?? '',
  creationDate: new Date().toLocaleString(),
});

type LayoutProps = {
  formState: AdvertisementFormState;
  lens: ILens<AdvertisementFormState>;
  currencies: ArrayDataSource<Currency, string, unknown>;
  categories: ArrayDataSource<Category, string, unknown>;
  countries: ArrayDataSource<Country, string, unknown>;
  attachments: Photo[];
  attachmentUploadInProgress: boolean;
  disabled: boolean;
  onCountryChange: (newValue: string) => void;
  onSave: (targetStatus: AdvertisementStatus) => () => void;
  renderFileUploader: () => ReactElement;
};

const Layout: FC<React.PropsWithChildren<LayoutProps>> = ({
  formState,
  lens,
  currencies,
  categories,
  countries,
  attachments,
  attachmentUploadInProgress,
  disabled,
  onCountryChange,
  onSave,
  renderFileUploader,
}) => {
  const { user } = useAuth();

  const previewAdvertisement = buildPreviewAdvertisement({ formState, user });

  const countryCities = countries.getById(formState.country ?? '')?.cities ?? [];
  const cityDataSource = useCitiesDataSource(countryCities);

  const shouldDisplayDraftStatus = useMemo(() => {
    const isEditMode = !!formState.id;
    const isDraftStatus = formState.status.id === AdvertisementStatus.DRAFT;
    return isEditMode && isDraftStatus;
  }, [formState.id, formState.status.id]);

  return (
    <Page>
      <div className={styles.container}>
        <Header isNewAdvertisement={!formState.id} onSave={onSave} disabled={disabled} />
        <div className={styles.contentWrapper}>
          <div className={styles.formWrapper} data-testid="advertisement-form">
            <Panel cx={styles.panel} background="white" shadow>
              <FlexCell width="100%">
                <FlexRow vPadding="12">
                  <FlexCell grow={1}>
                    <LabeledInput {...lens.prop('title').toProps()} label="Title">
                      <TextInput
                        {...lens.prop('title').toProps()}
                        placeholder="Title"
                        maxLength={125}
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow vPadding="12" alignItems="top">
                  <FlexCell cx={styles.halfCell}>
                    <LabeledInput {...lens.prop('price').toProps()} label="Price">
                      <NumericInput
                        {...lens.prop('price').toProps()}
                        min={0}
                        max={9999999}
                        placeholder="Price"
                      />
                    </LabeledInput>
                  </FlexCell>
                  <FlexCell cx={styles.halfCell}>
                    <LabeledInput {...lens.prop('currency').toProps()} label="Currency">
                      <PickerInput
                        {...lens.prop('currency').toProps()}
                        dataSource={currencies}
                        sorting={{ field: `name`, direction: 'asc' }}
                        selectionMode="single"
                        valueType="id"
                        placeholder="Currency"
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow vPadding="12">
                  <FlexCell grow={1}>
                    <LabeledInput {...lens.prop('description').toProps()} label="Description">
                      <TextArea
                        {...lens.prop('description').toProps()}
                        placeholder="Description"
                        autoSize
                        rows={4}
                        maxLength={1000}
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow vPadding="12" alignItems="top">
                  <FlexCell grow={1}>
                    <LabeledInput {...lens.prop('category').toProps()} label="Category">
                      <PickerInput
                        {...lens.prop('category').toProps()}
                        dataSource={categories}
                        selectionMode="single"
                        valueType="entity"
                        placeholder="Select a category"
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow vPadding="12" alignItems="center">
                  <FlexCell cx={styles.halfCell}>
                    <LabeledInput {...lens.prop('country').toProps()} label="Country">
                      <PickerInput
                        {...lens.prop('country').toProps()}
                        dataSource={countries}
                        onValueChange={onCountryChange}
                        sorting={{ field: `name`, direction: 'asc' }}
                        selectionMode="single"
                        valueType="id"
                        placeholder="Select a country"
                      />
                    </LabeledInput>
                  </FlexCell>
                  <FlexCell cx={styles.halfCell}>
                    <LabeledInput {...lens.prop('city').toProps()} label="City">
                      <PickerInput
                        {...lens.prop('city').toProps()}
                        dataSource={cityDataSource}
                        sorting={{ field: `name`, direction: 'asc' }}
                        selectionMode="single"
                        valueType="id"
                        placeholder="Select a city"
                        isDisabled={isEmpty(formState.country)}
                      />
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
                <FlexRow vPadding="12" alignItems="top">
                  <FlexCell grow={1}>
                    <LabeledInput {...lens.prop('attachments').toProps()} label="Attachments">
                      {renderFileUploader()}
                    </LabeledInput>
                  </FlexCell>
                </FlexRow>
              </FlexCell>
            </Panel>
          </div>
          <div className={styles.previewWrapper} data-testid="advertisement-preview">
            <FlexRow>
              <h2 className={styles.previewHeader}>{'Preview'}</h2>
              {attachmentUploadInProgress && (
                <>
                  <FlexSpacer />
                  <Text
                    cx={styles.notification}
                    color="night500"
                    font="sans-semibold"
                    fontSize="12"
                    lineHeight="12"
                  >
                    {'Attachment upload in progress...'}
                  </Text>
                </>
              )}
            </FlexRow>
            <Panel cx={styles.panel} background="night50" shadow>
              {shouldDisplayDraftStatus && (
                <PromoText cx={styles.imageOverlay} font="museo-slab">
                  {'DRAFT'}
                </PromoText>
              )}
              <AdvertisementDetails
                advertisement={previewAdvertisement}
                attachments={attachments}
                contactChannelsDisabled
              />
              <ContactDetails advertisement={previewAdvertisement} user={user} />
            </Panel>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Layout;
