import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';

import ContactSellerButton from '../ContactSellerButton/ContactSellerButton';
import { FlexRow, Panel, Button, RichTextView } from '@epam/promo';
import { Tooltip } from '@epam/uui';
import { ReactComponent as TeamsIcon } from '@epam/assets/icons/common/microsoft-teams-24.svg';
import { ReactComponent as TelescopeIcon } from '@epam/assets/icons/common/communication-telescope-24.svg';
import { ReactComponent as Flag } from '@epam/assets/icons/common/notification-error-fill-24.svg';
import { PhotoViewer } from '../../components/AdvertisementDetails/components';
import { PriceTag } from '../../components';

import { useAuth } from '../../auth/AuthProvider';
import usePhotoViewer from './components/usePhotoViewer';

import { ContactType, EPAM_SUPPORT_EMAIL } from '../../utils/businessConstants';

import { AdvertisementDetailsProps } from './types';

import contactButtonStyles from '../../components/ContactSellerButton/ContactSellerButton.module.scss';
import styles from './AdvertisementDetails.module.scss';
import Description from './components/Description';
import AsyncPhoto from '../../pages/CreateAndEditAdvertisementPage/components/AsyncPhoto/AsyncPhoto';
import Photo from '../../pages/CreateAndEditAdvertisementPage/components/utils/Photo';

const IMAGE_PLACEHOLDER = '/resources/image_placeholder.png';

const AdvertisementDetails: FC<React.PropsWithChildren<AdvertisementDetailsProps>> = ({
  advertisement,
  attachments,
  contactChannelsDisabled = false,
}) => {
  const { title, description, price, currency, owner, creationDate } = advertisement;
  const [mainImage, setMainImage] = useState<Photo>();
  const [thumbnails, setThumbnails] = useState<Photo[]>([]);

  const imagesToPreview = useMemo(
    () => attachments.filter((a) => a.isPreviewEligible()),
    [attachments]
  );

  useEffect(() => {
    const handleUnhighlightedImages = (images: Photo[]) => {
      const [firstImage, ...others] = images;
      setMainImage(firstImage);
      setThumbnails(others ?? []);
    };

    const handleHighlightedImages = (highlighted: Photo[], others: Photo[]) => {
      const [theOnlyHighlightedImage] = highlighted;
      setMainImage(theOnlyHighlightedImage);
      setThumbnails(others ?? []);
    };

    const highlight = advertisement.highlight;
    if (highlight) {
      const { highlighted, others } = groupBy(imagesToPreview, (i) =>
        i.id === highlight ? 'highlighted' : 'others'
      );
      if (highlighted) {
        handleHighlightedImages(highlighted, others);
      } else {
        handleUnhighlightedImages(imagesToPreview);
      }
    } else {
      handleUnhighlightedImages(imagesToPreview);
    }
  }, [advertisement.highlight, imagesToPreview]);

  const { user } = useAuth();

  const photosToView = mainImage ? [mainImage, ...thumbnails] : [];
  const photoIds = photosToView.map((photo) => photo.id);

  const {
    isPhotoViewerVisible,
    currentImageIndex,
    setCurrentImageIndex,
    onPhotoViewerToggle,
    onPhotoViewerNext,
    onPhotoViewerPrev,
  } = usePhotoViewer(photosToView);

  const handlePhotoViewerLaunch = useCallback(
    (photoId: string) => () => {
      const attachmentIndex = photoIds.indexOf(photoId);
      setCurrentImageIndex(attachmentIndex);
      onPhotoViewerToggle();
    },
    [onPhotoViewerToggle, photoIds, setCurrentImageIndex]
  );

  const renderMainAdvertisementImage = useCallback(
    () => (
      <>
        {mainImage ? (
          <div className={styles.imageWithOverlayContainer}>
            <AsyncPhoto
              className={styles.mainImage}
              onClick={handlePhotoViewerLaunch(mainImage.id)}
              alt={title}
              photo={mainImage}
            />
          </div>
        ) : (
          <div className={styles.imageWithOverlayContainer}>
            <img className={styles.mainImage} alt={title} src={IMAGE_PLACEHOLDER} />
          </div>
        )}
      </>
    ),
    [handlePhotoViewerLaunch, mainImage, title]
  );

  const renderThumbnails = useCallback(() => {
    if (isEmpty(thumbnails)) {
      return null;
    } else {
      return (
        <div className={styles.thumbnailsContainer}>
          {thumbnails.map((thumbnail) => (
            <div className={styles.thumbnail} key={thumbnail.id}>
              <AsyncPhoto
                className={styles.thumbnailImage}
                onClick={handlePhotoViewerLaunch(thumbnail.id)}
                alt={title}
                photo={thumbnail}
              />
            </div>
          ))}
        </div>
      );
    }
  }, [handlePhotoViewerLaunch, thumbnails, title]);

  const contactEmail = useMemo(
    () => owner.contacts?.find(({ contactType }) => contactType === ContactType.EPAM_EMAIL)?.value,
    [owner.contacts]
  );

  const commonName = useMemo(() => {
    if (isEmpty(contactEmail)) {
      return undefined;
    } else {
      // TODO: EPMCHBE-606 use telescope API for owner user information
      const [name] = contactEmail!.split('@');
      return name;
    }
  }, [contactEmail]);

  const renderFlagLink = useCallback(() => {
    const to = EPAM_SUPPORT_EMAIL;
    const subject = `Report on Inappropriate Advertisement - [${advertisement.title}]`;
    const body = `Dear Support,
%0D%0A%0D%0A
I found the following Marketplace advertisement inappropriate due to the following reasons:%0D%0A%0D%0A
Advertisement: ${window.location.href}%0D%0A%0D%0A
<enter your reasons>%0D%0A%0D%0A
Thank you in advance for your attention to this issue.%0D%0A%0D%0A
Sincerely,%0D%0A%0D%0A
${user.name}`;

    return `${to}?subject=${subject}&body=${body}`;
  }, [advertisement.title, user.name]);

  const renderAdvertisementInfoPanel = useCallback(
    () => (
      <Panel cx={styles.advertisementContainer} background="white" shadow>
        <FlexRow>
          <RichTextView cx={styles.title}>{title}</RichTextView>
        </FlexRow>
        <FlexRow>
          <RichTextView size="16">
            <Description content={description} />
          </RichTextView>
        </FlexRow>
        <FlexRow cx={styles.priceRow}>
          <PriceTag price={price} currency={currency} />
          <div className={styles.contactButtonsRow}>
            <div className={styles.contactButtonsContainer}>
              <ContactSellerButton
                contactName={owner.name}
                contactEmail={contactEmail}
                buyerName={user.name}
                advertisementDate={creationDate}
                advertisementTitle={title}
                disabled={contactChannelsDisabled}
              />
              <Button
                cx={contactButtonStyles.contactButtonTelescope}
                href={`https://telescope.epam.com/who/${commonName}?tab=profile`}
                target="_blank"
                icon={TelescopeIcon}
                isDisabled={contactChannelsDisabled || isEmpty(commonName)}
              />
              <Button
                cx={contactButtonStyles.contactButtonTeams}
                href={`https://teams.microsoft.com/l/chat/0/0?users=${contactEmail}`}
                target="_blank"
                icon={TeamsIcon}
                isDisabled={contactChannelsDisabled || isEmpty(contactEmail)}
              />
            </div>
            <Tooltip content="Report this ad" placement="top">
              <Button
                icon={Flag}
                href={`mailto:${renderFlagLink()}`}
                cx={contactButtonStyles.contactButton}
              />
            </Tooltip>
          </div>
        </FlexRow>
      </Panel>
    ),
    [
      commonName,
      contactChannelsDisabled,
      contactEmail,
      creationDate,
      currency,
      description,
      owner.name,
      price,
      renderFlagLink,
      title,
      user.name,
    ]
  );

  return (
    <div className={styles.container}>
      <Panel cx={styles.imageContainer} background="white" shadow>
        {renderMainAdvertisementImage()}
        {renderThumbnails()}
        <PhotoViewer
          photosToView={photosToView}
          photoViewerState={{
            isPhotoViewerVisible,
            currentImageIndex,
            setCurrentImageIndex,
            onPhotoViewerToggle,
            onPhotoViewerNext,
            onPhotoViewerPrev,
          }}
          alt={title}
        />
      </Panel>
      {renderAdvertisementInfoPanel()}
    </div>
  );
};

export default AdvertisementDetails;
