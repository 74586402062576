export enum AdvertisementStatus {
  PUBLISH = 'PUBLISH',
  DRAFT = 'DRAFT',
}

export enum ContactType {
  EPAM_EMAIL = 'EPAM_EMAIL',
}

export const EPAM_SUPPORT_EMAIL = 'supportmarketplace@epam.com';
