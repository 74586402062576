import { FC, useCallback } from 'react';
import { Button, Dropdown, DropdownMenuButton, Panel } from '@epam/loveship';
import { ReactComponent as FilterIcon } from '@epam/assets/icons/common/content-filter_list-24.svg';
import { ReactComponent as SelectedIcon } from '@epam/assets/icons/common/notification-done-12.svg';

import { ResultSortingTypes } from './contants';

import { IDropdownToggler, DropdownBodyProps } from '@epam/uui-core';

type SortMenuProps = {
  sortType: string;
  onSortTypeChange: (sortTypeValue: string) => void;
  isDisabled: boolean;
};

const SortMenu: FC<React.PropsWithChildren<SortMenuProps>> = ({
  sortType,
  onSortTypeChange,
  isDisabled,
}) => {
  const renderBody = useCallback(
    ({ onClose }: DropdownBodyProps) => (
      <Panel background="white" shadow={true}>
        {Object.keys(ResultSortingTypes).map((key) => {
          const value = ResultSortingTypes[key as keyof typeof ResultSortingTypes];
          return (
            <DropdownMenuButton
              isDisabled={isDisabled}
              key={key}
              caption={value}
              icon={sortType === value ? SelectedIcon : undefined}
              onClick={() => {
                onClose && onClose();
                onSortTypeChange(key);
              }}
            />
          );
        })}
      </Panel>
    ),
    [isDisabled, onSortTypeChange, sortType]
  );

  return (
    <Dropdown
      renderTarget={(props: IDropdownToggler) => (
        <Button icon={FilterIcon} color="white" iconPosition="right" {...props} />
      )}
      renderBody={renderBody}
      placement="bottom-end"
    />
  );
};

export default SortMenu;
