import { FC, ReactNode } from 'react';

import AppHeader, { StaticAppHeader } from '../../AppHeader';
import AppFooter, { StaticAppFooter } from '../../components/AppFooter';

import styles from './Page.module.scss';
import { useTermsAndConditionsModal } from '../../components/TermsAndConditions';

type LayoutProps = {
  header: ReactNode;
  footer: ReactNode;
};

const Layout: FC<React.PropsWithChildren<LayoutProps>> = ({ header, footer, children }) => (
  <div className={styles.app}>
    {header}
    <main className={styles.main}>{children}</main>
    {footer}
  </div>
);

const StaticPage: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Layout header={<StaticAppHeader />} footer={<StaticAppFooter />}>
    {children}
  </Layout>
);

const Page: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  useTermsAndConditionsModal();
  return (
    <Layout header={<AppHeader />} footer={<AppFooter />}>
      {children}
    </Layout>
  );
};

export { StaticPage };

export default Page;
