import dayjs from 'dayjs';
import { rangeDatePickerPresets } from '@epam/uui';

const datePickerPresets = {
  ...rangeDatePickerPresets,
  yesterday: {
    name: 'Yesterday',
    getRange: () => {
      return {
        from: dayjs().subtract(1, 'day').toString(),
        to: dayjs().subtract(1, 'day').toString(),
        order: 1,
      };
    },
  },
  last3Days: {
    name: 'Last 3 days',
    getRange: () => {
      return {
        from: dayjs().subtract(3, 'day').toString(),
        to: dayjs().toString(),
        order: 1,
      };
    },
  },
  last7Days: {
    name: 'Last 7 days',
    getRange: () => {
      return {
        from: dayjs().subtract(7, 'day').toString(),
        to: dayjs().toString(),
        order: 1,
      };
    },
  },
};

export default datePickerPresets;
