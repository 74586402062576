import { FC, useCallback, useState } from 'react';
import { IModal } from '@epam/uui-core';
import { Button, Checkbox, ModalFooter, ModalHeader, Panel, ScrollBars } from '@epam/promo';
import { FlexRow } from '@epam/uui';
import { FlexSpacer } from '@epam/uui-components';

import BlockingModal from '../Modals/BlockingModal';

import TermsAndConditions from './TermsAndConditions';

const TermsAndConditionsModal: FC<React.PropsWithChildren<IModal<boolean>>> = (
  modalProps: IModal<boolean>
) => {
  const [consentCheckboxIsChecked, setConsentCheckbox] = useState<boolean>(false);
  const handleConfirmConsent = useCallback(() => modalProps.success(true), [modalProps]);

  return (
    <BlockingModal {...modalProps} modalWindowWidth="900">
      <Panel background="white">
        <ModalHeader title="MARKETPLACE MEMBER AGREEMENT" />
        <ScrollBars hasTopShadow hasBottomShadow>
          <FlexRow padding="24">
            <TermsAndConditions />
          </FlexRow>
        </ScrollBars>
        <ModalFooter>
          <Checkbox
            label="I read, understood and agree to be bound by these terms and conditions."
            value={consentCheckboxIsChecked}
            onValueChange={setConsentCheckbox}
          />
          <FlexSpacer />
          <Button
            color="green"
            caption="Accept"
            onClick={handleConfirmConsent}
            isDisabled={!consentCheckboxIsChecked}
          />
        </ModalFooter>
      </Panel>
    </BlockingModal>
  );
};

export default TermsAndConditionsModal;
