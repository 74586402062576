import { FC, useCallback } from 'react';
import classNames from 'classnames';

import { ReactComponent as ErrorIcon } from '@epam/assets/icons/common/notification-error-fill-12.svg';
import { ReactComponent as OkIcon } from '@epam/assets/icons/common/notification-check-fill-12.svg';
import { ReactComponent as WarningIcon } from '@epam/assets/icons/common/notification-warning-fill-12.svg';
import { ProgressBar, IconButton, Text } from '@epam/promo';

import { PhotoStatus } from '../utils/Photo';

import styles from './uploadStatus.module.scss';

export type UploadStatusProps = {
  error: unknown | null;
  progress: number;
  status: PhotoStatus;
};

const UploadStatus: FC<React.PropsWithChildren<UploadStatusProps>> = ({
  error,
  progress,
  status,
}) => {
  const renderContent = useCallback(() => {
    if (error) {
      return (
        <>
          <IconButton icon={ErrorIcon} color="red" cx={styles.icon} />
          <Text font="sans-semibold" fontSize="12">
            {'Error during file operation'}
          </Text>
        </>
      );
    }

    if (status === PhotoStatus.PENDING_REMOVAL) {
      return (
        <>
          <IconButton
            icon={WarningIcon}
            color="amber"
            cx={classNames(styles.icon, styles.warningIcon)}
          />
          <Text font="sans-semibold" fontSize="12">
            {'Please save your advertisement to remove this image'}
          </Text>
        </>
      );
    }

    if (progress === 0) {
      return (
        <>
          <IconButton
            icon={WarningIcon}
            color="amber"
            cx={classNames(styles.icon, styles.warningIcon)}
          />
          <Text font="sans-semibold" fontSize="12">
            {'Please save your advertisement to upload this image'}
          </Text>
        </>
      );
    }

    if (progress === 100) {
      return (
        <>
          <IconButton icon={OkIcon} color="green" cx={styles.icon} />
          <Text font="sans-semibold" fontSize="12">
            {'Image uploaded'}
          </Text>
        </>
      );
    }

    return <ProgressBar striped progress={progress} size="24" cx={styles.progressBar} />;
  }, [error, progress, status]);

  if (status === PhotoStatus.UPLOADED) {
    return null;
  }

  return (
    <div className={styles.notificationLine}>
      <div className={styles.iconContainer}>{renderContent()}</div>
    </div>
  );
};

export default UploadStatus;
