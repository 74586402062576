import { FC, useEffect } from 'react';
import { Page } from '../../templates';
import style from './LogoutPage.module.scss';
import { Text } from '@epam/promo';
import { Link } from 'react-router-dom';
import logo from '../../icons/logo.svg';
import { useAxios } from '../../apiHooks/useAxios';
import { AdvertisementServiceUrl } from '../../apiUtils/constants';
import useTokens from '../../auth/useTokens';

const LogoutPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { axios } = useAxios();
  const { resetTokens } = useTokens();

  useEffect(() => {
    axios.get(AdvertisementServiceUrl.logout).finally(() => resetTokens());
  });

  return (
    <Page>
      <img src="/resources/hero_image.jpg" className={style.heroImage} alt="hero image" />
      <div className={style.container}>
        <Text fontSize="24" cx={style.text}>
          {'You have logged out of Marketplace'}
        </Text>
        <div className={style.loginButton}>
          <Link to="/">
            <div className={style.icon}>
              <img src={logo} alt="Home" />
            </div>
            <Text fontSize="18">{'Login with EPAM SSO'}</Text>
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default LogoutPage;
