import { FC, PropsWithChildren, createContext, useMemo } from 'react';

import { Spinner } from '@epam/promo';

import { useCategories } from '../../apiHooks/useCategories';
import { useLocations } from '../../apiHooks/useLocations';

import { Category, Country, Currency, Location } from '../../types';

type ReferenceDataContextState = {
  categories: Category[];
  locations: Location[];
  currencies: Currency[];
  countries: Country[];
};

export const ReferenceDataContext = createContext<ReferenceDataContextState>({
  categories: [],
  locations: [],
  currencies: [],
  countries: [],
});

const ReferenceDataProvider: FC<React.PropsWithChildren<PropsWithChildren<unknown>>> = ({
  children,
}) => {
  const { isLoading: areCategoriesLoading, categories } = useCategories();
  const { isLoading: areLocationsLoading, locations, currencies, countries } = useLocations();

  const contextValue = useMemo(
    () => ({
      categories,
      locations,
      currencies,
      countries,
    }),
    [categories, countries, currencies, locations]
  );

  if (areCategoriesLoading || areLocationsLoading) {
    return <Spinner rawProps={{ role: 'progressbar' }} />;
  }

  return (
    <ReferenceDataContext.Provider value={contextValue}>{children}</ReferenceDataContext.Provider>
  );
};

export default ReferenceDataProvider;
