import { History } from 'history';

export const ADVERTISEMENT_SERVICE_PREFIX = '/api';
export const USER_DETAILS_SERVICE_PREFIX = '/api/user-details';
export const ADMIN_SERVICE_PREFIX = '/api/admin';

export const AdvertisementServiceUrl = {
  categories: `${ADVERTISEMENT_SERVICE_PREFIX}/categories`,
  searchAdvertisements: `${ADVERTISEMENT_SERVICE_PREFIX}/search/advertisements`,
  advertisementList: `${ADVERTISEMENT_SERVICE_PREFIX}/advertisements`,
  advertisementDetails: (advertisementId: string) =>
    `${ADVERTISEMENT_SERVICE_PREFIX}/advertisements/${advertisementId}`,
  advertisementStatus: (advertisementId: string) =>
    `${AdvertisementServiceUrl.advertisementDetails(advertisementId)}/status`,
  attachmentDetail: (attachmentId: string) =>
    `${ADVERTISEMENT_SERVICE_PREFIX}/attachments/${attachmentId}`,
  uploadAttachment: `${ADVERTISEMENT_SERVICE_PREFIX}/attachment/upload`,
  deleteAttachments: `${ADVERTISEMENT_SERVICE_PREFIX}/attachments/delete`,
  logout: `${ADVERTISEMENT_SERVICE_PREFIX}/logout`,
} as const;

export const UserDetailsServiceUrl = {
  userDetails: `${USER_DETAILS_SERVICE_PREFIX}`,
  updateTerms: `${USER_DETAILS_SERVICE_PREFIX}/terms-and-conditions`,
} as const;

export const AdminServiceUrl = {
  deleteAdvertisement: (advertisementId: string) => `${ADMIN_SERVICE_PREFIX}/${advertisementId}`,
} as const;

export const wasBackButtonClicked = (history: History) => history.action === 'POP';
