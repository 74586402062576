import { FlexRow, Panel, Text } from '@epam/loveship';
import css from './EmptyStateContent.module.scss';

const EmptyStateContent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Panel background="white" cx={css.container}>
      <FlexRow>
        <Text font="sans-semibold" size="48">
          {children}
        </Text>
      </FlexRow>
    </Panel>
  );
};

export default EmptyStateContent;
