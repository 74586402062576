import { FlexRow, FlexSpacer } from '@epam/loveship';
import { FC, useCallback } from 'react';
import { Button } from '@epam/promo';
import buttonStyles from '../../../../templates/buttonStyles.module.scss';
import { useHistory } from 'react-router-dom';
import { AdvertisementStatus } from '../../../../utils/businessConstants';

type HeaderProps = {
  isNewAdvertisement: boolean;
  onSave: (targetStatus: AdvertisementStatus) => () => void;
  disabled: boolean;
};
const Header: FC<React.PropsWithChildren<HeaderProps>> = ({
  isNewAdvertisement,
  onSave,
  disabled,
}) => {
  const history = useHistory();
  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <h2>{`${isNewAdvertisement ? 'Create' : 'Edit'} Advertisement`}</h2>
      <FlexSpacer />
      <FlexRow>
        <Button
          size="42"
          fill="none"
          color="green"
          onClick={onSave(AdvertisementStatus.PUBLISH)}
          caption="Publish"
          isDisabled={disabled}
        />
        <Button
          cx={buttonStyles.actionButton}
          size="42"
          fill="none"
          onClick={onSave(AdvertisementStatus.DRAFT)}
          caption="Save as Draft"
          isDisabled={disabled}
        />
        <Button
          cx={buttonStyles.actionButton}
          size="42"
          fill="none"
          onClick={handleCancel}
          caption="Cancel"
          isDisabled={disabled}
        />
      </FlexRow>
    </>
  );
};

export default Header;
