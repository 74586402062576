import { FC, useMemo } from 'react';
import { produce } from 'immer';

import { RichTextView } from '@epam/promo';
import { Text } from '@epam/uui';

import styles from './PriceTag.module.scss';

export type PriceTagProps = {
  price: number;
  currency: string;
};

const INTEGER_FORMATTER = Intl.NumberFormat('de-CH', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
});

const FLOAT_FORMATTER = Intl.NumberFormat('de-CH', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});

const PriceTag: FC<React.PropsWithChildren<PriceTagProps>> = ({ price, currency }) => {
  const formattedPrice = useMemo(() => {
    if (price === 0) {
      return 'Free';
    }

    const isInteger = Number.isInteger(price);
    const formatter = isInteger ? INTEGER_FORMATTER : FLOAT_FORMATTER;

    const priceAsString = formatter
      .formatToParts(price)
      .map((part) => {
        if (part.type === 'group') {
          return produce(part, (draft) => {
            draft.value = ' ';
          });
        }

        return part;
      })
      .map(({ value }) => value)
      .join('');

    return `${priceAsString}${isInteger ? '.-' : ''}`;
  }, [price]);

  return (
    <RichTextView cx={styles.container}>
      <Text cx={styles.price}>
        {price !== 0 && <span className={styles.currency}>{currency}</span>}
        <span>{formattedPrice}</span>
      </Text>
    </RichTextView>
  );
};

export default PriceTag;
