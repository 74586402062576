import { useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { produce } from 'immer';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import DeferredFileUploader from '../components/DeferredFileUploader';
import { ErrorAlert, PageSpinner, SuccessAlert, WarningAlert } from '../../../components';
import Layout from '../components/Layout';
import { Page } from '../../../templates';
import PermissionDeniedErrorPage from '../../../components/PermissionDeniedErrorPage/PermissionDeniedErrorPage';
import { ReferenceDataContext } from '../../../components/ReferenceDataProvider';
import { useForm, Text } from '@epam/promo';

import descriptionFormatter from '../../../utils/descriptionFormatter';
import { getAdvertisementFormMetadata } from '../utils/validationSchema';
import { getCurrencyByCountryId } from '../utils/getCurrencyById';
import mapUserToOwner from '../utils/mapUserToOwner';
import { useAdvertisement } from '../../../apiHooks';
import { useAttachmentFiles } from '../../../apiHooks/useAttachmentFiles';
import { useAuth } from '../../../auth/AuthProvider';
import { useAxios } from '../../../apiHooks/useAxios';
import {
  useCategoriesDataSource,
  useCurrencyDataSource,
  useCountriesDataSoruce,
} from '../hooks/dataSources';
import useFileUploadManager from '../components/utils/useFileUploadManager';
import { useUuiContext } from '@epam/uui-core';

import { AdvertisementServiceUrl } from '../../../apiUtils/constants';
import { AdvertisementStatus } from '../../../utils/businessConstants';
import {
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from '../../../components/Notifications/constants';
import { PREVIEW_DEFAULT_STATUS } from '../constants';

import { Advertisement, Location } from '../../../types';
import { AdvertisementFormState } from '../types';

const useDefaultValue = (
  advertisement: Advertisement | undefined,
  locations: Location[]
): AdvertisementFormState => {
  return useMemo(() => {
    if (!advertisement || !locations) {
      return {
        id: '',
        title: '',
        description: '',
        country: undefined,
        city: undefined,
        currency: undefined,
        status: PREVIEW_DEFAULT_STATUS,
        attachments: [],
        owner: undefined,
      };
    } else {
      return {
        id: advertisement.id,
        title: advertisement.title,
        description: advertisement.description,
        country: advertisement.country,
        city: advertisement.city,
        currency: advertisement.currency,
        attachments: advertisement.attachments,
        highlight: advertisement.highlight,
        category: advertisement.category,
        price: advertisement.price,
        status: advertisement.status,
        owner: advertisement.owner,
      };
    }
  }, [advertisement, locations]);
};

const EditAdvertisementPage: React.FC<React.PropsWithChildren<RouteComponentProps>> = ({
  history,
}) => {
  const { id } = useParams<{ id: string }>();
  const svc = useUuiContext();
  const { advertisement } = useAdvertisement(id);
  const { categories, locations, currencies, countries } = useContext(ReferenceDataContext);
  const { axios } = useAxios();

  const { user } = useAuth();
  const owner = useMemo(() => mapUserToOwner(user), [user]);

  const {
    images,
    highlight,
    uploadDeleteInProgress,
    handlePendingUploadDelete,
    handleAddPhotos,
    handleRemovePhoto,
    handleHighlightChange,
  } = useFileUploadManager(advertisement?.highlight);

  useAttachmentFiles(handleAddPhotos, advertisement?.attachments);

  const defaultValue = useDefaultValue(advertisement, locations);
  const categoriesDataSource = useCategoriesDataSource(categories);
  const currencyDataSource = useCurrencyDataSource(currencies);
  const countryDataSource = useCountriesDataSoruce(countries);

  const displayValidationNotification = useRef<boolean>(false);

  const {
    lens,
    save,
    value: advertisementFormState,
    onValueChange,
    isInProgress,
    isInvalid,
  } = useForm<AdvertisementFormState>({
    value: defaultValue,
    onSave: (ad: Advertisement) =>
      handlePendingUploadDelete(id)
        .then(({ remainingPhotos, newHighlight }) => {
          const payload = produce(ad, (draft) => {
            draft.description = descriptionFormatter(draft.description);
            draft.attachments = remainingPhotos.map((a) => a.id);
            draft.highlight = newHighlight;
            draft.owner = owner;
          });

          return axios
            .put<Advertisement>(AdvertisementServiceUrl.advertisementDetails(id), payload)
            .then(({ data }) => ({ form: data }));
        })
        .catch(() => {
          ErrorAlert(ErrorMessage.AttachmentSaveOnEdit, svc);
        }),
    onSuccess: () => {
      SuccessAlert(SuccessMessage.AdvertisementEdited, svc);
      history.push('/my-advertisements');
    },
    onError: () => {
      ErrorAlert(ErrorMessage.AdvertisementSave, svc);
    },
    getMetadata: getAdvertisementFormMetadata,
  });

  useEffect(() => {
    if (displayValidationNotification.current) {
      if (isInvalid) {
        WarningAlert(WarningMessage.AdvertisementValidationError, svc);
      }

      displayValidationNotification.current = false;
    }
  });

  const handleCountryChange = useCallback(
    (newValue: string) => {
      onValueChange(
        produce(advertisementFormState, (draft) => {
          draft.country = newValue;
          draft.city = undefined;
          draft.currency = getCurrencyByCountryId(countryDataSource, newValue);
        })
      );
    },
    [onValueChange, advertisementFormState, countryDataSource]
  );

  const handleSave = useCallback(
    (targetStatus: AdvertisementStatus) => () => {
      displayValidationNotification.current = true;
      const currentStatus = advertisementFormState.status.id;

      if (currentStatus !== targetStatus) {
        onValueChange(
          produce(advertisementFormState, (draft) => {
            draft.status.id = targetStatus;
          })
        );
      }

      // TODO EPMCHBE-794 remove this cast and catch, it is swallowed in base lib in latest version
      save();
    },
    [advertisementFormState, onValueChange, save]
  );

  useEffect(() => {
    onValueChange(
      produce(advertisementFormState, (draft) => {
        draft.highlight = highlight;
      })
    );
  }, [advertisementFormState, highlight, onValueChange]);

  const renderFileUploader = useCallback(
    () => (
      <DeferredFileUploader
        photos={images}
        highlight={highlight}
        onHighlightChange={handleHighlightChange}
        onAddPhotos={handleAddPhotos}
        onDeletePhoto={handleRemovePhoto}
        actionInProgress={uploadDeleteInProgress}
      />
    ),
    [
      handleAddPhotos,
      handleHighlightChange,
      handleRemovePhoto,
      highlight,
      images,
      uploadDeleteInProgress,
    ]
  );

  const loadingIsFinished =
    !!advertisement && (isEmpty(advertisement?.attachments) || !isEmpty(images));

  const userDoesNotOwnTheAdvertisement = advertisement && owner.id !== advertisement.owner.id;

  if (!loadingIsFinished) {
    return (
      <div aria-label="loading">
        <PageSpinner aria-label="Loading" />
      </div>
    );
  } else if (userDoesNotOwnTheAdvertisement) {
    return (
      <Page>
        <PermissionDeniedErrorPage
          subtitle={
            <Text size="30" fontSize="24" lineHeight="24" font="sans">
              <span>{"Please note you are trying to edit another user's advertisement."}</span>
              <br />
              <span>{'Sorry for the inconvenience.'}</span>
            </Text>
          }
        />
      </Page>
    );
  } else {
    return (
      <Layout
        formState={advertisementFormState}
        lens={lens}
        currencies={currencyDataSource}
        categories={categoriesDataSource}
        countries={countryDataSource}
        attachments={images}
        attachmentUploadInProgress={uploadDeleteInProgress}
        disabled={uploadDeleteInProgress || isInProgress}
        onCountryChange={handleCountryChange}
        onSave={handleSave}
        renderFileUploader={renderFileUploader}
      />
    );
  }
};

export default withRouter(EditAdvertisementPage);
