import { useCallback, useMemo } from 'react';
import { useSessionStorage } from 'react-use';
import { isEmpty } from 'lodash';
import ReactDOM from 'react-dom';
import jwtDecode from 'jwt-decode';

import { SESSION_STORAGE_KEYS } from '../utils/constants';
import { ADMIN_KEY } from './constants';
import useUserDetails from './useUserDetails';

const accessTokenInitial = '';
const idTokenInitial = '';
const refreshTokenInitial = '';

type Claims = {
  name: string;
  email: string;
  picture: string;
  user_id: string;
  country: string;
  location: string;
};

export type User = {
  name: string;
  email: string;
  avatar: string;
  pmcId: string;
  country: string;
  city: string;
  isAdmin?: boolean;
};

const useTokens = () => {
  const { userDetails } = useUserDetails();

  const [accessToken, setAccessToken] = useSessionStorage(
    SESSION_STORAGE_KEYS.ACCESS_TOKEN,
    accessTokenInitial,
    true
  );

  const [idToken, setIdToken] = useSessionStorage(
    SESSION_STORAGE_KEYS.ID_TOKEN,
    idTokenInitial,
    true
  );

  // TODO EPMCHBE-327 handle refresh token
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshToken, setRefreshToken] = useSessionStorage(
    SESSION_STORAGE_KEYS.REFRESH_TOKEN,
    refreshTokenInitial,
    true
  );

  const saveTokens = useCallback(
    (idToken: string, accessToken: string, refreshToken: string) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setIdToken(idToken);
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
      });
    },
    [setAccessToken, setIdToken, setRefreshToken]
  );

  const resetTokens = useCallback(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      // sign out
      setIdToken(accessTokenInitial);
      setAccessToken(idTokenInitial);
      setRefreshToken(refreshTokenInitial);
    });
  }, [setAccessToken, setIdToken, setRefreshToken]);

  const user = useMemo((): User | undefined => {
    if (isEmpty(accessToken)) {
      return undefined;
    }

    const claims = jwtDecode(accessToken) as Claims | null;

    if (claims === null) {
      throw new Error('Error during JWT decode, invalid token');
    }

    let isAdmin = false;

    if (userDetails?.roles) {
      isAdmin = userDetails.roles.includes(ADMIN_KEY);
    }

    return {
      name: claims.name,
      email: claims.email,
      pmcId: claims.user_id,
      avatar: claims.picture, // 64x64
      country: claims.country,
      city: claims.location,
      isAdmin,
    };
  }, [accessToken, userDetails?.roles]);

  return {
    isAuthenticated: !isEmpty(idToken),
    accessToken,
    saveTokens,
    resetTokens,
    user,
  };
};

export default useTokens;
