import { Anchor } from '@epam/loveship';
import { FC } from 'react';
import styles from '../../AdvertisementDetails.module.scss';

const UrlRegexp = /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

const Description: FC<React.PropsWithChildren<{ content: string }>> = ({ content }) => {
  const segments = content.split(UrlRegexp);
  return (
    <p className={styles.description}>
      {segments.map((segment, index) =>
        UrlRegexp.test(segment) ? (
          <Anchor key={index} href={segment} target="_blank">
            {segment}
          </Anchor>
        ) : (
          segment
        )
      )}
    </p>
  );
};

export default Description;
