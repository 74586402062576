import { FC } from 'react';

import { GlobalMenu, MainMenu, MainMenuAvatar, MainMenuButton } from '@epam/uui';

import { MainMenuCustomElement } from '@epam/loveship';
import { Button, FlexCell, FlexSpacer } from '@epam/promo';
import { ReactComponent as AddIcon } from '@epam/assets/icons/common/action-add-24.svg';
import logo from './icons/logo.svg';

import { useAuth } from './auth/AuthProvider';

import { ReactComponent as logoutIcon } from '@epam/assets/icons/common/navigation-logout-24.svg';
import styles from './appHeader.module.scss';
import { AdaptiveItemProps, MainMenuLogo } from '@epam/uui-components';

const StaticAppHeader: FC<React.PropsWithChildren<unknown>> = () => (
  <MainMenu appLogoUrl={logo} cx={styles.overlay}>
    <FlexSpacer />
    <GlobalMenu estimatedWidth={60} priority={100500} />
  </MainMenu>
);

const AppHeader: FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useAuth();
  const isAuthenticated = !!user;

  const items = (): AdaptiveItemProps[] => {
    return [
      {
        id: 'logo',
        priority: 99,
        render: (p) => <MainMenuLogo key={p.id} href="/" logoUrl={logo} />,
      },
      {
        id: 'advertisements',
        priority: 100,
        render: () =>
          isAuthenticated && (
            <MainMenuButton caption="Advertisements" link={{ pathname: '/' }} estimatedWidth={72} />
          ),
      },
      {
        id: 'my-ads',
        priority: 100,
        render: () =>
          isAuthenticated && (
            <MainMenuButton
              caption="My Ads"
              link={{ pathname: '/my-advertisements' }}
              priority={1}
              estimatedWidth={60}
            />
          ),
      },
      {
        id: 'spacer',
        priority: 100500,
        render: () => <FlexSpacer priority={100500} />,
      },
      {
        id: 'new-ads',
        priority: 100,
        render: () =>
          isAuthenticated && (
            <FlexCell width="auto">
              <Button
                caption="New Advertisement"
                color="green"
                link={{ pathname: '/advertisement/create' }}
                icon={AddIcon}
              />
            </FlexCell>
          ),
      },
      {
        id: 'logout',
        priority: 100,
        render: () =>
          isAuthenticated && (
            <FlexCell width="auto" cx={styles.logoutButton}>
              <Button color="red" icon={logoutIcon} link={{ pathname: '/logout' }} />
            </FlexCell>
          ),
      },
      {
        id: 'avatar',
        priority: 100,
        render: () =>
          isAuthenticated && (
            <MainMenuCustomElement priority={10} estimatedWidth={80}>
              <MainMenuAvatar avatarUrl={user.avatar || '/resources/avatar_placeholder.jpg'} />
            </MainMenuCustomElement>
          ),
      },
      {
        id: 'global-menu',
        priority: 100,
        render: () => <GlobalMenu estimatedWidth={60} priority={100500} />,
      },
    ];
  };

  return <MainMenu cx={styles.overlay} items={items()} />;
};

export { StaticAppHeader };

export default AppHeader;
