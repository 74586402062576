import { FC, useCallback, useMemo, useState } from 'react';
import { FlexCell, FlexRow, IconContainer, RichTextView } from '@epam/promo';
import { Text } from '@epam/uui';
import { Link } from 'react-router-dom';
import { FlexSpacer, LinkButton, Panel } from '@epam/loveship';
import { useUuiContext } from '@epam/uui-core';
import { noop } from 'lodash';
import { AxiosError } from 'axios';

import ChangeAdStatus from '../Modals/ChangeAdStatus';
import { confirmActionAlert } from '../Notifications/ActionAlert';
import DeleteAd from '../Modals/DeleteAd';
import { ErrorAlert, PriceTag, SuccessAlert } from '../../components';
import ExpirationIndicator from '../ExpirationIndicator';
import getAvatarUrl, {
  AVATAR_PLACEHOLDER,
} from '../../pages/CreateAndEditAdvertisementPage/utils/avatarUtil';
import { ReactComponent as EditIcon } from '@epam/assets/icons/common/content-edit-18.svg';
import { ReactComponent as MapIcon } from '@epam/assets/icons/action-map_pin-fill.svg';
import { ReactComponent as SettingsIcon } from '@epam/assets/icons/common/navigation-more_vert-24.svg';
import requestedResourceIsNotFound from '../../utils/responseErrorHelper';
import truncateLongString from '../../utils/stringUtils';

import { useAuth } from '../../auth/AuthProvider';
import { useAxios } from '../../apiHooks/useAxios';

import { AdvertisementServiceUrl } from '../../apiUtils/constants';
import { AdvertisementStatus } from '../../utils/businessConstants';
import { ErrorMessage, SuccessMessage, WarningMessage } from '../Notifications/constants';

import { Advertisement } from '../../types';
import AsyncImage from '../AsyncImage';

import css from './AdvertisementTile.module.scss';
import ActionMenu from './ActionMenu';

export type AdvertisementTileProps = {
  advertisement: Advertisement;
  onRefreshData: () => void;
  withContextMenu?: boolean;
  withExpirationDetails?: boolean;
};

const IMAGE_PLACEHOLDER = '/resources/image_placeholder.png';

const AdvertisementTile: FC<React.PropsWithChildren<AdvertisementTileProps>> = ({
  advertisement,
  withContextMenu = false,
  withExpirationDetails = false,
  onRefreshData,
}) => {
  const svc = useUuiContext();
  const { axios } = useAxios();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const {
    id,
    description,
    price,
    currency,
    title,
    highlight,
    country,
    city,
    attachments,
    lastStatusChangedOn,
    status,
    owner,
  } = advertisement;

  const titleImageId = useMemo(() => highlight ?? attachments[0], [attachments, highlight]);

  const { user } = useAuth();

  const isPublished = status.id === AdvertisementStatus.PUBLISH;
  const dropdownMenuCaption = isPublished ? 'Revert to Draft' : 'Publish';

  const deleteAdvertisement = useCallback(() => {
    const deleteAdvertisement = () =>
      axios
        .delete(AdvertisementServiceUrl.advertisementDetails(id))
        .then(() => {
          onRefreshData();
          SuccessAlert(SuccessMessage.AdvertisementDelete, svc);
        })
        .catch((error) => {
          if (requestedResourceIsNotFound(error)) {
            confirmActionAlert(
              WarningMessage.AdvertisementDeleteNonExisting,
              'Ok',
              onRefreshData,
              svc
            );
            return;
          }

          ErrorAlert(ErrorMessage.Network, svc);
        });

    svc.uuiModals
      .show((props) => (
        <DeleteAd
          onDeleteAdvertisement={deleteAdvertisement}
          advertisement={advertisement}
          modalProps={props}
        />
      ))
      .catch(noop);
  }, [axios, id, onRefreshData, svc, advertisement]);

  const changeStatus = useCallback(() => {
    const payload = isPublished
      ? { id: AdvertisementStatus.DRAFT }
      : { id: AdvertisementStatus.PUBLISH };

    const publishAdvertisement = () =>
      axios
        .put(AdvertisementServiceUrl.advertisementStatus(id), payload)
        .then(() => {
          onRefreshData();
          SuccessAlert(SuccessMessage.AdvertisementStatusChange, svc);
        })
        .catch((error: AxiosError) => {
          if (requestedResourceIsNotFound(error)) {
            confirmActionAlert(
              WarningMessage.AdvertisementStatusChangeNonExisting,
              'Ok',
              onRefreshData,
              svc
            );
            return;
          }

          ErrorAlert(ErrorMessage.AdvertisementStatusChange, svc);
        });

    svc.uuiModals
      .show((props) => (
        <ChangeAdStatus
          modalProps={props}
          isPublished={isPublished}
          advertisement={advertisement}
          onChangeStatus={publishAdvertisement}
        />
      ))
      .catch(noop);
  }, [isPublished, axios, id, onRefreshData, svc, advertisement]);

  const renderDraftOverlay = useCallback(() => {
    if (isPublished) {
      return null;
    }

    return <Text cx={css.imageOverlay}>{'DRAFT'}</Text>;
  }, [isPublished]);

  const handleToggleDropdown = useCallback(() => {
    setOpenDropdown((state) => !state);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setOpenDropdown(false);
  }, []);

  const handleDelete = useCallback(() => {
    handleCloseDropdown();
    deleteAdvertisement();
  }, [handleCloseDropdown, deleteAdvertisement]);

  const handleChangeStatus = useCallback(() => {
    handleCloseDropdown();
    changeStatus();
  }, [changeStatus, handleCloseDropdown]);

  return (
    <Panel background="white" shadow>
      <div className={css.tileHeader}>
        <FlexRow>
          <IconContainer icon={MapIcon} />
          <Text cx={css.text}>{`${country}, ${city}`}</Text>
          <FlexSpacer />
          {user.pmcId === owner.id && withContextMenu && (
            <>
              <div style={{ position: 'relative' }}>
                <IconContainer
                  size={24}
                  icon={SettingsIcon}
                  onClick={handleToggleDropdown}
                  style={{ fill: '#008ACE' }}
                  rawProps={{
                    role: 'menu',
                  }}
                  color="blue"
                />
                {openDropdown && (
                  <ActionMenu
                    statusChangeCaption={dropdownMenuCaption}
                    onChangeStatus={handleChangeStatus}
                    onDelete={handleDelete}
                    onCloseDropdown={handleCloseDropdown}
                    onClickOutside={() => setOpenDropdown(false)}
                  />
                )}
              </div>
              <Link to={`/edit-advertisement/${id}`}>
                <IconContainer size={24} style={{ fill: '#008ACE' }} icon={EditIcon} />
              </Link>
            </>
          )}
        </FlexRow>
      </div>
      <div className={css.imageWrapper}>
        <Link className={css.imageLink} to={`/advertisement/${id}`}>
          {titleImageId ? (
            <AsyncImage className={css.image} alt={title} attachmentId={titleImageId} />
          ) : (
            <img className={css.image} alt={title} src={IMAGE_PLACEHOLDER} />
          )}
        </Link>
      </div>
      <div className={css.detailsWrapper}>
        {renderDraftOverlay()}
        {withExpirationDetails && <ExpirationIndicator statusLastUpdatedOn={lastStatusChangedOn} />}
        <LinkButton
          cx={css.title}
          caption={truncateLongString(title, 27)}
          link={{ pathname: `/advertisement/${id}` }}
          size="48"
        />
        <RichTextView>
          <p className={css.description}>{truncateLongString(description, 75)}</p>
        </RichTextView>
        <FlexRow cx={css.spaceBetweenContainer}>
          <PriceTag price={price} currency={currency} />
          <FlexCell shrink={0} grow={0} width="auto">
            <img
              alt={advertisement?.owner?.name || user.name}
              src={getAvatarUrl(advertisement.owner, user)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = AVATAR_PLACEHOLDER;
              }}
              height={40}
              className={css.ownerImage}
            />
          </FlexCell>
        </FlexRow>
      </div>
    </Panel>
  );
};

export default AdvertisementTile;
