import { FC } from 'react';

import { FlexRow, FlexSpacer } from '@epam/loveship';

import styles from './AppFooter.module.scss';
import { Link } from 'react-router-dom';
import { Text } from '@epam/uui';

const currentYear = new Date().getFullYear();

const StaticAppFooter: FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <footer className={styles.footer}>
      <FlexRow>
        <span>{`\u00A9 1993-${currentYear} EPAM Systems. All rights reserved.`}</span>
        {children}
      </FlexRow>
    </footer>
  );
};

const AppFooter: FC<React.PropsWithChildren<unknown>> = () => (
  <StaticAppFooter>
    <FlexSpacer />
    <Link to="/terms-and-conditions" target="_blank">
      <Text size="48">{'Terms and Conditions'}</Text>
    </Link>
  </StaticAppFooter>
);

export { StaticAppFooter };

export default AppFooter;
