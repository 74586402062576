import { FC, useCallback, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useUuiContext } from '@epam/uui-core';
import { Button } from '@epam/promo';
import { Text } from '@epam/uui';
import { noop } from 'lodash';
import { AxiosError } from 'axios';

import {
  AdvertisementDetails,
  ContactDetails,
  ErrorAlert,
  PageContent,
  PageSpinner,
  SuccessAlert,
} from '../../components';
import Breadcrumb from '../../components/Breadcrumb';
import { Page } from '../../templates';

import { confirmActionAlert } from '../../components/Notifications/ActionAlert';
import Photo from '../CreateAndEditAdvertisementPage/components/utils/Photo';
import requestedResourceIsNotFound from '../../utils/responseErrorHelper';
import { useAdvertisement } from '../../apiHooks';
import { useAttachmentFiles } from '../../apiHooks/useAttachmentFiles';
import { useAuth } from '../../auth/AuthProvider';
import { useAxios } from '../../apiHooks/useAxios';
import ChangeAdStatus from '../../components/Modals/ChangeAdStatus';
import DeleteAd from '../../components/Modals/DeleteAd';

import { AdvertisementServiceUrl, AdminServiceUrl } from '../../apiUtils/constants';
import { AdvertisementStatus } from '../../utils/businessConstants';
import {
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from '../../components/Notifications/constants';

import { Advertisement } from '../../types';

import buttonStyles from '../../templates/buttonStyles.module.scss';
import styles from './AdvertisementPage.module.scss';

const AdvertisementPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<{ id: string }>();
  const { advertisement, setAdvertisement } = useAdvertisement(id);
  const { user } = useAuth();
  const { axios } = useAxios();
  const history = useHistory();
  const svc = useUuiContext();

  const [photos, setPhotos] = useState<Photo[]>([]);
  useAttachmentFiles(setPhotos, advertisement?.attachments);

  const isCurrentUserOwner = useMemo(() => {
    if (!advertisement) {
      return false;
    }

    return advertisement.owner.id === user.pmcId;
  }, [advertisement, user.pmcId]);

  const handleStatusChange = useCallback(
    (isPublish: boolean) => {
      const payload = isPublish
        ? { id: AdvertisementStatus.PUBLISH }
        : { id: AdvertisementStatus.DRAFT };

      const publishAdvertisement = () =>
        axios
          .put<Advertisement>(AdvertisementServiceUrl.advertisementStatus(id), payload)
          .then(({ data }) => {
            SuccessAlert(SuccessMessage.AdvertisementStatusChange, svc);
            setAdvertisement(data);
          })
          .catch((error: AxiosError) => {
            if (requestedResourceIsNotFound(error)) {
              confirmActionAlert(
                WarningMessage.AdvertisementStatusChangeNonExisting,
                'Ok',
                () => history.push('/my-advertisements'),
                svc
              );
              return;
            }

            ErrorAlert(ErrorMessage.AdvertisementStatusChange, svc);
          });

      svc.uuiModals
        .show((props) => (
          <ChangeAdStatus
            modalProps={props}
            isPublished={!isPublish}
            advertisement={advertisement}
            onChangeStatus={publishAdvertisement}
          />
        ))
        .catch(noop);
    },
    [advertisement, axios, history, id, setAdvertisement, svc]
  );

  const handleRevertToDraft = useCallback(() => handleStatusChange(false), [handleStatusChange]);

  const handlePublish = useCallback(() => handleStatusChange(true), [handleStatusChange]);

  const handleEdit = useCallback(() => {
    history.push(`/edit-advertisement/${advertisement!.id}`);
  }, [history, advertisement]);

  const handleDelete = useCallback(() => {
    const deletePath = user.isAdmin
      ? AdminServiceUrl.deleteAdvertisement(id)
      : AdvertisementServiceUrl.advertisementDetails(id);

    const deleteAdvertisement = () =>
      axios
        .delete(deletePath)
        .then(() => {
          SuccessAlert(SuccessMessage.AdvertisementDelete, svc);
          history.push('/my-advertisements');
        })
        .catch((error) => {
          if (requestedResourceIsNotFound(error)) {
            confirmActionAlert(
              WarningMessage.AdvertisementDeleteNonExisting,
              'Ok',
              () => history.push('/my-advertisements'),
              svc
            );
            return;
          }

          ErrorAlert(ErrorMessage.Network, svc);
        });

    svc.uuiModals
      .show((props) => (
        <DeleteAd
          isCurrentUserAdmin={user.isAdmin}
          onDeleteAdvertisement={deleteAdvertisement}
          advertisement={advertisement}
          modalProps={props}
        />
      ))
      .catch(noop);
  }, [advertisement, axios, history, id, svc, user.isAdmin]);

  const renderControls = useCallback(() => {
    if (!isCurrentUserOwner) {
      if (user.isAdmin) {
        return <Button size="42" fill="none" color="red" onClick={handleDelete} caption="Delete" />;
      }

      return null;
    }

    return (
      <div className={styles.buttonContainer}>
        {AdvertisementStatus.DRAFT === advertisement!.status.id && (
          <Button size="42" fill="none" color="gray50" caption="Publish" onClick={handlePublish} />
        )}
        {AdvertisementStatus.PUBLISH === advertisement!.status.id && (
          <Button
            size="42"
            fill="none"
            color="gray50"
            caption="Revert to Draft"
            onClick={handleRevertToDraft}
          />
        )}
        <Button
          cx={buttonStyles.actionButton}
          size="42"
          fill="none"
          onClick={handleEdit}
          caption="Edit"
        />
        <Button size="42" fill="none" color="red" onClick={handleDelete} caption="Delete" />
      </div>
    );
  }, [
    isCurrentUserOwner,
    advertisement,
    handlePublish,
    handleRevertToDraft,
    handleEdit,
    handleDelete,
    user.isAdmin,
  ]);

  const isDraft = (advertisement: Advertisement) => {
    return advertisement.status.id === AdvertisementStatus.DRAFT;
  };

  if (!advertisement) {
    return <PageSpinner />;
  }

  return (
    <Page>
      <PageContent>
        <div className={styles.header}>
          <Breadcrumb>
            <Text cx={styles.breadcrumbCategory} fontSize="16">
              {advertisement.category.name}
            </Text>
            <Text cx={styles.breadcrumbTerminal} fontSize="16">
              {advertisement.title.toUpperCase()}
            </Text>
          </Breadcrumb>
          {renderControls()}
        </div>
        <div className={styles.content}>
          <>{isDraft(advertisement) && <Text cx={styles.imageOverlay}>{'DRAFT'}</Text>}</>
          <AdvertisementDetails
            advertisement={advertisement}
            attachments={photos}
            contactChannelsDisabled={isCurrentUserOwner}
          />
          <ContactDetails advertisement={advertisement} user={user} />
        </div>
      </PageContent>
    </Page>
  );
};

export default AdvertisementPage;
