import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce, useLocalStorage } from 'react-use';
import { wasBackButtonClicked } from '../apiUtils/constants';

const useStoredState = <T>(key: string, storeState: () => T, restoreState: (state: T) => void) => {
  const [storedState, setStoredState] = useLocalStorage<T>(key);
  const history = useHistory();

  useEffectOnce(() => {
    if (wasBackButtonClicked(history) && storedState) {
      restoreState(storedState);
    }
  });

  useEffect(() => {
    return () => {
      setStoredState(storeState());
    };
  }, [setStoredState, storeState]);
};

export default useStoredState;
