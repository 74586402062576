import { useCallback, useContext, FC } from 'react';
import { produce } from 'immer';
import { isEmpty } from 'lodash';

import EmptySearchList from './components/EmptySearchList';
import { FlexCell, FlexRow } from '@epam/loveship';
import { Paginator } from '@epam/promo';
import { Page } from '../../templates';
import {
  PageSpinner,
  AdvertisementTile,
  ResultsFilter,
  ResultsHeader,
  CategoriesFilter,
} from '../../components';
import { MainHeader } from './components/MainHeader';
import { ReferenceDataContext } from '../../components/ReferenceDataProvider';

import useSearch, { DEFAULT_SEARCH_FILTERS } from '../../apiHooks/useSearch';

import { AdvertisementStatus } from '../../utils/businessConstants';

import { Advertisement } from '../../types';

import css from './MainPage.module.scss';
import useStoredScrollPosition from '../../apiHooks/useStoredScrollPosition';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import useStoredState from '../../apiHooks/useStoredState';

const initialFilters = produce(DEFAULT_SEARCH_FILTERS, (draft) => {
  draft.status = AdvertisementStatus.PUBLISH;
});

const MainPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { categories } = useContext(ReferenceDataContext);
  const {
    searchFilter,
    data: advertisementsData,
    handlePageIndexChange,
    handleKeywordChange,
    handleSortTypeChange,
    handleCurrencyChange,
    handleCategoriesChange,
    handlePriceRangeChange,
    handleDateRangeChange,
    handleCountryChange,
    handleResetFilters,
    handleRefreshData,
  } = useSearch(initialFilters);

  const handlePaginationChange = useCallback(
    (page: number) => {
      handlePageIndexChange(page);
      window.scrollTo(0, 400);
    },
    [handlePageIndexChange]
  );

  const renderAdvertisementTiles = useCallback(
    () => (
      <div className={css.grid}>
        {(advertisementsData?.items ?? []).map((advertisement: Advertisement) => (
          <AdvertisementTile
            key={advertisement.id}
            advertisement={advertisement}
            onRefreshData={handleRefreshData}
            withContextMenu
          />
        ))}
      </div>
    ),
    [advertisementsData?.items, handleRefreshData]
  );

  const storeState = useCallback(() => {
    return {
      pageIndex: searchFilter.pageIndex,
    };
  }, [searchFilter.pageIndex]);

  const restoreState = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      handlePageIndexChange(pageIndex);
    },
    [handlePageIndexChange]
  );

  useStoredState(LOCAL_STORAGE_KEYS.MAIN_PAGE_STORED_STATE, storeState, restoreState);

  const isReadyToScroll = useCallback(() => !!advertisementsData, [advertisementsData]);

  useStoredScrollPosition(LOCAL_STORAGE_KEYS.MAIN_PAGE_SCROLL_POS, isReadyToScroll);

  if (!advertisementsData) {
    return (
      <div aria-label="loading">
        <PageSpinner aria-label="Loading" />
      </div>
    );
  }

  return (
    <Page>
      <MainHeader value={searchFilter.keyword} onValueChange={handleKeywordChange} />
      <div className={css.container}>
        <FlexRow padding="12" alignItems="top">
          <FlexCell cx={css.sidebar} width="100%">
            <CategoriesFilter
              categories={categories}
              categoriesFiltered={searchFilter.categories}
              onCategoriesSelectChange={handleCategoriesChange}
            />
            <ResultsFilter
              filters={searchFilter}
              onDateRangeChange={handleDateRangeChange}
              onPriceRangeChange={handlePriceRangeChange}
              onCurrencyChange={handleCurrencyChange}
              onCountryChange={handleCountryChange}
              onClearSearchProperties={handleResetFilters}
            />
          </FlexCell>
          <FlexCell cx={css.advertisements} width="100%">
            <ResultsHeader
              resultsCount={advertisementsData.totalElements}
              sortType={searchFilter.sortType}
              paginationPageIndex={searchFilter.pageIndex}
              onPaginationPageChange={handlePaginationChange}
              totalPages={advertisementsData.totalPages}
              onSortTypeChange={handleSortTypeChange}
            />
            {!isEmpty(advertisementsData.items) && renderAdvertisementTiles()}
            {advertisementsData.totalPages > 1 && (
              <FlexRow cx={css.paginator}>
                <Paginator
                  size="24"
                  totalPages={advertisementsData.totalPages}
                  value={searchFilter.pageIndex}
                  onValueChange={handlePaginationChange}
                  isDisabled={advertisementsData.totalElements === 0}
                />
              </FlexRow>
            )}
            {isEmpty(advertisementsData.items) && <EmptySearchList />}
          </FlexCell>
        </FlexRow>
      </div>
    </Page>
  );
};

export default MainPage;
