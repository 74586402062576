import { noop } from 'lodash';

import { WarningNotification } from '@epam/promo';
import { Text } from '@epam/uui';
import { CommonContexts, INotification } from '@epam/uui-core';

import { WarningMessage } from './constants';

// @TODO ask uui team to expose this interface
export interface NotificationAction {
  name: string;
  action: () => void;
}

const ActionAlert = (
  message: WarningMessage,
  renderActions: (props: INotification) => NotificationAction[],
  svc: CommonContexts<unknown, unknown>,
  cx?: string
) => {
  svc.uuiNotifications
    .show(
      (props: INotification) => (
        <WarningNotification {...props} actions={renderActions(props)} cx={cx}>
          <Text size="36" fontSize="14">
            {message}
          </Text>
        </WarningNotification>
      ),
      { duration: 'forever' }
    )
    .then(noop)
    .catch(noop);
};

export const confirmActionAlert = (
  warningMessage: WarningMessage,
  actionText: string,
  action: () => void,
  svc: CommonContexts<unknown, unknown>
) =>
  ActionAlert(
    warningMessage,
    (props: INotification) => [
      {
        name: actionText,
        action: () => {
          action();
          props.onSuccess && props.onSuccess();
        },
      },
    ],
    svc
  );

export default ActionAlert;
