import { useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { wasBackButtonClicked } from '../apiUtils/constants';

const useStoredScrollPosition = (pageKey: string, isReadyToScroll: () => boolean): void => {
  const [storedScrollPosition, setStoredScrollPosition] = useLocalStorage(pageKey, 0);

  const history = useHistory();

  useEffect(() => {
    if (wasBackButtonClicked(history) && isReadyToScroll() && storedScrollPosition) {
      window.scrollTo(0, storedScrollPosition);
    }
  }, [storedScrollPosition, isReadyToScroll, history]);

  useLayoutEffect(() => {
    return () => {
      setStoredScrollPosition(window.scrollY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useStoredScrollPosition;
