import { FC } from 'react';

import AppHeader from '../AppHeader';
import { Spinner } from '@epam/loveship';

const PageSpinner: FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <AppHeader />
    <div style={{ height: 600 }}>
      <Spinner />
    </div>
  </>
);

export default PageSpinner;
