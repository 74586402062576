/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { isEmpty } from 'lodash';

const truncateLongString = (
  title: string | null | undefined = '',
  maxLength: number
): string | null => {
  if (title && !isEmpty(title) && title.length > maxLength) {
    return `${title.substring(0, maxLength)}...`;
  }

  return title;
};

export default truncateLongString;
