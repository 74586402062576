import { FC, useCallback, useState } from 'react';
import { Button, Panel, ScrollBars, FlexSpacer, ModalFooter } from '@epam/promo';
import { Text, FlexRow } from '@epam/uui';

import BlockingModal from './BlockingModal';

import {
  getDeleteConfirmationMessage,
  getDeleteConfirmationMessageAdmin,
} from '../Notifications/constants';

import { Advertisement } from '../../types';
import { IModal } from '@epam/uui-core';

type DeleteAdProps = {
  onDeleteAdvertisement: () => Promise<void>;
  advertisement: Advertisement | undefined;
  modalProps: IModal<void>;
  isCurrentUserAdmin?: boolean;
};

const DeleteAd: FC<React.PropsWithChildren<DeleteAdProps>> = ({
  modalProps,
  onDeleteAdvertisement,
  advertisement,
  isCurrentUserAdmin = false,
}) => {
  const [isLoading, setLoading] = useState(false);

  const message = isCurrentUserAdmin
    ? getDeleteConfirmationMessageAdmin(advertisement!.title)
    : getDeleteConfirmationMessage(advertisement!.title);

  const onDeleteConfirm = useCallback(() => {
    setLoading(true);
    onDeleteAdvertisement().then(() => modalProps.success());
  }, [modalProps, onDeleteAdvertisement, setLoading]);

  return (
    <BlockingModal {...modalProps}>
      <Panel background="white">
        <ScrollBars hasTopShadow hasBottomShadow>
          <FlexRow padding="24" vPadding="24">
            <Text size="24" fontSize="14">
              {message}
            </Text>
          </FlexRow>
        </ScrollBars>
        <ModalFooter>
          <FlexSpacer />
          <Button
            color="gray50"
            fill="white"
            caption="Cancel"
            isDisabled={isLoading}
            onClick={modalProps.abort}
          />
          <Button
            isDisabled={isLoading}
            color="red"
            caption="Confirm deletion"
            onClick={onDeleteConfirm}
          />
        </ModalFooter>
      </Panel>
    </BlockingModal>
  );
};

export default DeleteAd;
