import { FC } from 'react';
import { useSessionStorage, useEffectOnce } from 'react-use';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { AdvertisementPage } from './pages/AdvertisementPage';
import AuthProvider from './auth/AuthProvider';
import { CreateAdvertisementPage } from './pages/CreateAndEditAdvertisementPage/CreateAdvertisementPage';
import { EditAdvertisementPage } from './pages/CreateAndEditAdvertisementPage/EditAdvertisementPage';
import { MainPage } from './pages/MainPage';
import MyAdvertisementsPage from './pages/MyAdvertisementsPage/MyAdvertisementsPage';
import { ReferenceDataProvider } from './components/ReferenceDataProvider';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage/TermsAndConditionsPage';

import { SESSION_STORAGE_KEYS } from './utils/constants';
import { LogoutPage } from './pages/LogoutPage';

export const App: FC<React.PropsWithChildren<unknown>> = () => {
  const [entryUrl, setEntryUrl] = useSessionStorage(SESSION_STORAGE_KEYS.ENTRY_URL, '', true);
  const { pathname } = useLocation();

  useEffectOnce(() => {
    if (isEmpty(entryUrl)) {
      setEntryUrl(pathname);
    }
  });

  return (
    <AuthProvider>
      <ReferenceDataProvider>
        <Switch>
          <Route path="/" exact component={MainPage} />
          <Route exact path="/advertisement/create" component={CreateAdvertisementPage} />
          <Route exact path="/advertisement/:id" component={AdvertisementPage} />
          <Route exact path="/edit-advertisement/:id" component={EditAdvertisementPage} />
          <Route exact path="/my-advertisements" component={MyAdvertisementsPage} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
          <Route exact path="/logout" component={LogoutPage} />
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </ReferenceDataProvider>
    </AuthProvider>
  );
};
